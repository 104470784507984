@charset "utf-8";
@import url('https://fonts.googleapis.com/css2?family=Inter:wght@200;300;400;500;600;700;800;900&display=swap');
/**  
* -----------------------------------------------------------------------------
*
*
* -----------------------------------------------------------------------------   
TABLE OF CONTENT
---------------------------------------------------------
    01. General CSS
    02. Global Class CSS
    03. Header Section CSS
    04. Sticky Menu CSS
    05. About Section CSS
    06. Appointment Section CSS
    07. Breadcrumbs Section CSS
    08. Banner Section CSS
    09. Slider Section CSS
    10. Video Section CSS
    11. Iconbox Area Section CSS
    12. Skill-bar Section CSS
    13. Services Section CSS
    14. Faq Section CSS
    15. Counter Section CSS
    16. Choose Us Section CSS
    17. Team Section CSS
    18. Process Section CSS
    19. Pricing Section CSS  
    20. Project Section CSS
    21. Partner Section CSS
    22. Blog Section CSS
    23. Testimonial Section CSS
    24. Shop Section CSS
    25. Single Shop Section CSS
    26. Cart Section CSS
    27. Woocommerce Section CSS
    28. Checkout Section CSS
    29. Account Section CSS
    30. Contact Section CSS
    31. Footer Section CSS
    32. Scroll Up CSS
    33. Preloader CSS
    34. Animation Part CSS

--------------------------------------------------------*/
/* -----------------------------------
    01. General CSS
-------------------------------------*/
html, body {  font-size: 15px;
  color: #000;
  font-family:'Inter', sans-serif;
  vertical-align: baseline;
  line-height:inherit;
  font-weight: 400;
  overflow-x: hidden;
}
img {
  max-width: 100%;
  height: auto;
}
p {
  margin: 0 0 26px; line-height: 22px;
 
}
h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: 'Inter', sans-serif;
  color: #0a0a0a;
  margin: 0 0 26px;
 
}
h1 {
  font-size: 52px;
}
h2 {
  font-size: 40px;
}
h3 {
  font-size: 28px;

}
h4 {
  font-size: 26px;

}
h5 {
  font-size: 24px;
}
h6 {
  font-size: 20px;

}
.text-md-left {
  text-align: left;
}
.text-md-right {
  text-align: right;
}
.text-right {
  text-align: right;
}
.no-border {
  border: none !important;
}
.uppercase {
  text-transform: uppercase !important;
}
.capitalize {
  text-transform: capitalize !important;
}
.extra-bold {
  font-weight: 800 !important;
}
.gray-bg {
  background: #ebebeb;
}
.gray-bg2 {
  background: #ededed;
}
.gray-bg3 {
  background: #f6f7f9;
}
.gray-bg4 {
  background: #f6f6f6;
}
.gray-bg5 {
  background: #f9f9f9;
}
.gray-bg6 {
  background: #f7f7f7;
}
.gray-bg7 {
  background: #fcfcfc;
}
.black-bg {
  background: #15161a;
}
.black-bg2 {
  background: #0c0b0b;
}
.black-bg3 {
  background: #201d1d;
}
.white-color {
  color: #ffffff !important;
}
.title-color {
  color: #0a0a0a !important;
}
.body-color {
  color: #454545 !important;
}
.white-bg {
  background: #fff;
}
.primary-color2 {
  color: #e94d65 !important;
}
.primary-bd2 {
  background: #e94d65 !important;
}
.secondary-bg2 {
  background: #043d72 !important;
}
.rs-vertical-bottom {
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -ms-flex-wrap: wrap;
  -webkit-flex-wrap: wrap;
  flex-wrap: wrap;
  -ms-flex-align: flex-end;
  -webkit-align-items: flex-end;
  align-items: flex-end;
}
.rs-vertical-middle {
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -ms-flex-wrap: wrap;
  -webkit-flex-wrap: wrap;
  flex-wrap: wrap;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;
}
a {
  color: #016ab3;
  transition: all 0.3s ease;
  text-decoration: none !important;
  outline: none !important;
}
a:active,
a:hover {
  text-decoration: none;
  outline: 0 none;
  color: #ffe100;
}
ul {
  list-style: outside none none;
  margin: 0;
  padding: 0;
}
.clear {
  clear: both;
}
::-moz-selection {
  background: #0d86dc;
  text-shadow: none;
  color: #ffffff;
}
::selection {
  background: #0d86dc;
  text-shadow: none;
  color: #ffffff;
}
.bg1 {
  background: url(assets/images/bg/about-bg.png);
  background-position: top center;
  background-repeat: no-repeat;
  background-size: cover;
}
.bg2 {
  background: url(assets/images/bg/ser-bg.png);
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
}
.bg3 {
  background: url(assets/images/bg/portfolio-bg.png);
  background-repeat: no-repeat;
  background-position: top center;
  background-size: cover;
}
.bg4 {
  background-image: url(assets/images/bg/team-bg.jpg);
  background-position: top center;
  background-repeat: no-repeat;
  background-size: cover;
}
.bg5 {
  background-image: url(assets/images/bg/choose-bg.jpg);
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
}
.bg6 {
  background-image: url(assets/images/bg/testi-bg.png);
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
}
.bg7 {
  background-color: #EBEBEB;
  background-image: url(assets/images/bg/blog-bg.jpg);
  background-repeat: no-repeat;
  background-size: cover;
}
.bg8 {
  background-image: url(assets/images/bg/contact-bg.jpg);
  background-repeat: no-repeat;
  background-position: bottom center;
  background-size: cover;
}
.bg9 {
  background-image: url(assets/images/bg/contact-bg-3.jpg);
  background-repeat: no-repeat;
  background-position: top center;
  background-size: cover;
}
.bg10 {
  background-image: url(assets/images/bg/testi-bg2.jpg);
  background-color: #000000;
  background-repeat: no-repeat;
  background-position: top left;
  background-size: cover;
}
.bg11 {
  background-image: url(assets/images/bg/contact-bg-2.jpg);
  background-repeat: no-repeat;
  background-position: top center;
  background-size: cover;
}
.bg12 {
  background-image: url(assets/images/bg/about-bg2.jpg);
  background-repeat: no-repeat;
  background-position: top center;
}
.bg13 {
  background-image: url(assets/images/bg/cta-bg.png);
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
}
.bg14 {
  background-image: url(assets/images/bg/ser-bg3.jpg);
  background-repeat: no-repeat;
  background-position: top center;
  background-size: cover;
}
.bg15 {
  background-image: url(assets/images/bg/choose-bg3.jpg);
  background-repeat: no-repeat;
  background-size: cover;
}
.bg16 {
  background-image: url(assets/images/bg/testi-bg3.jpg);
  background-repeat: no-repeat;
  background-size: cover;
}
.bg17 {
  background-image: url(assets/images/bg/team-bg3.jpg);
  background-repeat: no-repeat;
  background-size: cover;
}
.bg18 {
  background-color: #F9F9F9;
  background-image: url(assets/images/bg/con-bg4.jpg);
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
}
.bg19 {
  background-image: url(assets/images/bg/blog-bg3.jpg);
  background-repeat: no-repeat;
  background-position: top center;
  background-size: cover;
}
.bg20 {
  background-image: url(assets/images/bg/service-bg4.jpg);
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
}
.bg21 {
  background-image: url(assets/images/bg/about-bg4.png);
  background-position: top left;
  background-repeat: no-repeat;
  background-size: auto;
}
.bg22 {
  background-image: url(assets/images/bg/testi-bg4.jpg);
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
}
.bg23 {
  background-image: url(assets/images/bg/project-single-bg.jpg);
  background-repeat: no-repeat;
  background-size: cover;
  padding: 40px 0px 45px 30px;
}
.bg24 {
  background-image: url(assets/images/bg/project-single-bg2.jpg);
  background-repeat: no-repeat;
  background-size: cover;
  padding: 40px 35px 40px 35px;
}
.bg25 {
  background-image: url(assets/images/bg/count-bg5.jpg);
  background-repeat: no-repeat;
  background-size: cover;
}
.bg26 {
  background-image: url(assets/images/bg/project-bg5.jpg);
  background-position: top center;
  background-repeat: no-repeat;
  background-size: cover;
}
.shape-bg {
  background: url(assets/images/background/shape/shape-bg.png);
  background-repeat: repeat;
  background-size: auto;
  background-repeat: no-repeat;
  background-size: cover;
}
.offwrap {
  cursor: url(assets/images/close.png), auto;
  width: 100%;
  left: 100%;
  transition: all 0.8s ease-out 0s;
  position: fixed;
  background: #fff;
  height: 100vh;
  top: 0;
  bottom: 0;
  opacity: .6;
  z-index: 222;
}
body.nav-expanded .offwrap {
  left: 0%;
  transition: all 0.8s ease-out 0s;
}
.bg-unset {
  background: unset !important;
}
.rs-menu-toggle {
  color: #bb0b0b;
  text-align: right;
}
button {
  cursor: pointer;
}
.glyph-icon:before {
  margin: 0;
}
.z-index-1 {
  position: relative;
  z-index: 1;
}
ul.services-list li a {
  display: block;
  border: 1px solid #e8e8e8;
  padding: 16px 18px;
  transition: all .5s ease;
  position: relative;
  font-size: 16px;
  font-weight: 700;
  color: #0a0a0a;
  margin-bottom: 15px;
}
ul.services-list li a:before {
  content: "\f113";
  position: absolute;
  right: 12px;
  top: 16px;
  font-family: Flaticon;
  color: #1c1b1b;
}
ul.services-list li a:hover,
ul.services-list li a.active {
  background: #bb0b0b;
  color: #ffffff;
}
ul.services-list li a:hover:before,
ul.services-list li a.active:before {
  color: #ffffff;
}
ul.listing-style li {
  margin-bottom: 7px;
}
ul.listing-style li i {
  color: #bb0b0b;
  font-size: 24px;
  line-height: 31px;
  margin-right: 10px;
}
.features-content {
  padding: 98px 150px 97px 100px;
}
.features-content ul.rs-features-list li {
  margin: 0px 0px 21px 0px;
  display: flex;
  align-items: center;
}
.features-content ul.rs-features-list li i {
  color: #ffffff;
  background-color: transparent;
  background-image: linear-gradient(180deg, #e78b0e 0%, #d22511 100%);
  padding: 15px 15px 15px 15px;
  margin: 0px 25px 0px 0px;
  border-radius: 50px 50px 50px 50px;
}
.features-content ul.rs-features-list li .feature-text {
  color: #000000;
  font-weight: 500;
}
.icon-item li {
  display: flex;
  padding-bottom: 10px;
}
.icon-item li .list-icon i {
  font-size: 14px;
  width: 1.25em;
}
.icon-item li .list-text {
  align-self: center;
  padding-left: 5px;
}
.icon-item li:last-child {
  padding-bottom: 0;
}
input:focus,
select:focus,
textarea:focus,
button:focus {
  outline: none !important;
}
.pagination-area {
  display: inline-block;
}
.pagination-area .nav-link {
  display: flex;
  align-items: center;
  padding: 15px 10px 15px 8px;
  border-radius: 0;
  background: #016ab3;
}
.pagination-area .nav-link .page-number {
  padding: 2px 15px 0 20px;
  line-height: 12px;
  font-size: 18px;
  text-decoration: none;
  transition: all 0.4s ease-in-out 0s;
  text-align: center;
  background: 0 0;
  color: #eee;
  display: inline-block;
  vertical-align: top;
  border-right: 1px solid #e6e6e6;
}
.pagination-area .nav-link .page-number.border-none {
  border-right: unset;
}
.pagination-part {
  display: inline-block;
  border: 1px solid #f9f9f9;
  padding: 11px 0;
  border-radius: 3px;
  box-shadow: 0 0 30px rgba(0, 0, 0, 0.05);
  background: #fff;
}
.pagination-part li {
  padding: 2px 20px 0 21px;
  line-height: 23px;
  font-size: 18px;
  text-decoration: none;
  transition: all 0.4s ease-in-out 0s;
  text-align: center;
  background: 0 0;
  color: #000;
  display: inline-block;
  vertical-align: top;
  border-right: 1px solid #e6e6e6;
}
.pagination-part li a {
  color: #454545;
}
.pagination-part li:last-child {
  border-right: unset;
}
.pagination-part .next-page {
  position: relative;
  transition: all 0.4s ease-in-out 0s;
  padding-right: 12px;
}
.pagination-part .next-page:before,
.pagination-part .next-page:after {
  content: "\f105";
  position: absolute;
  top: 4px;
  right: 0;
  color: #454545;
  line-height: 16px;
  font-family: fontawesome;
  font-size: 12px;
}
.pagination-part .next-page:before {
  right: 4px;
}
.pagination-part .active a {
  color: #bb0b0b;
}
.gridFilter button {
  font-size: 17px;
  font-weight: 600;
  background: 0 0!important;
  color: #0a0a0a;
  display: inline-block;
  transition: .4s;
  outline: 0;
  position: relative;
  padding: 6px 20px 6px 20px;
  border: 0;
}
.gridFilter button.active {
  color: #FFFFFF;
  background: #BB0B0B !important;
}
.gridFilter button:hover {
  color: #FFFFFF;
  background: #BB0B0B !important;
}
.gridFilter button + button {
  margin-left: 20px;
}
blockquote {
  margin: 35px 0px;
  padding: 40px 60px;
  color: #666;
  position: relative;
  background: rgba(0, 0, 0, 0.01);
  font-weight: normal;
  font-style: italic;
  text-align: left;
  clear: both;
  font-size: 20px;
  font-weight: 400;
  border-radius: 8px;
}
blockquote:before {
  content: "\f129";
  font-size: 35px;
  color: #016ab3;
  padding-bottom: 0;
  display: inline-block;
  font-family: flaticon;
  font-weight: 400;
  text-align: center;
  top: 0;
  background: 0 0;
  transform: rotate(180deg);
}
blockquote cite {
  font-size: 15px;
  display: block;
  margin-top: 10px;
}
blockquote cite:before {
  content: "";
  font-size: 28px;
  color: #ff5421;
  padding-bottom: 0px;
  display: inline-block;
  background: #016ab3;
  height: 2px;
  width: 40px;
  font-weight: 400;
  text-align: center;
  top: -4px;
  margin-right: 10px;
  position: relative;
}
.dots {
  list-style: disc;
  margin: 0 0 1.5em 3em;
}
.arrow-btn {
  position: absolute;
  top: 90%;
  left: 50%;
  transform: translate(-50%, -15%);
}
.arrow-btn a {
  font-weight: 400;
  font-size: 17px;
  border-color: #bb0b0b;
  border-style: solid;
  border-width: 1px;
  margin: 0px;
  border-radius: 23px;
  padding: 12px 15px;
  color: #bb0b0b;
}
.arrow-btn a span {
  position: absolute;
  display: block;
  top: 50%;
  left: 50%;
  width: 8px;
  height: 8px;
  margin: -4px 0 0 -4px;
  border-radius: 50%;
  background: #bb0b0b;
}
.rs-carousel.nav-style1 {
  position: relative;
}
.rs-carousel.nav-style1 .owl-nav {
  display: block;
}
.rs-carousel.nav-style1 .owl-nav .owl-next,
.rs-carousel.nav-style1 .owl-nav .owl-prev {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: 30px;
  width: 42px;
  height: 42px;
  line-height: 42px;
  border-radius: 50%;
  background: #bb0b0b;
  text-align: center;
  color: #ffffff;
  transition: all 0.5s;
  transition-delay: 0.70s;
  opacity: 0;
  visibility: hidden;
}
.rs-carousel.nav-style1 .owl-nav .owl-next i:before,
.rs-carousel.nav-style1 .owl-nav .owl-prev i:before {
  content: "\f138";
  font-family: Flaticon;
}
.rs-carousel.nav-style1 .owl-nav .owl-next {
  right: 30px;
  left: unset;
}
.rs-carousel.nav-style1 .owl-nav .owl-next i:before {
  content: "\f137";
}
.rs-carousel.nav-style1:hover .owl-nav .owl-next,
.rs-carousel.nav-style1:hover .owl-nav .owl-prev {
  left: -50px;
  transition-delay: 0s;
  visibility: visible;
  opacity: 1;
}
.rs-carousel.nav-style1:hover .owl-nav .owl-next {
  right: -50px;
  left: unset;
}
.rs-carousel.nav-style2 {
  position: relative;
}
.rs-carousel.nav-style2 .owl-nav {
  display: block;
  position: absolute;
  top: -80px;
  right: 0;
}
.rs-carousel.nav-style2 .owl-nav .owl-prev,
.rs-carousel.nav-style2 .owl-nav .owl-next {
  display: inline-block;
}
.rs-carousel.nav-style2 .owl-nav .owl-prev i,
.rs-carousel.nav-style2 .owl-nav .owl-next i {
  transition: all 0.3s ease;
}
.rs-carousel.nav-style2 .owl-nav .owl-prev i:before,
.rs-carousel.nav-style2 .owl-nav .owl-next i:before {
  font-family: Flaticon;
  font-size: 22px;
}
.rs-carousel.nav-style2 .owl-nav .owl-prev:hover i,
.rs-carousel.nav-style2 .owl-nav .owl-next:hover i {
  color: #bb0b0b;
}
.rs-carousel.nav-style2 .owl-nav .owl-prev i:before {
  content: "\f134";
}
.rs-carousel.nav-style2 .owl-nav .owl-prev:after {
  content: "/";
  padding: 0 5px 0 5px;
  position: relative;
  top: -3px;
}
.rs-carousel.nav-style2 .owl-nav .owl-next i:before {
  content: "\f133";
}
.rs-carousel .owl-dots {
  text-align: center;
  margin-top: 50px;
}
.rs-carousel .owl-dots .owl-dot {
  width: 25px;
  height: 8px;
  display: inline-block;
  margin: 0 6px;
  padding: 3px 0;
  border-radius: 0;
  border: none;
  transition: all 0.3s ease;
  background: #BB0B0B;
  box-shadow: 0px 3px 8px 0px rgba(0, 0, 0, 0.25);
}
.rs-carousel .owl-dots .owl-dot:hover,
.rs-carousel .owl-dots .owl-dot.active {
  width: 40px;
  background: #BB0B0B;
  opacity: .5;
}
/* ------------------------------------
    02. Global Class CSS
---------------------------------------*/
.y-middle {
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -ms-flex-wrap: wrap;
  -webkit-flex-wrap: wrap;
  flex-wrap: wrap;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;
}
.y-bottom {
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -ms-flex-wrap: wrap;
  -webkit-flex-wrap: wrap;
  flex-wrap: wrap;
  -ms-flex-align: flex-end;
  -webkit-align-items: flex-end;
  align-items: flex-end;
}
.project-item .vertical-middle {
  position: relative;
  z-index: 9;
}
.vertical-middle {
  display: table;
  height: 100%;
  width: 100%;
}
.vertical-middle-cell {
  display: table-cell;
  vertical-align: middle;
}
.readon {
  color: #fff;
  font-size: 17px;
  line-height: 26px;
  font-weight: 600;
  text-transform: capitalize;
  cursor: pointer;
  display: inline-block;
  position: relative;
  transition: all 0.4s;
  z-index: 1;
  background-color: transparent;
}
.readon.consultant {
  padding:14px 45px 14px 15px;
  border-radius: 0px 0px 0px 0px;
  color: #fff;
  background: #bb0b0b;
  overflow: hidden;
  position: relative;
}
.readon.consultant:after {
  content: "";
  position: absolute;
  top: 10px;
  left: 0;
  width: 300px;
  height: 200px;
  background-color: #333;
  border-color: transparent;
  border-radius: 50%;
  transform: translate(-40px, -80px) scale(0.1);
  opacity: 0;
  z-index: -1;
  transition: transform 0.5s, opacity 0.5s, background-color 0.5s;
}
.readon.consultant:hover:after {
  opacity: .3;
  transform-origin: 100px 100px;
  transform: scale(1) translate(-10px, -70px);
}
.readon.consultant.slider {
  padding: 18px 40px 18px 40px;
}
.readon.consultant.discover {
  padding: 15px 40px 15px 40px;
}
.readon.consultant.discover.orange-more {
  background: #fd4a36;
}
.readon.consultant.blue-view {
  padding: 15px 40px 15px 40px;
  background: #0082f1;
}
.readon.consultant.orange-slide {
  background: #fd4a36;
}
.readon.consultant.blog {
  font-size: 13px;
  font-weight:400;
  padding:7px 40px 7px 15px;
  background: #51ad32;
  position: relative; border-radius: 6px; box-shadow: rgba(9, 30, 66, 0.25) 0px 4px 8px -2px, rgba(9, 30, 66, 0.08) 0px 0px 0px 1px;
}
.readon.consultant.blog:before {
  content: '\e836';
  font-family: "unicons";
  font-size: 15px;
  font-weight: normal;
  position: absolute;
  top:7px;
  right:16px;
  transition: all 0.3s;
  color: #fff;
  transition: all ease .4s;
  color: #ffffff;
}
.readon.consultant.lets-talk {
  padding: 8px 22px;
  font-size: 14px;
  background: #fd4a36;
}
.readon.consultant.get-quote {
  font-size: 14px;
  padding: 8px 22px;
  background: #4caf50;
  border-color: #4caf50;
}
.readon.consultant.paste-btn {
  font-weight: 500;
  padding: 15px 33px 15px 33px;
  background-color: #4CAF50;
  border-radius: 4px 4px 4px 4px;
}
.readon.consultant.paste-btn.more {
  padding: 15px 40px 15px 40px;
}
.readon.blue-slide {
  background-color: #0082f1;
  color: #ffffff;
  padding: 17px 35px 17px;
  display: inline-block !important;
}
.readon.blue-slide:hover {
  opacity: 0.8;
}
.readon.submit {
  background-color: #BB0B0B;
  padding:14px 45px 14px 15px;
  border: none;
  transition: all 0.4s cubic-bezier(0.215, 0.61, 0.355, 1) 0s;
  display: block;
  cursor: pointer;
  position: relative;
  z-index: 2;
}
.readon.submit:hover {
  color: #fff;
  opacity: .99;
}
.readon.submit.blue-small {
  background-color: #0082F1;
  padding: 17px 30px 17px 30px;
  font-size: 16px;
  border-radius: 0px 0px 0px 0px;
}
.readon.submit.orange-submit {
  background-color: #FD4A36;
  font-size: 16px;
}
.readon.submit.paste-btn {
  color: #fff;
  background-color: #4CAF50;
  margin: 0px 0px 0px 0px;
  padding:14px 45px 14px 15px;
  font-size: 16px;
  font-weight: 600;
  border-radius: 4px 4px 4px 4px;
}
.readon.post {
  padding: 14px 25px;
  border-radius: 0;
  line-height: normal;
  font-size: 14px;
  font-weight: 500;
  background:#52ad33;
  border: none;
}
.readon.price {
  padding: 8px 30px 8px 30px;
  color: #0A0A0A;
  background-color: #fff;
  border-style: solid;
  border-width: 1px 1px 1px 1px;
  border-color: #0A0A0A;
  border-radius: 0px 0px 0px 0px;
}
.readon.price:hover {
  border-style: solid;
  border-width: 1px 1px 1px 1px;
  border-color: #BB0B0B;
  color: #ffffff;
  background-color: #bb0b0b;
}
.readon.price.price2 {
  background-color: #BB0B0B;
  border-style: solid;
  border-width: 1px 1px 1px 1px;
  border-color: #BB0B0B;
  background: #bb0b0b;
  color: #ffffff;
}
.readon.price.price2:hover {
  border-width: 1px 1px 1px 1px;
  border-color: #DF1313;
  background: #DF1313;
}
.readon.price.big {
  font-size: 20px;
  font-weight: 500;
}
.readon.apply {
  padding: 5px 15px 5px 15px;
  color: #fff;
  background-color:#51ad32; font-size: 14px; font-weight: 500;
}
.readon.view-more {
  padding: 7px 25px 7px;
  font-size: 14px;
  font-weight: 500;
  text-transform: uppercase;
  color: #111111;
  background-color: #fff;
  border-radius: 0px 0px 0px 0px;
}
.readon.view-more.small {
  padding: 7px 25px 7px 25px;
  border-radius: 4px 4px 4px 4px;
}
.readon2 {
  padding: 12px 35px 10px;
  border-radius: 30px;
  color: #ffffff !important;
  position: relative;
  display: inline-flex;
  align-items: center;
  background: #e94d65;
  text-align: center;
  cursor: pointer;
  font-size: 16px;
  outline: none;
  transition: all 0.3s ease !important;
}
.readon2 .btn-arrow {
  position: relative;
  width: 20px;
  height: 16px;
  overflow: hidden;
  margin-left: 11px;
}
.readon2 .btn-arrow:before,
.readon2 .btn-arrow:after {
  position: absolute;
  content: "\f114";
  font-family: Flaticon;
  top: 50%;
  transform: translateY(-50%);
  color: #ffffff;
  transition: all 0.3s ease;
}
.readon2 .btn-arrow:before {
  right: 1px;
}
.readon2 .btn-arrow:after {
  right: 22px;
}
.readon2:hover {
  color: #ffffff;
  background: #043d72;
}
.readon2:hover div:before,
.readon2:hover div:after {
  color: #e94d65;
}
.readon2:hover div:before {
  right: -20px;
}
.readon2:hover div:after {
  right: 1px;
}
.readon2.hover-light:hover {
  background: #ffffff;
  color: #e94d65 !important;
}
.readon2.hover-light:hover .btn-arrow:before,
.readon2.hover-light:hover .btn-arrow:after {
  color: #e94d65;
}
.left-line-v {
  position: relative;
  padding-left: 20px;
}
.left-line-v .draw-line.start-draw {
  position: absolute;
  content: '';
  left: 0;
  top: 3px;
  width: 4px;
  height: 0;
  background: #e94d65;
  animation: start-draw 1.5s ease forwards;
}
.blog-button a {
  text-align: center;
  display: inline-block;
  color: #016ab3;
  padding-right: 25px;
  position: relative;
  z-index: 1;
  font-weight: 700;
  font-size: 15px;
}
.blog-button a:after {
  content: "\f114";
  position: absolute;
  font-family: flaticon;
  font-size: 15px;
  top: 1px;
  right: 0;
  opacity: 1;
  transition: all 0.3s;
}
.blog-button a:hover:after {
  right: -5px;
  opacity: 1;
}
.blog-button.services a {
  font-size: 16px;
  font-weight: 600;
  color: #bb0b0b;
  padding-right: 12px;
}
.blog-button.services a:after {
  content: "\f113";
  position: absolute;
  font-family: flaticon;
  font-size: 10px;
  top: 1px;
  right: 0;
  opacity: 1;
  transition: all 0.3s;
  color: #bb0b0b;
}
.blog-button.services a:hover:after {
  right: -2px;
  opacity: 1;
  transform: unset;
}
.add-btn {
  cursor: pointer;
  display: inline-block;
  position: relative;
  transition: all 0.4s cubic-bezier(0.215, 0.61, 0.355, 1) 0s;
  padding: 10px 40px;
  border-radius: 3px;
  font-size: 16px;
  font-weight: 500;
  background: #bb0b0b;
  color: #ffffff;
  border: none;
}
.add-btn:hover {
  opacity: 0.7;
}
/*-- Submit Button Css --*/
.submit-btn {
  box-shadow: 0 28px 50px 0 rgba(0, 0, 0, 0.05);
  outline: 0;
  border: none;
  padding: 18px 18px 18px 18px;
  border-radius: 3px;
  display: inline-block;
  text-transform: capitalize;
  font-size: 16px;
  font-weight: 500;
  color: #fff;
  background-color: #4CAF50;
  transition: all .3s ease;
  position: relative;
  overflow: hidden;
  width: 100%;
  cursor: pointer;
}
.submit-btn:hover {
  opacity: 0.9;
}
.margin-remove {
  margin: 0 !important;
}
.no-gutter {
  margin-left: 0;
  margin-right: 0;
}
.no-gutter [class*="col-"] {
  padding-left: 0;
  padding-right: 0;
}
[class^="flaticon-"]:before,
[class*=" flaticon-"]:before,
[class^="flaticon-"]:after,
[class*=" flaticon-"]:after {
  margin: 0;
}
.container-fluid {
  padding-left: 100px;
  padding-right: 100px;
}
.container-fluid2 {
  padding-left: 15px;
  padding-right: 15px;
}
.display-table {
  display: table;
  height: 100%;
  width: 100%;
}
.display-table-cell {
  display: table-cell;
  vertical-align: middle;
}
.relative {
  position: relative;
}
.pt-relative {
  position: relative !important;
}
.sub-text {
  font-weight: 600;
  margin: 0 0 10px;
  display: inline-block;
  color: #bb0b0b;
  line-height: 28px;
  font-size: 15px;
  text-transform: uppercase;
}
.desc {
  font-weight: 500;
}
.sec-title .sub-text {
  font-size: 15px;
  line-height: 28px;
  font-weight: 600;
  color: #191821;
  margin: 0 0 10px;
  text-transform: capitalize;
  display: block;
}
.sec-title .sub-text.big {
  font-size: 17px;
  font-weight: 500;
  color: #333333;
}
.sec-title .sub-text.small {
  font-size: 15px;
  color: #0082f1;
  text-transform: uppercase;
}
.sec-title .title {
  font-size: 36px;
  line-height: 46px;
  font-weight: 700;
  color: #0a0a0a;
  margin: 0;
  position: relative;
  padding-top: 28px;
}
.sec-title .title span {
  color: #bb0b0b;
  font-style: oblique;
}
.sec-title .title span.new-text {
  font-style: unset;
}
.sec-title .title:after {
  content: "";
  position: absolute;
  border: 0;
  width: 65px;
  height: 5px;
  background: #bb0b0b;
  z-index: 1;
  margin-left: 0;
  left: 0;
  top: 0;
}
.sec-title .title.title2:after {
  left: 50%;
  transform: translateX(-50%);
}
.sec-title .title.title2.contact {
  max-width: 530px;
  margin: 0 auto;
  color: #ffffff;
}
.sec-title .title.title3 {
  padding-top: 0;
}
.sec-title .title.title3:after {
  display: none;
}
.sec-title .title.title3.small {
  font-size: 28px;
}
.sec-title .title.title4 {
  font-size: 28px;
  line-height: 36px;
}
.sec-title .title span {
  color: #bb0b0b;
}
.sec-title .title-inner .title-small {
  font-size: 17px;
  line-height: 26px;
  font-weight: 600;
  margin: 0px 0px 6px 0px;
  color: #0a0a0a;
}
.sec-title2 .title {
  font-size: 36px;
}
.sec-title2 .title span {
  color: #bb0b0b;
  font-style: oblique;
}
.sec-title2 .title span.new-next {
  font-style: unset;
}
.sec-title2 .first-half {
  max-width: 50%;
  flex: 0 0 50%;
}
.sec-title2 .last-half {
  max-width: 50%;
  flex: 0 0 50%;
  font-weight: 500;
}
.sec-title3 {
  position: relative;
}
.sec-title3 .sub-title {
  font-size: 15px;
  font-weight: 600;
  text-transform: uppercase;
  color: #fd4a36;
  display: block;
  margin: 0 0 10px;
}
.sec-title3 .sub-title.paste-color {
  color: #4caf50;
}
.sec-title3 .sub-text {
  font-size: 15px;
  font-weight: 500;
  line-height: 28px;
  text-transform: uppercase;
  color: #fd4a36;
  margin: 0 0 10px;
  display: block;
  position: relative;
  padding-left: 107px;
}
.sec-title3 .sub-text:before {
  content: '';
  position: absolute;
  left: 0;
  top: 12px;
  height: 4px;
  width: 12px;
  border-radius: 2px;
  background-color: #fd4a36;
  transition: all .3s ease;
  z-index: 0;
}
.sec-title3 .sub-text:after {
  content: "";
  position: absolute;
  border: 0;
  height: 4px;
  width: 55px;
  z-index: 1;
  margin-left: 0;
  left: 24px;
  top: 12px;
  border-radius: 2px;
  background-color: #fd4a36;
  transition: all .3s ease;
  z-index: 0;
}
.sec-title3 .title {
  font-size: 36px;
  font-weight: 700;
  line-height: 46px;
  color: #0a0a0a;
  margin: 0;
}
.sec-title3 .heading-border-line {
  position: relative;
}
.sec-title3 .heading-border-line:before {
  content: "";
  width: 12px;
  height: 4px;
  background: #fd4a36;
  position: absolute;
  bottom: -4px;
  left: 50%;
  margin-left: -35px;
  transform: translateX(-50%);
  border-radius: 2px;
  -webkit-transition: all .3s ease;
  transition: all .3s ease;
  z-index: 1;
}
.sec-title3 .heading-border-line:after {
  content: "";
  width: 65px;
  height: 4px;
  background: #fd4a36;
  position: absolute;
  bottom: -4px;
  right: 0;
  left: 50%;
  margin-left: -20px;
  border-radius: 2px;
  -webkit-transition: all .3s ease;
  transition: all .3s ease;
  z-index: 0;
}
.sec-title3 .heading-border-line.left-style {
  position: relative;
  margin-left: 44px;
}
.sec-title3 .heading-border-line.left-style:before {
  left: 0;
}
.sec-title3 .heading-border-line.left-style:after {
  left: 0;
}
.sec-title3:hover .sub-text:before {
  width: 65px;
  background: #fd4a36;
}
.sec-title3:hover .sub-text:after {
  margin-left: 46px;
  width: 12px;
  background: #fd4a36;
}
.sec-title3:hover .heading-border-line {
  position: relative;
}
.sec-title3:hover .heading-border-line:before {
  margin-left: -5px;
  width: 65px;
  background: #fd4a36;
}
.sec-title3:hover .heading-border-line:after {
  width: 12px;
  margin-left: 35px;
  background: #fd4a36;
}
.sec-title4 {
  position: relative;
  z-index: 1;
}
.sec-title4 .sub-title {
  color: #e94d65;
  text-transform: uppercase;
  font-size: 22px;
  font-weight: 500;
  margin-bottom: 14px;
}
.sec-title4 .title {
  color: #043d72;
}
/* -----------------------
    03. Header Section CSS
--------------------------*/
.full-width-header {
  position: relative;
}
.full-width-header .rs-header {
  position: relative;
}
.full-width-header .rs-header .topbar-area.style1 {
  background: transparent;
  border-bottom: 1px solid rgba(255, 255, 255, 0.2);
}
.full-width-header .rs-header .topbar-area.style1 .topbar-contact li {
  display: inline-block;
  color: #ffffff;
  font-size: 12px;
  line-height:30px;
  font-weight: 500;
  border-right: 1px solid rgba(255, 255, 255, 0.2);
  margin-right: 10px;
  /*padding-right: 20px;*/
}
.full-width-header .rs-header .topbar-area.style1 .topbar-contact li i {
  margin-right: 5px;
}
.full-width-header .rs-header .topbar-area.style1 .topbar-contact li i:before {
  font-size: 16px;
  font-weight: 400;
  color: #ffffff;
}
.full-width-header .rs-header .topbar-area.style1 .topbar-contact li a {
  color: #ffffff;
  font-size: 12px;
}
.full-width-header .rs-header .topbar-area.style1 .topbar-contact li a:hover {
  color: #bb0b0b;
}
.full-width-header .rs-header .topbar-area.style1 .topbar-contact li:last-child {
  margin: 0;
  border: none;
}
.full-width-header .rs-header .topbar-area.style1 .toolbar-sl-share ul .opening {
  color: #ffffff;
  /*border-right: 1px solid rgba(255, 255, 255, 0.2);*/
  margin-right: 15px;
/*  padding-right: 40px;*/
}
.full-width-header .rs-header .topbar-area.style1 .toolbar-sl-share ul .opening em {
  font-size: 13px;
  font-style: normal;
}
.full-width-header .rs-header .topbar-area.style1 .toolbar-sl-share ul .opening em i {
  margin-right: 8px;
}
.full-width-header .rs-header .topbar-area.style1 .toolbar-sl-share ul .opening em i:before {
  font-size: 13px;
  color: #ffffff;
}
.full-width-header .rs-header .topbar-area.style1 .toolbar-sl-share ul{ display: flex; justify-content: flex-end; align-items: center; }
.full-width-header .rs-header .topbar-area.style1 .toolbar-sl-share ul li {
  /*display: inline-block;*/
  font-size: 14px;
  line-height: 30px;
  margin-right: 12px;
}
.full-width-header .rs-header .topbar-area.style1 .toolbar-sl-share ul li a {
  font-size:13px;
  color: #ffffff; display: flex; align-items: center; justify-content: center;
}
.full-width-header .rs-header .topbar-area.style1 .toolbar-sl-share ul li a:hover {
  color: #4fc526;
}
.full-width-header .rs-header .topbar-area.style1 .toolbar-sl-share ul li:last-child {
  margin: 0;
}
.full-width-header .rs-header .topbar-area.style2 {
  background-color: unset !important;
  padding-bottom: 30px;
}
.full-width-header .rs-header .topbar-area.style2 .row-table {
  display: flex;
  width: 100%;
  position: relative;
  align-items: center;
  justify-content: space-between;
}
.full-width-header .rs-header .topbar-area.style2 .row-table .header-logo {
  display: flex;
  flex: 1;
}
.full-width-header .rs-header .topbar-area.style2 .row-table .header-logo .logo-area a .normal-logo {
  display: block;
  max-height: 50px;
}
.full-width-header .rs-header .topbar-area.style2 .row-table .header-logo .logo-area a .sticky-logo {
  display: none;
  max-height: 50px;
}
.full-width-header .rs-header .topbar-area.style2 .row-table .header-quote {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.full-width-header .rs-header .topbar-area.style2 .row-table .header-quote .rs-address-area .address-list {
  display: inline-block;
  padding-right: 25px;
  padding-left: 25px;
  border-right: 1px solid #eee;
  font-size: 14px;
}
.full-width-header .rs-header .topbar-area.style2 .row-table .header-quote .rs-address-area .address-list .info-icon {
  float: left;
  margin-right: 15px;
  line-height: 35px;
}
.full-width-header .rs-header .topbar-area.style2 .row-table .header-quote .rs-address-area .address-list .info-icon i:before {
  font-size: 26px;
  color: #0082f1;
}
.full-width-header .rs-header .topbar-area.style2 .row-table .header-quote .rs-address-area .address-list .info-content {
  float: left;
  text-align: left;
  display: block;
}
.full-width-header .rs-header .topbar-area.style2 .row-table .header-quote .rs-address-area .address-list .info-content .info-title {
  font-size: 15px;
  line-height: 18px;
  font-weight: 600;
}
.full-width-header .rs-header .topbar-area.style2 .row-table .header-quote .rs-address-area .address-list .info-content .info-title a {
  color: #0a0a0a;
}
.full-width-header .rs-header .topbar-area.style2 .row-table .header-quote .rs-address-area .address-list .info-content .info-title a:hover {
  color: #0082f1;
}
.full-width-header .rs-header .topbar-area.style2 .row-table .header-quote .rs-address-area .address-list .info-content .info-des {
  font-size: 14px;
  color: #595858;
}
.full-width-header .rs-header .topbar-area.style2 .row-table .header-quote .rs-address-area .address-list .info-content .info-des a {
  color: #595858;
}
.full-width-header .rs-header .topbar-area.style2 .row-table .header-quote .rs-address-area .address-list .info-content .info-des a:hover {
  color: #0082f1;
}
.full-width-header .rs-header .topbar-area.style2 .row-table .header-quote .rs-address-area .address-list:last-child {
  border: none;
}
.full-width-header .rs-header .menu-area {
  transition: all 0.3s ease 0s;
  background: transparent;
}
.full-width-header .rs-header .menu-area .row-table {
  display: flex;
  width: 100%;
  position: relative;
  align-items: center;
  justify-content: flex-start;
}
.full-width-header .rs-header .menu-area .row-table .col-cell {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.full-width-header .rs-header .menu-area .row-table .col-cell.header-logo {
  display: flex; margin-right:5%;
  /*flex: 1;*/
}
.full-width-header .rs-header .menu-area .logo-area a .sticky-logo {
  display: none;
}
.full-width-header .rs-header .menu-area .logo-area img {
  transition: 0.4s;
  -webkit-transition: 0.4s;
  max-height:70px;
}
.full-width-header .rs-header .menu-area .logo-area .dark {
  display: none;
}
.full-width-header .rs-header .menu-area .logo-area .light {
  display: inherit;
}
.full-width-header .rs-header .menu-area .rs-menu-area {
  display: flex;
  align-items: center;
  /*margin-right: 72px;*/
}
.full-width-header .rs-header .menu-area .main-menu {
  position: unset;
}
.full-width-header .rs-header .menu-area .main-menu .rs-menu ul.nav-menu li {
  display: inline-block;
  margin-right: 32px;
  padding: 0;
}
.full-width-header .rs-header .menu-area .main-menu .rs-menu ul.nav-menu li a {
  transition: all 0.3s ease;
  font-size: 15px;
  font-weight: 600;
  margin-right: 10px;
  height: 95px;
  line-height: 95px;
  padding: 0;
  color: #ffffff;
  position: relative;
  font-family:'Inter'; /*text-shadow: 0px 1.6px 3.6px rgb(0 0 0 / 13%), 0px 0px 2.9px rgb(0 0 0 / 11%);*/
}
.full-width-header .rs-header .menu-area .main-menu .rs-menu ul.nav-menu li a:hover {
  color: #bb0b0b;
}
.full-width-header .rs-header .menu-area .main-menu .rs-menu ul.nav-menu li.menu-item-has-children > a {
  padding-right: 17px;
}
.full-width-header .rs-header .menu-area .main-menu .rs-menu ul.nav-menu li.menu-item-has-children > a:before {
  content: "+";
  position: absolute;
  left: auto;
  right: 0;
  top: 50%;
  text-align: center;
  z-index: 9999;
  font-size: 16px;
  display: block;
  cursor: pointer;
  transform: translateY(-50%);
  font-weight: 400;
}
.full-width-header .rs-header .menu-area .main-menu .rs-menu ul.nav-menu li.menu-item-has-children:hover > a:before {
  content: "-";
}
.full-width-header .rs-header .menu-area .main-menu .rs-menu ul.nav-menu li.current-menu-item > a {
  color: #bb0b0b;
}
.full-width-header .rs-header .menu-area .main-menu .rs-menu ul.sub-menu {
  background: #ffffff;
  margin: 0;
  padding:8px 0;
}
.full-width-header .rs-header .menu-area .main-menu .rs-menu ul.sub-menu li {
  margin: 0 !important;
  border: none;
}
.full-width-header .rs-header .menu-area .main-menu .rs-menu ul.sub-menu li a {
  padding-top:6px;
  padding-bottom:6px;
  padding-left:20px;
  padding-right: 10px;
  margin: 0;
  font-family: 'Inter';
  font-size: 14px;
  color: #101010;
  font-weight: 500;
  height: unset;
  line-height:18px;
  text-transform: capitalize;
}
.full-width-header .rs-header .menu-area .main-menu .rs-menu ul.sub-menu li a:hover {
  color: #bb0b0b;
}
.full-width-header .rs-header .menu-area .main-menu .rs-menu ul.sub-menu li.menu-item-has-children a:before {
  display: none;
}
.full-width-header .rs-header .menu-area .main-menu .rs-menu ul.sub-menu li:last-child {
  margin: 0;
}
.full-width-header .rs-header .menu-area .main-menu .rs-menu ul.sub-menu.right {
  left: auto;
  right: 100%;
}
.full-width-header .rs-header .menu-area .main-menu .rs-menu ul.onepage-menu li.current-menu-item > a {
  color: #bb0b0b;
}
.full-width-header .rs-header .menu-area .main-menu .rs-menu ul li a:hover,
.full-width-header .rs-header .menu-area .main-menu .rs-menu ul li.active a,
.full-width-header .rs-header .menu-area .main-menu .rs-menu ul li.current-menu-item > a,
.full-width-header .rs-header .menu-area .main-menu .rs-menu ul li.current-menu-item > a:before {
  color: #4ec226 !important; text-shadow: none; 
}
.full-width-header .rs-header .menu-area.sticky {
  background: #fff;
}
.full-width-header .rs-header .menu-area.sticky .logo-area a .sticky-logo {
  display: block;
  max-height: 50px;
}
.full-width-header .rs-header .menu-area.sticky .logo-area a .normal-logo {
  display: none;
}
.full-width-header .rs-header .menu-area.sticky .main-menu .rs-menu ul.nav-menu > li > a {
  color: #101010;
}
.full-width-header .rs-header .menu-area.sticky .main-menu .rs-menu ul.nav-menu > li > a:hover {
  color: #bb0b0b;
}
.full-width-header .rs-header .menu-area.sticky .main-menu .rs-menu ul.nav-menu > li.current-menu-item > a {
  color: #bb0b0b;
}
.full-width-header .rs-header .menu-area.sticky .expand-btn-inner ul .rsphone a {
  color: #101010;
}
.full-width-header .rs-header .menu-area.sticky .expand-btn-inner ul .rsphone a:hover {
  color: #bb0b0b;
}
.full-width-header .rs-header .menu-area.sticky .expand-btn-inner ul .icon-bar .cart-icon i {
  color: #0a0a0a;
}
.full-width-header .rs-header .menu-area.sticky .expand-btn-inner ul .search-parent a i {
  color: #101010;
}
.full-width-header .rs-header .menu-area.sticky .expand-btn-inner ul .search-parent a i:hover {
  color: #bb0b0b;
}
.full-width-header .rs-header .menu-area.sticky .expand-btn-inner ul .nav-link .nav-expander .bar span {
  background: #101010;
}
.full-width-header .rs-header .menu-area.sticky .expand-btn-inner ul .nav-link .nav-expander:hover .bar span {
  background: #bb0b0b;
}
.full-width-header .rs-header .expand-btn-inner {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.full-width-header .rs-header .expand-btn-inner ul {
  display: flex;
  align-items: center;
}
.full-width-header .rs-header .expand-btn-inner ul li {
  display: inline-block;
  margin-right: 22px;
}
.full-width-header .rs-header .expand-btn-inner ul li > a {
  color: #ffffff;
  display: inline-block;
  cursor: pointer;
}
.full-width-header .rs-header .expand-btn-inner ul li > a i:before {
  font-size: 18px;
  font-weight: 600;
}
.full-width-header .rs-header .expand-btn-inner ul li > a i.flaticon-shopping-bag-1:before {
  font-weight: normal;
}
.full-width-header .rs-header .expand-btn-inner ul li > a:hover {
  color: #bb0b0b;
}
.full-width-header .rs-header .expand-btn-inner ul li:last-child {
  margin-right: 0;
}
.full-width-header .rs-header .expand-btn-inner ul .humburger .nav-expander .bar {
  padding:20px 0px 0px;
  background: transparent;
  max-width: 50px;
  display: flex;
  flex-wrap: wrap;
  border-radius: 2px;
  padding-right: 0;
  margin-right:0; width: 35px; height:48px;
}
.full-width-header .rs-header .expand-btn-inner ul .humburger .nav-expander .bar span {
  height: 5px;
  width: 5px;
  display: block;
  background: #0069b3;
  border-radius: 50%;
  transition: none;
  list-style: none;
  transition: all 0.3s ease;
  margin: 2px 3px;
}
.full-width-header .rs-header .expand-btn-inner ul .humburger .nav-expander .bar span.dot2,
.full-width-header .rs-header .expand-btn-inner ul .humburger .nav-expander .bar span.dot3,
.full-width-header .rs-header .expand-btn-inner ul .humburger .nav-expander .bar span.dot4,
.full-width-header .rs-header .expand-btn-inner ul .humburger .nav-expander .bar span.dot5,
.full-width-header .rs-header .expand-btn-inner ul .humburger .nav-expander .bar span.dot6,
.full-width-header .rs-header .expand-btn-inner ul .humburger .nav-expander .bar span.dot7,
.full-width-header .rs-header .expand-btn-inner ul .humburger .nav-expander .bar span.dot8 {
  background:#0069b3;
}
.full-width-header .rs-header.header-transparent {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 999;
}
.full-width-header .rs-header.style2 {
  position: static;
}
.full-width-header .rs-header.style2 .topbar-area {
  background-color: #0a1437;
}
.full-width-header .rs-header.style2 .topbar-area.style1 .topbar-contact li a:hover {
  color: #fff;
}
.full-width-header .rs-header.style2 .menu-area .main-menu .rs-menu ul.nav-menu li a {
  color: #0a0a0a;
}

.full-width-header .rs-header.style2 .menu-area .main-menu .rs-menu ul.sub-menu li {
  margin: 0 !important;
  border-bottom: 1px solid #f7f7f7; display: block;
}
.full-width-header .rs-header.style2 .menu-area .main-menu .rs-menu ul.sub-menu li:last-child{ border-bottom:none; }
.full-width-header .rs-header.style2 .menu-area .main-menu .rs-menu ul.sub-menu li a:hover, .full-width-header .rs-header.style2 .menu-area .main-menu .rs-menu ul.sub-menu li a:focus {
  color: #398d1d; background: #ebf7e8;
}
.full-width-header .rs-header.style2 .menu-area .main-menu .rs-menu ul.sub-menu li:focus-within > [aria-haspopup="true"], .full-width-header .rs-header.style2 .menu-area .main-menu .rs-menu ul.sub-menu li > [aria-haspopup="true"]:focus{  color: #398d1d; background: #ebf7e8; }
.full-width-header .rs-header.style2 .menu-area .main-menu .rs-menu ul li a:hover,
.full-width-header .rs-header.style2 .menu-area .main-menu .rs-menu ul li.active a,
.full-width-header .rs-header.style2 .menu-area .main-menu .rs-menu ul li.current-menu-item > a {
  color: #398d1d;
}
.full-width-header .rs-header.style2 .expand-btn-inner ul li > a {
  color: #0a0a0a;
}
.full-width-header .rs-header.style2 .expand-btn-inner ul li > a:hover {
  color: #398d1d;
}
.full-width-header .rs-header.style2 .expand-btn-inner ul li:last-child {
  margin-right: 0;
}
.full-width-header .rs-header.style2.header-home4-style .menu-area .main-menu .rs-menu ul.nav-menu li a {
  color: #0a0a0a;
  height: 116px;
  line-height: 116px;
}
.full-width-header .rs-header.style2.header-home4-style .menu-area .main-menu .rs-menu ul.sub-menu li {
  margin: 0 !important;
  border: none;
}
.full-width-header .rs-header.style2.header-home4-style .menu-area .main-menu .rs-menu ul.sub-menu li a {
  height: unset;
  line-height: unset;
}
.full-width-header .rs-header.style2.header-home4-style .menu-area .main-menu .rs-menu ul.sub-menu li a:hover {
  color: #bb0b0b;
}
.full-width-header .rs-header.style2.header-home4-style .menu-area .main-menu .rs-menu ul li a:hover,
.full-width-header .rs-header.style2.header-home4-style .menu-area .main-menu .rs-menu ul li.active a,
.full-width-header .rs-header.style2.header-home4-style .menu-area .main-menu .rs-menu ul li.current-menu-item > a {
  color: #bb0b0b;
}
.full-width-header .rs-header.style2.header-home4-style .expand-btn-inner ul li > a {
  color: #0a0a0a;
}
.full-width-header .rs-header.style2.header-home4-style .expand-btn-inner ul li > a:hover {
  color: #bb0b0b;
}
.full-width-header .rs-header.style2.header-home4-style .expand-btn-inner ul li:last-child {
  margin-right: 0;
}
.full-width-header .rs-header.style2.header-home4-style .expand-btn-inner ul .humburger {
  display: none;
}
.full-width-header .rs-header.style3 {
  bottom: -33px;
}
.full-width-header .rs-header.style3 .box-layout {
  position: relative;
  z-index: 10;
  max-width: 1172px;
  margin: 0 auto;
  border-radius: 1px;
  background: #0082f1;
  padding: 0 30px;
}
.full-width-header .rs-header.style3 .box-layout .row-table .header-logo {
  display: none;
}
.full-width-header .rs-header.style3 .box-layout .row-table .menu-responsive .main-menu .rs-menu ul.nav-menu li a {
  color: #ffffff;
  height: 65px;
  line-height: 65px;
}
.full-width-header .rs-header.style3 .box-layout .row-table .menu-responsive .main-menu .rs-menu ul.nav-menu li a:hover {
  color: #bbb !important;
}
.full-width-header .rs-header.style3 .box-layout .row-table .menu-responsive .main-menu .rs-menu ul.nav-menu.menu-item-has-children > a {
  padding-right: 17px;
}
.full-width-header .rs-header.style3 .box-layout .row-table .menu-responsive .main-menu .rs-menu ul.nav-menu.menu-item-has-children > a:before {
  content: "+";
  position: absolute;
  left: auto;
  right: 0;
  top: 50%;
  text-align: center;
  z-index: 9999;
  font-size: 16px;
  display: block;
  cursor: pointer;
  transform: translateY(-50%);
  font-weight: 400;
}
.full-width-header .rs-header.style3 .box-layout .row-table .menu-responsive .main-menu .rs-menu ul.nav-menu.menu-item-has-children:hover > a:before {
  content: "-";
}
.full-width-header .rs-header.style3 .box-layout .row-table .menu-responsive .main-menu .rs-menu ul.nav-menu.current-menu-item > a {
  color: #bbb !important;
}
.full-width-header .rs-header.style3 .box-layout .row-table .menu-responsive .main-menu .rs-menu ul.sub-menu li a {
  color: #101010;
  line-height: 25px;
  height: unset;
}
.full-width-header .rs-header.style3 .box-layout .row-table .menu-responsive .main-menu .rs-menu ul.sub-menu li a:hover {
  color: #0082f1 !important;
}
.full-width-header .rs-header.style3 .box-layout .row-table .menu-responsive .main-menu .rs-menu ul li a:hover,
.full-width-header .rs-header.style3 .box-layout .row-table .menu-responsive .main-menu .rs-menu ul li.active a,
.full-width-header .rs-header.style3 .box-layout .row-table .menu-responsive .main-menu .rs-menu ul li.current-menu-item > a {
  color: #0082f1 !important;
}
.full-width-header .rs-header.style3 .box-layout .row-table .menu-responsive .sticky {
  background: transparent;
}
.full-width-header .rs-header.style3 .box-layout .row-table .menu-responsive .sticky .box-layout {
  background: unset !important;
}
.full-width-header .rs-header.style3 .box-layout .row-table .menu-responsive .sticky .logo-area a .sticky-logo {
  display: block;
  max-height: 50px;
}
.full-width-header .rs-header.style3 .box-layout .row-table .menu-responsive .sticky .logo-area a .normal-logo {
  display: none;
}
.full-width-header .rs-header.style3 .box-layout .row-table .menu-responsive .sticky .main-menu .rs-menu ul.nav-menu > li > a {
  color: #101010 !important;
}
.full-width-header .rs-header.style3 .box-layout .row-table .menu-responsive .sticky .main-menu .rs-menu ul.nav-menu > li > a:hover {
  color: #0082f1 !important;
}
.full-width-header .rs-header.style3 .box-layout .row-table .menu-responsive .sticky .main-menu .rs-menu ul.nav-menu > li.current-menu-item > a {
  color: #0082f1 !important;
}
.full-width-header .rs-header.style3 .box-layout .row-table .rightbar-menu {
  display: flex;
  align-items: center;
}
.full-width-header .rs-header.style3 .box-layout .row-table .rightbar-menu .expand-btn-inner .search-parent {
  margin-right: 24px;
}
.full-width-header .rs-header.style3 .box-layout .row-table .rightbar-menu .expand-btn-inner .search-parent a i:before {
  color: #ffffff;
}
.full-width-header .rs-header.style3 .box-layout .row-table .rightbar-menu .expand-btn-inner .humburger {
  display: none;
}
.full-width-header .rs-header.style3 .box-layout .row-table .rightbar-menu .expand-btn-inner .toolbar-sl-share {
  border-left: 1px solid rgba(255, 255, 255, 0.42);
  padding-left: 22px;
  border-color: #fff;
}
.full-width-header .rs-header.style3 .box-layout .row-table .rightbar-menu .expand-btn-inner .toolbar-sl-share li {
  display: inline-block;
  margin-right: 22px;
}
.full-width-header .rs-header.style3 .box-layout .row-table .rightbar-menu .expand-btn-inner .toolbar-sl-share li a i {
  color: #ffffff;
}
.full-width-header .rs-header.style3 .box-layout .row-table .rightbar-menu .expand-btn-inner .toolbar-sl-share li a i:before {
  font-size: 14px;
}
.full-width-header .rs-header.style3 .box-layout .row-table .rightbar-menu .expand-btn-inner .toolbar-sl-share li a i:hover {
  color: #ccc;
}
.full-width-header .rs-header.style3 .box-layout .row-table .rightbar-menu .expand-btn-inner .toolbar-sl-share li:last-child {
  margin-right: 0;
}
.full-width-header .rs-header.style3 .sticky {
  background: transparent;
}
.full-width-header .rs-header.style3 .sticky .box-layout {
  background: unset !important;
}
.full-width-header .rs-header.style3 .sticky .box-layout .row-table .header-logo {
  display: block;
}
.full-width-header .rs-header.style3 .sticky .box-layout .row-table .header-logo .logo-area a img {
  max-height: 40px;
}
.full-width-header .rs-header.style3 .sticky .box-layout .row-table .menu-responsive .main-menu .rs-menu ul.nav-menu li a {
  color: #101010;
}
.full-width-header .rs-header.style3 .sticky .box-layout .row-table .menu-responsive .main-menu .rs-menu ul.nav-menu li a:hover {
  color: #0082f1 !important;
}
.full-width-header .rs-header.style3 .sticky .box-layout .row-table .menu-responsive .main-menu .rs-menu ul.nav-menu.menu-item-has-children > a {
  padding-right: 17px;
}
.full-width-header .rs-header.style3 .sticky .box-layout .row-table .menu-responsive .main-menu .rs-menu ul.nav-menu.menu-item-has-children:hover > a:before {
  content: "-";
}
.full-width-header .rs-header.style3 .sticky .box-layout .row-table .menu-responsive .main-menu .rs-menu ul.nav-menu.current-menu-item > a {
  color: #bbb !important;
}
.full-width-header .rs-header.style3 .sticky .box-layout .row-table .rightbar-menu {
  display: flex;
  align-items: center;
}
.full-width-header .rs-header.style3 .sticky .box-layout .row-table .rightbar-menu .expand-btn-inner .search-parent {
  margin-right: 24px;
}
.full-width-header .rs-header.style3 .sticky .box-layout .row-table .rightbar-menu .expand-btn-inner .search-parent a i:before {
  color: #0a0a0a;
}
.full-width-header .rs-header.style3 .sticky .box-layout .row-table .rightbar-menu .expand-btn-inner .search-parent a i:hover {
  color: #0082f1;
}
.full-width-header .rs-header.style3 .sticky .box-layout .row-table .rightbar-menu .expand-btn-inner .toolbar-sl-share {
  display: none;
}
.full-width-header .rs-header.header-home5-style .menu-area {
  padding-top: 10px;
}
.full-width-header .rs-header.header-home5-style .expand-btn-inner ul li > a:hover,
.full-width-header .rs-header.header-home5-style .menu-area .main-menu .rs-menu ul li.active a,
.full-width-header .rs-header.header-home5-style .menu-area .main-menu .rs-menu ul.nav-menu li a:hover,
.full-width-header .rs-header.header-home5-style .menu-area .main-menu .rs-menu ul li.current-menu-item > a:before,
.full-width-header .rs-header.header-home5-style .menu-area .main-menu .rs-menu ul.nav-menu li.current-menu-item > a {
  color: #4caf50 !important;
}
.full-width-header .rs-header.header-home5-style .menu-area.sticky {
  padding-top: unset;
}
.full-width-header .rs-header.header-home5-style .menu-area.sticky .expand-btn-inner ul .search-parent a i:hover {
  color: #4caf50;
}
.full-width-header .rs-header.header-home5-style .expand-btn-inner ul .humburger {
  display: none;
}
.full-width-header .mobile-menu {
  display: none;
}
.full-width-header .mobile-menu .mobile-logo {
  float: left;
}
.full-width-header .mobile-menu .mobile-logo img {
  max-height: 30px;
}
.full-width-header .mobile-menu .rs-menu-toggle {
  color: #ffffff;
  float: right;
}
.full-width-header .mobile-menu .rs-menu-toggle.primary {
  color: #bb0b0b;
}
.full-width-header .mobile-menu .rs-menu-toggle.secondary {
  color: #191821;
}
.full-width-header .mobile-menu .rs-menu-toggle .nav-dots {
  position: absolute;
  right: 0;
  width: 25px;
  height: 2px;
  background: #fff;
  z-index: 11;
  top: 23px;
}
.full-width-header .mobile-menu .rs-menu-toggle .dot1,
.full-width-header .mobile-menu .rs-menu-toggle .dot3 {
  width: 15px;
}
.full-width-header .mobile-menu .rs-menu-toggle .dot2 {
  top: 28px;
}
.full-width-header .mobile-menu .rs-menu-toggle .dot3 {
  top: 34px;
}
.full-width-header .mobile-menu .rs-menu-toggle .dot4 {
  top: 40px;
}
.full-width-header .right_menu_togle {
  background: rgb(209,234,254);
  background: linear-gradient(54deg, rgba(209,234,254,1) 0%, rgba(224,254,228,1) 100%);
  padding:25px 20px 30px 20px;
  font-size: 1.15em;
  max-width: 480px;
  width: 100%;
  right: -500px;
  z-index: 999999;
  transition: all 0.3s ease;
  position: fixed;
  overflow-y: auto;
}
.full-width-header .right_menu_togle .close-btn {
  overflow: visible;
}
.full-width-header .right_menu_togle .close-btn .nav-close {
  position: absolute;
  top: 15px;
  display: block;
  z-index: 99;
  right:18px;
  cursor: pointer;
  transition: all .3s ease;
}
.full-width-header .right_menu_togle .close-btn .nav-close .line {
  width: 40px;
  height: 40px;
  line-height: 40px;
}
.full-width-header .right_menu_togle .close-btn .nav-close .line span {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%) rotate(45deg);
  width: 2px !important;
  height: 25px !important;
  background: #363636;
  border: none !important;
  transition: all 0.3s ease;
}
.full-width-header .right_menu_togle .close-btn .nav-close .line span.line2 {
  transform: translate(-50%, -50%) rotate(-45deg);
}
.full-width-header .right_menu_togle .close-btn:hover .nav-close .line span {
  background: #bb0b0b;
}
.full-width-header .right_menu_togle .canvas-logo {
  padding-left: 0;
  padding-bottom: 25px;
}
.full-width-header .right_menu_togle .canvas-logo img {
  max-height: 28px;
}
.full-width-header .right_menu_togle .offcanvas-text p {
  font-size: 16px;
  line-height: 30px;
  color: #ffffff;
  margin: 5px 0 45px;
}
.full-width-header .right_menu_togle .canvas-contact {
 padding: 30px 0px;/*  position: absolute; left: 0;  bottom: 0;*/
}
.full-width-header .right_menu_togle .canvas-contact .address-area .address-list {
  display: flex;
  margin-top: 18px;
}
.full-width-header .right_menu_togle .canvas-contact .address-area .address-list .info-icon i:before {
  margin:4px 10px 0 0;
  font-size: 26px;
  color:#4bbb25; 
}
.full-width-header .right_menu_togle .canvas-contact .address-area .address-list .info-content .title {
  font-size: 16px;
  line-height: 26px;
  font-weight: 600;
  color: #363636;
  margin-bottom: 5px;
}
.full-width-header .right_menu_togle .canvas-contact .address-area .address-list .info-content em {
  display: block;
  font-style: normal;
  line-height: 22px;
  font-size: 15px;
  color: #363636;
}
.full-width-header .right_menu_togle .canvas-contact .address-area .address-list .info-content em a {
  color: #363636;
}
.full-width-header .right_menu_togle .canvas-contact .address-area .address-list .info-content em a:hover {
  color: #bb0b0b;
}
.full-width-header .right_menu_togle .canvas-contact .social {
  margin: 40px 0 0;
}
.full-width-header .right_menu_togle .canvas-contact .social li {
  display: inline-block;
}
.full-width-header .right_menu_togle .canvas-contact .social li a i {
  font-size: 15px;
  color: #fff;
  margin: 0 8px 0 0;
  text-align: center;
  border: 1px solid rgba(255, 255, 255, 0.1);
  width: 35px;
  height: 35px;
  line-height: 35px;
  border-radius: 0px;
}
.full-width-header .right_menu_togle .canvas-contact .social li a i:hover {
  background: #bb0b0b;
  color: #ffffff;
}
.full-width-header .right_menu_togle .canvas-contact .social li:last-child {
  margin: 0;
}
.full-width-header .mobile-navbar-menu .sub-menu {
  display: none;
  position: relative;
  left: 0;
  width: 100%;
  margin: 0;
  text-align: left;
}
.full-width-header .mobile-navbar-menu .nav-menu li {
  position: relative;
}
.full-width-header .mobile-navbar-menu .nav-menu li a {
  border-bottom: 1px solid rgba(0, 0, 0, 0.06);
  display: block;
  color: #363636;
  padding:5px 0;
  line-height: 30px;
  width: 100%;
}
.full-width-header .mobile-navbar-menu .nav-menu li li a {
  padding-left: 20px;
}
.full-width-header .mobile-navbar-menu .nav-menu li li li a {
  padding-left:30px;
}
.full-width-header .mobile-navbar-menu span.submenu-button {
  position: absolute;
  z-index: 99;
  right: 0;
  top: -3px;
  display: block;
  height: 40px;
  width: 40px;
  cursor: pointer;
}
.full-width-header .mobile-navbar-menu span.submenu-button:before,
.full-width-header .mobile-navbar-menu span.submenu-button:after {
  position: absolute;
  top: 24px;
  right: 10px;
  width: 20px;
  height: 1px;
  display: block;
  background:#363636;
  z-index: 2;
  content: '';
  right: 12px;
  width: 12px;
}
.full-width-header .mobile-navbar-menu span.submenu-button:before {
  width: 1px;
  height: 12px;
  right: 17px;
  top: 18px;
}
.full-width-header .mobile-navbar-menu span.submenu-button em {
  display: block;
  position: absolute;
  right: 0;
  border-radius: 4px;
  top: 50%;
  transform: translateY(-50%);
}
.full-width-header .mobile-navbar-menu .hash-has-sub span.submenu-button {
  width: 100%;
  cursor: pointer;
  top: 0;
  height: 50px;
}
.full-width-header .mobile-navbar-menu .submenu-button.submenu-opened:before {
  display: none;
}
.full-width-header .mobile-navbar-menu ul ul li,
.full-width-header .mobile-navbar-menu li:hover > ul > li {
  height: auto;
}
.full-width-header .mobile-navbar-menu ul.nav-menu > li:first-child > span.submenu-button:after {
  top: 19px;
}
.full-width-header .mobile-navbar-menu ul.nav-menu > li:first-child > span.submenu-button:before {
  top: 13px;
}
.full-width-header .mobile-navbar-menu .nav-menu li.active-menu a,
.full-width-header .mobile-navbar-menu .nav-menu li a:hover {
  color:#51ad32;
}
body.modal-open {
  padding: 0 !important;
}
body .modal.show .modal-dialog {
  width: 100%;
  max-width: 500px;
}
body .search-modal {
  padding: 0 !important;
  background: #000;
}
body .search-modal .modal-content {
  position: initial;
  border: 0;
  background: unset;
}
body .search-modal .search-block form {
  position: relative;
}
body .search-modal .search-block form input {
  background: none;
  border: none;
  color: #fff;
  border-bottom: 2px solid rgba(255, 255, 255, 0.2);
  outline: none;
  font-size: 35px;
}
body .search-modal .search-block form button {
  background: #000;
  border: none;
  padding: 5px 20px;
  color: #ffffff;
  border-radius: 0 5px 5px 0;
  font-size: 16px;
  position: absolute;
  content: '';
  right: 0;
  top: 11px;
}
body .search-modal .search-block form button i:before {
  color: #fff;
  font-size: 35px;
  font-weight: 600;
}
body .search-modal .search-block ::-webkit-input-placeholder {
  /* Chrome/Opera/Safari */
  color: #fff;
  opacity: 1;
}
body .search-modal .search-block ::-moz-placeholder {
  /* Firefox 19+ */
  color: #fff;
  opacity: 0.3;
}
body .search-modal .search-block :-ms-input-placeholder {
  /* IE 10+ */
  color: #fff;
  opacity: 1;
}
body .search-modal .search-block :-moz-placeholder {
  /* Firefox 18- */
  color: #fff;
  opacity: 1;
}
body .search-modal .close {
  position: fixed;
  right: 50px;
  top: 50px;
  background: #ffffff;
  color: #0a0a0a;
  width: 55px;
  height: 55px;
  line-height: 58px;
  text-align: center;
  cursor: pointer;
  border-radius: 50%;
  transition: all .5s ease;
  opacity: unset;
  box-shadow: unset;
  border: none;
}
body .search-modal .close span:before {
  font-size: 14px;
  position: relative;
  top: -2px;
  left: 0px;
  font-weight: 700;
}
body .search-modal .close:hover {
  opacity: 0.8 !important;
}
.full-width-header .rs-header .menu-area .main-menu .rs-menu ul.mega-menu {
  position: absolute;
  left: 0;
  min-width: 1210px;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.015);
  padding: 20px 0;
}
.full-width-header .rs-header.style3 .menu-area .main-menu .rs-menu ul.mega-menu {
  min-width: 1180px;
  left: -31px;
}
/*------------------------------------
    04. Sticky Menu CSS
--------------------------------------*/
.menu-sticky {
  background: #fff;
  margin: 0;
  z-index: 999;
  width: 100%;
  top: 0;
  position: relative;
}
@-webkit-keyframes sticky-animation {
  0% {
    opacity: 0;
    -webkit-transform: translateY(-100%);
  }
  100% {
    opacity: 1;
    -webkit-transform: translateY(0);
  }
}
@keyframes sticky-animation {
  0% {
    opacity: 0;
    transform: translateY(-100%);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}
/* ------------------------------------
    05. About Section CSS
---------------------------------------*/
.rs-about.style1 .about-img {
  position: relative;
}
.rs-about.style1 .about-img .working-experiance {
  position: absolute;
  width: 200px;
  max-width: 200px;
  bottom: 0;
  left: 2px;
  z-index: 1;
  padding: 50px 30px 30px 30px;
  background-color: #BB0B0B;
  border-radius: 0px 0px 0px 15px;
}
.rs-about.style1 .about-img .working-experiance .count-year .sub-text {
  font-size: 73px;
  font-weight: 800;
  color: #ffffff;
}
.rs-about.style1 .about-img .working-experiance .title {
  font-size: 15px;
  font-weight: 700;
  color: #ffffff;
}
.rs-about.style2 {
  position: relative;
}
.rs-about.style2 .rs-animation .animate-style {
  position: absolute;
  right: 21%;
  bottom: 19%;
}
.rs-about.style2 .rs-animation .animate-style img {
  width: 130px;
}
.rs-about.style3 .accordion {
  border-width: 12px;
}
.rs-about.style3 .accordion .card {
  margin-bottom: 3px;
  border: none;
}
.rs-about.style3 .accordion .card:last-child {
  margin-bottom: 0;
}
.rs-about.style3 .accordion .card .card-header {
  padding: 0 !important;
  border: none;
  background: #fff;
  padding: 16px 2px 16px 2px !important;
}
.rs-about.style3 .accordion .card .card-header .card-link {
  color: #1273EB;
  font-weight: 700;
}
.rs-about.style3 .accordion .card .card-body {
  background: #f9f9f9;
  font-weight: 400;
  color: #454545;
  padding: 17px 65px 40px 22px !important;
  border: none;
}
.rs-about.style3 .accordion .card .card-header .card-link {
  position: relative;
  padding-left: 44px !important;
}
.rs-about.style3 .accordion .card .card-header .card-link:after {
  content: "\f078";
  font-family: FontAwesome;
  position: absolute;
  left: 20px;
  color: #0082f1;
  font-size: 15px;
  font-weight: 200;
  top: 50%;
  transform: translateY(-50%);
}
.rs-about.style3 .accordion .card .card-header .card-link.collapsed.card-link {
  color: #0a0a0a !important;
}
.rs-about.style3 .accordion .card .card-header .card-link.collapsed:after {
  content: "\f054";
  font-family: FontAwesome;
  color: #454545;
}
.rs-about.style3 .about-content {
  position: relative;
  z-index: 111;
}
.rs-about.style3 .about-content .rs-animations .spinner {
  position: absolute;
  bottom: 0;
  z-index: -11;
}
.rs-about.style3 .about-content .rs-animations .spinner.dot {
  top: -45px;
  left: -40px;
}
.rs-about.style3 .about-content .rs-animations .spinner.ball {
  bottom: -66px;
  left: -73px;
}
.rs-about.style4 .about-img {
  position: relative;
  z-index: 11;
}
.rs-about.style4 .about-img .animations {
  position: absolute;
  bottom: 0;
  z-index: -11;
}
.rs-about.style4 .about-img .animations.one {
  left: 16%;
  bottom: -77px;
}
.rs-about.style4 .about-img .animations.two {
  left: 26%;
  top: -6%;
}
.rs-about.style5 .left-side {
  position: relative;
}
.rs-about.style5 .left-side img {
  border-radius: 0 50px 0 0;
}
.rs-about.style5 .left-side .skill-tag {
  position: absolute;
  content: '';
  right: -50px;
  top: 50%;
  width: 150px;
  height: 150px;
  transform: translateY(-50%);
  border: 5px solid #ffffff;
  font-size: 16px;
  padding: 46px 20px;
  line-height: 24px;
  border-radius: 100%;
  text-align: center;
  background: #043d72;
  color: #ffffff;
  font-weight: 600;
}
.rs-about.style5 .left-side .skill-tag span {
  font-size: 30px;
  font-weight: 700;
}
.rs-about.style5 .left-side .left-pattern {
  position: absolute;
  content: '';
  left: -170px;
  top: 50%;
  transform: translateY(-50%);
  z-index: -1;
}
.rs-about.style5 .services li {
  font-weight: 500;
  color: #454545;
  margin-bottom: 18px;
  transition: all 0.3s ease;
  display: inline-block;
}
.rs-about.style5 .services li i {
  margin-right: 10px;
}
.rs-about.style5 .services li i:before {
  color: #043d72;
  font-size: 16px;
  font-weight: 700;
}
.rs-about.style5 .services li:last-child {
  margin-bottom: 0;
}
.rs-about.style5 .services li:hover {
  padding-left: 5px;
}
.rs-about.style5 .pattern-img .left-pattern {
  position: absolute;
  content: '';
  top: 20px;
  right: 0;
  z-index: -1;
}
/* ------------------------------------
    06. Appointment Section CSS
---------------------------------------*/
.rs-appointment.style1 .border-section {
  border-style: solid;
  border-width: 2px 0px 0px 0px;
  border-color: #02010108;
  padding: 50px 0px 100px 0px;
}
.rs-appointment.style1 .appoint-schedule .appoint-img {
  background: /*url(assets/images/appointment/1.jpg)*/;
  background-repeat: no-repeat;
  background-position: center left;
  background-size: cover;
}
.rs-appointment.style1 .appoint-schedule .contact-wrap {
  padding: 110px 100px 100px 70px;
  background: #13171c;
}
.rs-appointment.style1 .appoint-schedule .contact-wrap .from-control {
  padding: 17px 17px 17px 17px;
  color: #FFFFFF;
  border-style: solid;
  border-width: 1px 1px 1px 1px;
  border-color: #0D0D0D;
  background-color: #0D0D0D;
  width: 100%;
  max-width: 100%;
  opacity: 1;
}
.rs-appointment.style1 .appoint-schedule .contact-wrap .wpcf7-form-control:not(.wpcf7-submit) {
  width: 100%;
  padding: 17px 17px 17px 17px;
  color: #FFFFFF;
  border-style: solid;
  border-width: 1px 1px 1px 1px;
  border-color: #0D0D0D;
  background-color: #0d0d0d;
}
.rs-appointment.style1 .appoint-schedule .contact-wrap textarea {
  height: 120px;
}
.rs-appointment.style1 .appoint-schedule .contact-wrap ::-webkit-input-placeholder {
  /* Chrome/Opera/Safari */
  color: #fff;
  opacity: 1;
}
.rs-appointment.style1 .appoint-schedule .contact-wrap ::-moz-placeholder {
  /* Firefox 19+ */
  color: #fff;
  opacity: 1;
}
.rs-appointment.style1 .appoint-schedule .contact-wrap :-ms-input-placeholder {
  /* IE 10+ */
  color: #fff;
  opacity: 1;
}
.rs-appointment.style1 .appoint-schedule .contact-wrap :-moz-placeholder {
  /* Firefox 18- */
  color: #fff;
  opacity: 1;
}
.rs-appointment.style1 .appoint-schedule .contact-wrap input[type=submit]:hover {
  opacity: .9;
}
.rs-appointment.style1.apply-career .appoint-schedule .appoint-img {
  background: url(assets/images/careers/2.jpg);
  background-repeat: no-repeat;
  background-position: center left;
  background-size: cover;
}
.rs-appointment.style1.apply-career .appoint-schedule .contact-wrap {
  padding: 100px 100px 100px 70px;
  background: #13171c;
}
.rs-appointment.style1.apply-career .appoint-schedule .contact-wrap .form-group label {
  color: #ffffff;
  font-weight: 600;
  display: block;
  margin-bottom: 8px;
}
.rs-appointment.style1.apply-career .appoint-schedule .contact-wrap .form-group .form-control-mod {
  width: 100%;
  padding: 17px 17px 17px 17px;
  color: #FFFFFF;
  border-style: solid;
  border-width: 1px 1px 1px 1px;
  border-color: #0D0D0D;
  background-color: #0D0D0D;
}
/* ------------------------------------
    07. Breadcrumbs Section CSS
---------------------------------------*/
.rs-breadcrumbs {
  background-repeat: no-repeat !important;
  background-size: cover !important;
  background-position: center center !important;
}
.rs-breadcrumbs .breadcrumbs-inner {
  padding: 310px 0 180px;
}
.rs-breadcrumbs .breadcrumbs-inner .page-title {
  font-size: 48px;
  line-height: 56px;
  font-weight: 700;
  margin-bottom: 10px;
  color: #ffffff;
  position: relative;
  z-index: 1;
  padding-top: 20px;
}
.rs-breadcrumbs .breadcrumbs-inner .page-title:after {
  content: "";
  height: 5px;
  position: absolute;
  top: 0px;
  left: 0;
  -webkit-transition: all .3s ease;
  transition: all .3s ease;
  z-index: -1;
  background: #bb0b0b;
  width: 65px;
  margin: 0 auto;
}
.rs-breadcrumbs .breadcrumbs-inner .page-title .watermark {
  position: absolute;
  font-size: 200px;
  text-transform: uppercase;
  font-weight: 900;
  font-family: 'Inter', sans-serif;
  opacity: .05;
  width: 100%;
  left: 0;
  color: inherit;
  white-space: nowrap;
  vertical-align: middle;
}
.rs-breadcrumbs .breadcrumbs-inner .sub-text {
  font-size: 18px;
  line-height: 30px;
  font-weight: 500;
  color: #fff;
  text-transform: capitalize;
  display: inline-block;
  margin-top: 10px;
  padding-right: 290px;
}
.rs-breadcrumbs .breadcrumbs-inner ul {
  padding: 0;
}
.rs-breadcrumbs .breadcrumbs-inner ul li {
  color: #ffffff;
  display: inline-block;
  font-size: 14px;
  font-weight: 400;
}
.rs-breadcrumbs .breadcrumbs-inner ul li a {
  position: relative;
  padding-right: 22px;
  -webkit-transition: 0.3s;
  transition: 0.3s;
  color: #ffffff;
}
.rs-breadcrumbs .breadcrumbs-inner ul li a:before {
  background-color: #fff;
  content: "";
  height: 15px;
  width: 1px;
  position: absolute;
  right: 9px;
  top: 2px;
  transform: rotate(26deg);
}
.rs-breadcrumbs .breadcrumbs-inner ul li a:hover {
  opacity: .7;
}
.rs-breadcrumbs.img1 {
  background: url(assets/images/breadcrumbs/inr_1.jpg);
}
.rs-breadcrumbs.img2 {
  background: url(assets/images/breadcrumbs/inr_2.jpg);
}
.rs-breadcrumbs.img3 {
  background: url(assets/images/breadcrumbs/inr_3.jpg);
}
.rs-breadcrumbs.img4 {
  background: url(assets/images/breadcrumbs/inr_4.jpg);
}
.rs-breadcrumbs.img5 {
  background: url(assets/images/breadcrumbs/inr_5.jpg);
}
.rs-breadcrumbs.img6 {
  background: url(assets/images/breadcrumbs/inr_6.jpg);
}
.rs-breadcrumbs.img7 {
  background: url(assets/images/breadcrumbs/inr_7.jpg);
}
.rs-breadcrumbs.img8 {
  background: url(assets/images/breadcrumbs/inr_8.jpg);
}
.rs-breadcrumbs.img9 {
  background: url(assets/images/breadcrumbs/inr_9.jpg);
}
.rs-breadcrumbs.img10 {
  background: url(assets/images/breadcrumbs/inr_10.jpg);
}
/* ------------------------------------
    08. Banner Section CSS
---------------------------------------*/
.rs-banner.main-home {
  background: url(assets/images/banner/style1/main-banner.png);
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  position: relative;
  z-index: 111;
}
.rs-banner.main-home .content-wrap {
  padding: 350px 0 298px;
  position: relative;
  z-index: 10;
}
.rs-banner.main-home .content-wrap .border-line {
  background-color: #bb0b0b;
  color: #ffffff;
  width: 70px;
  height: 8px;
  margin-bottom: 28px;
}
.rs-banner.main-home .content-wrap .title {
  font-size: 84px;
  line-height: 84px;
  font-weight: 800;
  color: #ffffff;
  margin: 0px 0px 14px 0px;
}
.rs-banner.main-home .content-wrap .sub-title {
  font-size: 53px;
  line-height: 63px;
  font-weight: 700;
  color: #ffffff;
  margin: 0px 0px 47px 0px;
}
.rs-banner.main-home .animate-part .spiner {
  position: absolute;
  bottom: 0;
}
.rs-banner.main-home .animate-part .spiner.dot {
  right: 46%;
  top: 30%;
}
.rs-banner.main-home .animate-part .spiner.dot img {
  width: 70px;
}
.rs-banner.main-home .animate-part .spiner.tri-circle {
  left: 33%;
  bottom: 9%;
}
.rs-banner.main-home .animate-part .spiner.tri-circle img {
  width: 150px;
}
.rs-banner.main-home .animate-part .spiner.circle {
  right: 35%;
  bottom: 27%;
}
.rs-banner.main-home .animate-part .spiner.circle img {
  width: 85px;
}
.rs-banner.main-home .animate-part .spiner.line {
  bottom: -40px;
  right: -10px;
}
.rs-banner.main-home .animate-part .spiner.line img {
  width: 1030px;
}
.rs-banner.banner-home-style2 {
  background: url(assets/images/banner/banner2.jpg);
  background-size: cover;
  background-repeat: no-repeat;
  background-position: top center;
}
.rs-banner.banner-home-style2 .content-wrap {
  padding: 325px 0 230px;
}
.rs-banner.banner-home-style2 .content-wrap .sub-text {
  font-size: 18px;
  line-height: 28px;
  font-weight: 500;
  color: #4caf50;
  text-transform: capitalize;
  display: block;
  margin-bottom: 18px;
}
.rs-banner.banner-home-style2 .content-wrap .title {
  font-size: 52px;
  font-weight: 700;
  line-height: 1.4em;
  color: #ffffff;
  margin-bottom: 22px;
}
.rs-banner.banner-home-style2 .content-wrap .description {
  font-size: 18px;
  font-weight: 500;
  color: #ffffff;
}
.rs-banner.banner-home-style2 .rs-contact .contact-wrap {
  background: #ffffff;
  padding: 22px 40px 45px;
  border-radius: 4px 4px 4px 4px;
}
.rs-banner.banner-home-style2 .rs-contact .contact-wrap .content-part {
  text-align: center;
}
.rs-banner.banner-home-style2 .rs-contact .contact-wrap .content-part .title {
  font-size: 30px;
  font-weight: 500;
  line-height: 1.4em;
  color: #0A0A0A;
  margin: 10px 0px 4px 0px;
}
.rs-banner.banner-home-style2 .rs-contact .contact-wrap .content-part .description {
  font-size: 16px;
  font-weight: 400;
  color: #333333;
}
.rs-banner.banner-home-style2 .rs-contact .contact-wrap .from-control {
  padding: 12px 25px 12px 25px;
  border-radius: 5px 5px 5px 5px;
  border-style: solid;
  border-color: #02010100;
  background-color: #F7F7F7;
  color: #454545;
  width: 100%;
  opacity: 4;
}
.rs-banner.banner-home-style2 .rs-contact .contact-wrap textarea {
  height: 120px;
}
.rs-banner.banner-home-style2 .rs-contact .contact-wrap ::-webkit-input-placeholder {
  /* Chrome/Opera/Safari */
  color: #454545;
  opacity: 0.4;
}
.rs-banner.banner-home-style2 .rs-contact .contact-wrap ::-moz-placeholder {
  /* Firefox 19+ */
  color: #454545;
  opacity: 0.4;
}
.rs-banner.banner-home-style2 .rs-contact .contact-wrap :-ms-input-placeholder {
  /* IE 10+ */
  color: #454545;
  opacity: 0.4;
}
.rs-banner.banner-home-style2 .rs-contact .contact-wrap :-moz-placeholder {
  /* Firefox 18- */
  color: #454545;
  opacity: 0.4;
}
.rs-banner.banner-style6 {
  background: url(assets/images/banner/home_banner6.jpg);
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  padding: 250px 0;
}
.rs-banner.banner-style6 .title {
  font-weight: 800;
  font-size: 110px;
  line-height: 1;
  margin-left: -5px;
}
.rs-banner.banner-style6 .btn-area {
  padding-top: 15px;
  display: flex;
  align-items: center;
}
.rs-banner.banner-style6 .bnr-play-btn {
  color: #222;
  transition: all 0.3s ease !important;
  margin-left: 20px;
  position: relative;
  top: 4px;
}
.rs-banner.banner-style6 .bnr-play-btn .border-wrap {
  position: relative;
  margin-right: 20px;
  width: 50px;
  height: 50px;
  line-height: 50px !important;
  text-align: center !important;
  border-radius: 100%;
  display: inline-block;
  color: #ffffff;
  background: #e94d65;
}
.rs-banner.banner-style6 .bnr-play-btn .border-wrap i:before {
  font-size: 15px;
  padding-left: 3px;
}
.rs-banner.banner-style6 .bnr-play-btn .border-wrap:before,
.rs-banner.banner-style6 .bnr-play-btn .border-wrap:after {
  content: "";
  position: absolute;
  z-index: -4;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  display: block;
  background: transparent;
  border: 1px solid #e94d65;
  border-radius: 50%;
  animation: pulse-border 1300ms ease-out infinite;
  transition: all 0.3s ease;
  z-index: 9;
}
.rs-banner.banner-style6 .bnr-play-btn .border-wrap:after {
  width: 47px;
  height: 47px;
}
.rs-banner.banner-style6 .bnr-play-btn .border-wrap:before {
  width: 55px;
  height: 55px;
}
.rs-banner.banner-style6 .bnr-play-btn:hover {
  color: #043d72;
}
/*---------------------------------------
    09. Slider Section CSS
---------------------------------------*/
.rs-slider {
  position: relative;
  overflow: hidden;
  text-align: left;
}
.rs-slider.slider3 {
  position: relative;
  /*-- Animations Start --*/
  /*-- Animations End --*/
}
.rs-slider.slider3 .desc {
  font-size: 17px;
  color: #0a0a0a;
  max-width: 500px;
  margin: 25px 0 45px;
}
.rs-slider.slider3 .slider-bottom {
  display: block;
  overflow: hidden;
}
.rs-slider.slider3 .slider-bottom .consultant {
  display: inline-block !important;
}
.rs-slider.slider3 .sl-subtitle {
  -webkit-animation: fadeInDown 1.7s ease-in-out;
  animation: fadeInDown 1.7s ease-in-out;
}
.rs-slider.slider3 .sl-title {
  -webkit-animation: fadeInUp 1.2s ease-in-out;
  animation: fadeInUp 1.2s ease-in-out;
}
.rs-slider.slider3 .slider-bottom {
  -webkit-animation: fadeInUp 1.8s ease-in-out;
  animation: fadeInUp 1.8s ease-in-out;
}
.rs-slider.slider3 .content-part {
  position: absolute;
  content: '';
  bottom:0%;
  left: 50%;
  transform:translate(-50%, 0%);
  width: 100%; background: rgb(0 0 0 / 40%); padding:3% 5% 3%; backdrop-filter: blur(1px);
}
.rs-slider.slider3 .content-part .sl-subtitle {
  font-size: 16px;
  font-weight: 500;
  /*text-transform: uppercase;*/
  margin-bottom: 12px;
  color: #bb0b0b;
}
.rs-slider.slider3 .content-part .sl-title {
  font-size: 80px;
  line-height: 1.1;
  font-weight: 700;
  margin-bottom: 36px;
}
.rs-slider.slider3 .content-part .slider-bottom {
  display: inline-block;
}
.rs-slider.slider3 .content-part .slider-bottom .readon {
  display: block !important;
  font-size: 15px;
  font-weight: 500;
  padding: 15px 32px;
}
.rs-slider.slider3 .content-part .slider-bottom ul li {
  display: inline-block;
  margin-right: 15px;
}
.rs-slider.slider3 .content-part .slider-bottom ul li:last-child {
  margin: 0;
}
.rs-slider.slider3 .nivo-caption {
  padding: 0;
}
.rs-slider.slider3 .nivo-directionNav > a {
  background: rgba(0, 0, 0, 0.2);
}
.rs-slider.slider3 .nivo-directionNav > a:before {
  color: #ffffff;
}
.rs-slider .sl-wrap {
  padding: 50px 0;
}
.rs-slider .nivo-directionNav > a {
  font-size: 0;
  width: 60px;
  height: 60px;
  line-height: 60px;
  text-align: center;
  border: none;
  background: #ebebeb;
  -webkit-transition: all 0.4s ease-out;
  transition: all 0.4s ease-out;
  opacity: 1;
}
.rs-slider .nivo-directionNav > a.nivo-prevNav,
.rs-slider .nivo-directionNav > a.nivo-nextNav {
  left: 5px;
  background: #bb0b0b;
}
.rs-slider .nivo-directionNav > a.nivo-prevNav {
  top: 44.4%;
}
.rs-slider .nivo-directionNav > a.nivo-prevNav:before {
  content: "\f104";
}
.rs-slider .nivo-directionNav > a.nivo-nextNav {
  top: 53%;
}
.rs-slider .nivo-directionNav > a.nivo-nextNav:before {
  content: "\f105";
}
.rs-slider .nivo-directionNav > a:before {
  font-family: FontAwesome;
  position: absolute;
  font-size: 28px;
  top: 50%;
  left: 50%;
  -webkit-transform: translateY(-50%) translateX(-50%);
  transform: translateY(-50%) translateX(-50%);
  transition: all 0.3s ease;
  color: #bb0b0b;
}
.rs-slider .nivo-directionNav > a:hover {
  opacity: 95;
}
.rs-slider .nivo-controlNav {
  position: absolute;
  bottom: 50px;
  z-index: 11;
  left: 50%;
  -webkit-transform: translateX(-50%);
  transform: translateX(-50%);
  display: none !important;
}
.rs-slider .nivo-controlNav .nivo-control {
  width: 26px;
  height: 7px;
  border: 1px solid #fff;
  font-size: 0px;
  display: inline-block;
  margin-right: 7px;
  -webkit-transition: .4s ease all;
  transition: .4s ease all;
}
.rs-slider .nivo-controlNav .nivo-control.active {
  background: #bb0b0b;
  border-color: #bb0b0b;
}
.rs-slider.rs-slider-style3.slider3 .content-part .sl-subtitle {
  font-size: 18px;
  font-weight: 500;
  text-transform: uppercase;
  margin-bottom: 15px;
  color: #0082f1;
}
.rs-slider.rs-slider-style3.slider3 .content-part .sl-title {
  font-size: 55px;
  line-height: 1.2;
  font-weight: 700;
  margin-bottom: 36px;
}
.rs-slider.rs-slider-style3 .nivo-directionNav > a {
  border-radius: 50%;
}
.rs-slider.rs-slider-style3 .nivo-directionNav > a.nivo-prevNav,
.rs-slider.rs-slider-style3 .nivo-directionNav > a.nivo-nextNav {
  left: 30px;
  background: #0082f1;
}
.rs-slider.rs-slider-style3 .nivo-directionNav > a.nivo-prevNav {
  top: 50%;
  transform: translateY(-50%);
}
.rs-slider.rs-slider-style3 .nivo-directionNav > a.nivo-prevNav:before {
  content: "\f104";
  color: #ffffff;
}
.rs-slider.rs-slider-style3 .nivo-directionNav > a.nivo-nextNav {
  top: 50%;
  transform: translateY(-50%);
  right: 30px;
  left: unset;
}
.rs-slider.rs-slider-style3 .nivo-directionNav > a.nivo-nextNav:before {
  content: "\f105";
  color: #ffffff;
}
.rs-slider.rs-slider-style3 .nivo-directionNav > a:before {
  color: #0082f1;
}
.rs-slider.rs-slider-style4 {
  position: relative;
  z-index: 2;
}
.rs-slider.rs-slider-style4.slider3 .content-part .sl-subtitle {
    font-size: 16px;
    font-weight: 500;
    color: #ffffff;
    margin-bottom: 6px;
    width: 35%;
}
.rs-slider.rs-slider-style4.slider3 .content-part .sl-title {
  font-size:40px;
  line-height: 1.3;
  font-weight: 700;
  color: #ffffff;
  margin-bottom: 0px; width: 75%; text-shadow: rgba(0, 0, 0, 0.25) 0px 54px 55px, rgba(0, 0, 0, 0.12) 0px -12px 30px, rgba(0, 0, 0, 0.12) 0px 4px 6px, rgba(0, 0, 0, 0.17) 0px 12px 13px, rgba(0, 0, 0, 0.09) 0px -3px 5px;
}
.rs-slider.rs-slider-style4.slider3 .content-part .slider-bottom {
  display: inline-block;
  overflow: unset;
  position: relative;
  left: -4%;
}
.rs-slider.rs-slider-style4.slider3 .content-part .slider-bottom .readon {
  display: block !important;
  font-size: 16px;
  font-weight: 600;
  padding: 14px 32px 14px; border-radius: 6px; 
}
.rs-slider.rs-slider-style4.slider3 .content-part .slider-bottom li .rs-videos .animate-border .popup-border {
  top: -5px;
  left: unset;
  right: -136px;
  z-index: 111;
  transform: unset;
}
.rs-slider.rs-slider-style4 .nivo-directionNav > a {
  border-radius: 50%;
}
.rs-slider.rs-slider-style4 .nivo-directionNav > a.nivo-prevNav,
.rs-slider.rs-slider-style4 .nivo-directionNav > a.nivo-nextNav {
  left: 30px;
  background: rgb(255 255 255 / 10%);
}
.rs-slider.rs-slider-style4 .nivo-directionNav > a.nivo-prevNav {
  top: 50%;
  transform: translateY(-50%);
}
.rs-slider.rs-slider-style4 .nivo-directionNav > a.nivo-prevNav:before {
  content: "\f104";
  color: #ffffff;
}
.rs-slider.rs-slider-style4 .nivo-directionNav > a.nivo-nextNav {
  top: 50%;
  transform: translateY(-50%);
  right: 30px;
  left: unset;
}
.rs-slider.rs-slider-style4 .nivo-directionNav > a.nivo-nextNav:before {
  content: "\f105";
  color: #ffffff;
}
.rs-slider.rs-slider-style4 .nivo-directionNav > a:before {
  color: #fd4a36;
}
.rs-slider.style4 .owl-nav .owl-next,
.rs-slider.style4 .owl-nav .owl-prev {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: 10px;
  font-size: 25px;
  text-align: center;
  color: #ffffff;
}
.rs-slider.style4 .owl-nav .owl-next i:before,
.rs-slider.style4 .owl-nav .owl-prev i:before {
  content: "\f112";
  font-family: Flaticon;
}
.rs-slider.style4 .owl-nav .owl-next {
  right: 10px;
  left: unset;
}
.rs-slider.style4 .owl-nav .owl-next i:before {
  content: "\f113";
}
/* ------------------------------------
    10. Video Section CSS
---------------------------------------*/
.rs-videos .animate-border .popup-border {
  background: #037cd1;
  width: 80px;
  height: 80px;
  line-height: 80px;
  text-align: center;
  display: inline-block;
  border-radius: 50%;
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
}
.rs-videos .animate-border .popup-border i {
  font-size: 20px;
  line-height: 80px;
  display: block;
  color: #ffffff;
}
.rs-videos .animate-border .popup-border:before {
  content: "";
  border: 2px solid #037cd1;
  position: absolute;
  z-index: 0;
  left: 50%;
  top: 50%;
  transform: translateX(-50%) translateY(-50%);
  display: block;
  width: 180px;
  height: 180px;
  border-radius: 50%;
  animation: zoomBig 3.25s linear infinite;
  -webkit-animation-delay: .75s;
  animation-delay: .75s;
}
.rs-videos .animate-border .popup-border:after {
  content: "";
  border: 2px solid #037cd1;
  position: absolute;
  z-index: 0;
  left: 50%;
  top: 50%;
  transform: translateX(-50%) translateY(-50%);
  display: block;
  width: 180px;
  height: 180px;
  border-radius: 50%;
  animation: zoomBig 3.25s linear infinite;
  -webkit-animation-delay: 0s;
  animation-delay: 0s;
}
.rs-videos .animate-border.blue-color-style .popup-border:after,
.rs-videos .animate-border.blue-color-style .popup-border:before {
  border: 2px solid #0082f1;
}
.rs-videos .animate-border.blue-color-style .popup-border {
  background: #0082f1;
}
.rs-videos .animate-border.orange-color-style .popup-border:after,
.rs-videos .animate-border.orange-color-style .popup-border:before {
  border: 2px solid #ffffff;
}
.rs-videos .animate-border.orange-color-style .popup-border i {
  color: #fd4a36;
}
.rs-videos .animate-border.orange-color-style .popup-border {
  background: #ffffff;
}
.rs-videos.choose-video {
  position: relative;
}
.rs-videos.choose-video .animate-border .popup-border {
  left: 50%;
  top:40%;
  transform: translate(-50%, -50%);
}
.rs-videos.choose-video.careers-video {
  position: relative;
}
.rs-videos.choose-video.careers-video .images-video {
  background: url(assets/images/careers/1.jpg);
  background-position: top center;
  background-repeat: no-repeat;
  background-size: cover;
  padding: 250px 100px 300px;
}
.rs-videos.choose-video.careers-video .animate-border .popup-border {
  left: 45%;
  top: 32%;
  transform: unset;
}
.rs-videos.choose-video.careers-video .animate-border .video-desc {
  font-size: 20px;
  font-weight: 600;
  color: #ffffff;
  left: 40%;
  top: 60%;
  position: absolute;
}
.rs-videos.address-item .address-icon {
  display: inline-block;
  position: relative;
  height: 70px;
  width: 70px;
  line-height: 70px;
  border-radius: 100%;
  text-align: center;
  background: #bb0b0b;
}
.rs-videos.address-item .address-icon i {
  text-align: center;
  color: #ffffff;
  border-radius: 100%;
  font-size: 25px;
  position: relative;
  line-height: 70px;
  z-index: 9;
  width: 100%;
  vertical-align: middle;
  margin-left: 0;
  height: auto;
}
.rs-videos.address-item .address-icon:before {
  content: "";
  position: absolute;
  z-index: 0;
  left: 50%;
  top: 50%;
  transform: translateX(-50%) translateY(-50%);
  display: block;
  width: 70px;
  height: 70px;
  border-radius: 50%;
  animation: pulse-border 1500ms ease-out infinite;
  background: #bb0b0b;
}
.rs-videos.faq {
  position: relative;
}
.rs-videos.faq .images-video {
  background: url(assets/images/video/faq.jpg);
  background-repeat: no-repeat;
  background-size: cover;
  padding: 280px 0px 280px 0px;
}
.rs-videos.faq .animate-border .popup-border {
  background: #4caf50;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}
.rs-videos.faq .animate-border .popup-border:after,
.rs-videos.faq .animate-border .popup-border:before {
  border: 2px solid #4CAF50;
}
@keyframes zoomBig {
  0% {
    transform: translate(-50%, -50%) scale(0.5);
    opacity: 1;
    border-width: 3px;
  }
  40% {
    opacity: .5;
    border-width: 2px;
  }
  65% {
    border-width: 1px;
  }
  100% {
    transform: translate(-50%, -50%) scale(1);
    opacity: 0;
    border-width: 1px;
  }
}
@keyframes zoomBig {
  0% {
    transform: translate(-50%, -50%) scale(0.5);
    opacity: 1;
    border-width: 3px;
  }
  40% {
    opacity: .5;
    border-width: 2px;
  }
  65% {
    border-width: 1px;
  }
  100% {
    transform: translate(-50%, -50%) scale(1);
    opacity: 0;
    border-width: 1px;
  }
}
/* ------------------------------------
    11. Iconbox Area Section CSS
---------------------------------------*/
.rs-iconbox-area .box-inner .box-item {
  background: #ffffff;
  padding: 50px 12px 40px 12px;
  box-shadow: 5px 8px 20px 6px #eee;
  text-align: center;
  margin: 0 3px 0 3px;
}
.rs-iconbox-area .box-inner .box-item .icon-box {
  margin-bottom: 30px;
}
.rs-iconbox-area .box-inner .box-item .text-area .services-txt {
  margin: 0;
}
/* ------------------------------------
    12. Skill-bar Section CSS
---------------------------------------*/
.rs-skillbar.style1 .cl-skill-bar .skillbar-title {
  font-size: 13px;
  line-height: 6px;
  font-weight: 600;
  color: #15161a;
}
.rs-skillbar.style1 .cl-skill-bar .skillbar {
  height: 7px;
  line-height: 7px;
  background-color: #EBEBEB;
  margin: 10px 0 24px;
  position: relative;
  width: 100%;
}
.rs-skillbar.style1 .cl-skill-bar .skillbar .skillbar-bar {
  height: 7px;
  line-height: 7px;
  background-image: linear-gradient(180deg, #bb0b0b 0%, #9d0c0c 100%);
  margin-bottom: 0;
  overflow: visible !important;
  background-size: 1rem 1rem;
  width: 0;
}
.rs-skillbar.style1 .cl-skill-bar .skillbar .skill-bar-percent {
  height: 30px;
  line-height: 30px;
  position: absolute;
  right: 0;
  top: -38px;
  font-size: 13px;
  font-weight: 500;
  color: #0a0a0a;
}
.rs-skillbar.style1 .cl-skill-bar .skillbar.margin-no {
  margin: 10px 0 0;
}
.rs-skillbar.style1.team-single .cl-skill-bar .skillbar-title {
  font-size: 16px;
  font-weight: 400;
  color: #454545;
}
.rs-skillbar.style1.team-single .cl-skill-bar .skillbar {
  background: #fff;
  border: 1px solid #bb0b0b;
  height: 9px;
  border-radius: 0;
  overflow: visible;
  padding: 1px;
  margin: 10px 0 20px;
}
.rs-skillbar.style1.team-single .cl-skill-bar .skillbar .skillbar-bar {
  background: #bb0b0b;
  height: 5px;
}
.rs-skillbar.style1.team-single .cl-skill-bar .skillbar .skill-bar-percent {
  font-size: 16px;
  right: 70px;
  color: #454545;
  font-weight: 400;
}
.rs-skillbar.style1.home3-skillbar-style .cl-skill-bar .skillbar .skillbar-bar {
  background-image: linear-gradient(180deg, #0082f1 0%, #0082f1 100%);
}
.rs-skillbar.style1.home4-skillbar-style .cl-skill-bar .skillbar .skillbar-bar {
  background-color: #FD4A36;
  background-image: linear-gradient(45deg, rgba(255, 255, 255, 0.15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.15) 50%, rgba(255, 255, 255, 0.15) 75%, transparent 75%, transparent);
  background-size: 1rem 1rem;
}
/* ------------------------------------
    13. Services Section CSS
---------------------------------------*/
.rs-services.main-home .services-item {
  display: flex;
}
.rs-services.main-home .services-item .services-icon img {
  height: 70px;
  width: 70px;
  max-width: unset;
}
.rs-services.main-home .services-item .services-text {
  padding: 0px 0px 0px 30px;
}
.rs-services.main-home .services-item .services-text .title {
  font-size: 20px;
  font-weight: 700;
  line-height: 30px;
  margin-bottom: 13px;
}
.rs-services.main-home .services-item .services-text .title a {
  color: #ffffff;
}
.rs-services.main-home .services-item .services-text .title a:hover {
  color: #bb0b0b;
}
.rs-services.main-home .services-item .services-text .services-txt {
  color: #F5F5F5;
  margin: 0;
}
.rs-services.main-home.services-style1 .services-item {
  position: relative;
}
.rs-services.main-home.services-style1 .services-item .services-text .title a {
  color: #0a0a0a;
}
.rs-services.main-home.services-style1 .services-item .services-text .title a:hover {
  color: #bb0b0b;
}
.rs-services.main-home.services-style1 .services-item .services-text .services-txt {
  color: #454545;
  margin-bottom: 26px;
}
.rs-services.main-home.services-style1 .services-item .services-text .serial-number {
  position: absolute;
  left: -6px;
  top: 44px;
  color: #00000008;
  font-size: 70px;
  font-weight: 600;
}
.rs-services.main-home.services-style1 .services-item:after {
  display: block;
  width: 47px;
  left: 124px;
  background: #C12A2A;
  content: "";
  height: 4px;
  position: absolute;
  transition: all .3s ease 0s;
  bottom: 0;
  z-index: 9;
  transform: translateX(-50%);
}
.rs-services.main-home.services-style1.home-4-style .services-item .services-text .title a:hover {
  color: #fd4a36;
}
.rs-services.main-home.services-style1.home-4-style .services-item:after {
  background: #fd4a36;
}
.rs-services.chooseus-style .services-item {
  display: flex;
  padding: 25px 25px 25px 25px;
  background-color: #15181D;
  margin: 0 -4px 0 0;
}
.rs-services.chooseus-style .services-item .services-icon img {
  height: 65px;
  width: 65px;
  max-width: unset;
  padding: 5px 5px 0px 0px;
}
.rs-services.chooseus-style .services-item .services-text {
  padding: 0px 0px 0px 15px;
}
.rs-services.chooseus-style .services-item .services-text .title {
  font-size: 20px;
  font-weight: 700;
  line-height: 30px;
  margin: 0 0 10px;
}
.rs-services.chooseus-style .services-item .services-text .title a {
  color: #ffffff;
}
.rs-services.chooseus-style .services-item .services-text .title a:hover {
  color: #bb0b0b;
}
.rs-services.chooseus-style .services-item .services-text .services-txt {
  margin: 0;
  color: #F4F4F4;
}
.rs-services.chooseus-style.about-style .services-item {
  padding: unset;
  background-color: unset;
  margin: unset;
}
.rs-services.home-style2 .services-item {
  display: flex;
}
.rs-services.home-style2 .services-item .services-icon img {
  height: 70px;
  width: 70px;
  max-width: unset;
}
.rs-services.home-style2 .services-item .services-text {
  padding: 0px 0px 0px 25px;
}
.rs-services.home-style2 .services-item .services-text .title {
  font-size: 20px;
  font-weight: 700;
  line-height: 30px;
  margin: 0 0 6px;
}
.rs-services.home-style2 .services-item .services-text .title a {
  color: #0a0a0a;
}
.rs-services.home-style2 .services-item .services-text .title a:hover {
  color: #bb0b0b;
}
.rs-services.home-style2 .services-item .services-text .services-txt {
  margin: 0;
  color: #454545;
}
.rs-services.style2 .service-wrap {
  padding: 15px 15px 30px;
  background: #ffffff;
  box-shadow: 0px 14px 30px rgba(0, 0, 0, 0.07);
}
.rs-services.style2 .service-wrap .image-part {
  overflow: hidden;
  margin-bottom: 20px;
}
.rs-services.style2 .service-wrap .image-part img {
  transform: scale(1);
  transition: all 0.9s;
}
.rs-services.style2 .service-wrap .content-part {
  padding: 10px 20px 5px;
}
.rs-services.style2 .service-wrap .content-part .title {
  font-size: 20px;
  line-height: 32px;
  margin-bottom: 10px;
}
.rs-services.style2 .service-wrap .content-part .title a {
  color: #0a0a0a;
}
.rs-services.style2 .service-wrap .content-part .title a:hover {
  color: #bb0b0b;
}
.rs-services.style2 .service-wrap:hover .image-part img {
  transform: scale(1.1);
}
.rs-services.style2.rs-services-style2 .service-wrap {
  box-shadow: unset;
}
.rs-services.style2.rs-services-style2 .service-wrap .image-part img {
  transform: unset;
}
.rs-services.style2.rs-services-style2 .service-wrap:hover .image-part img {
  transform: unset;
}
.rs-services.style3 .services-item {
  position: relative;
  transition: all 500ms ease;
}
.rs-services.style3 .services-item .services-icon {
  margin-bottom: 35px;
}
.rs-services.style3 .services-item .services-icon img {
  width: 80px;
  height: 80px;
  max-width: unset;
}
.rs-services.style3 .services-item .services-content .services-title {
  font-size: 22px;
  line-height: 32px;
  font-weight: 700;
  margin-bottom: 17px;
}
.rs-services.style3 .services-item .services-content .services-title a {
  color: #0a0a0a;
}
.rs-services.style3 .services-item .services-content .services-title a:hover {
  color: #bb0b0b;
}
.rs-services.style3 .services-item .services-content .services-txt {
  margin: 0;
}
.rs-services.style3 .services-item:hover {
  transform: translateY(-10px);
}
.rs-services.style4 .services-item {
  background: #ffffff;
  box-shadow: rgb(9 30 66 / 4%) 0px 4px 8px -2px, rgb(9 30 66 / 4%) 0px 0px 0px 1px;
  padding: 40px 30px 40px 30px;
  border-radius: 5px 5px 5px 5px;
  margin: 0px -7px 0 0;
}
.rs-services.style4 .services-item .services-icon {
  margin-bottom: 17px;
}
.rs-services.style4 .services-item .services-icon img {
  width: 54px;
  padding: 8px 0px 0px 0px;
}
.rs-services.style4 .services-item .services-content .title {
  font-size: 18px;
  line-height: 1.5;
  font-weight: 700;
  margin-bottom: 10px;
}
.rs-services.style4 .services-item .services-content .title a {
  color: #0a0a0a;
}
.rs-services.style4 .services-item .services-content .title a:hover {
  color: #0082f1;
}
.rs-services.style4 .services-item .services-content p {
  font-size: 15px;
}
.rs-services.style5 .flip-box-inner {
  position: relative;
  z-index: 1;
  margin: 0;
  backface-visibility: hidden;
}
.rs-services.style5 .flip-box-inner .flip-box-wrap {
  position: relative;
  -webkit-transform: translateZ(0);
  perspective: 1e3px;
}
.rs-services.style5 .flip-box-inner .flip-box-wrap .front-part {
  position: relative;
  bottom: 0;
  z-index: 10;
  transform: rotateY(0);
  top: 0;
  right: 0;
  left: 0;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
  align-items: center;
  -ms-flex-align: center;
  height: 300px;
  background-color: initial;
  background-position: 50%;
  background-clip: padding-box;
  background-size: cover;
  background-repeat: no-repeat;
  text-align: center;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  -webkit-perspective: 1e3px;
  perspective: 1e3px;
  -webkit-transition: -webkit-transform 0.6s cubic-bezier(0.2, 0.85, 0.4, 1.275);
  transition: -webkit-transform 0.6s cubic-bezier(0.2, 0.85, 0.4, 1.275);
  transition: transform 0.6s cubic-bezier(0.2, 0.85, 0.4, 1.275);
  transition: transform 0.6s cubic-bezier(0.2, 0.85, 0.4, 1.275), -webkit-transform 0.6s cubic-bezier(0.2, 0.85, 0.4, 1.275);
}
.rs-services.style5 .flip-box-inner .flip-box-wrap .front-part .front-content-part {
  background-color: #fff;
  text-align: left;
  padding: 50px 40px 50px 40px;
  border-style: solid;
  border-width: 0px 0px 0px 0px;
  box-shadow: 0px 0px 10px 0px #eee;
  position: relative;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  width: 100%;
  transition: all .9s ease 0s;
}
.rs-services.style5 .flip-box-inner .flip-box-wrap .front-part .front-content-part .front-icon-part {
  margin-bottom: 15px;
}
.rs-services.style5 .flip-box-inner .flip-box-wrap .front-part .front-content-part .front-icon-part .icon-part img {
  width: 60px;
  height: 60px;
}
.rs-services.style5 .flip-box-inner .flip-box-wrap .front-part .front-content-part .front-title-part .title {
  font-size: 20px;
  font-weight: 700;
  line-height: 30px;
  margin-bottom: 10px;
}
.rs-services.style5 .flip-box-inner .flip-box-wrap .front-part .front-content-part .front-title-part .title a {
  color: #0a0a0a;
}
.rs-services.style5 .flip-box-inner .flip-box-wrap .front-part .front-content-part .front-title-part .title a:hover {
  color: #bb0b0b;
}
.rs-services.style5 .flip-box-inner .flip-box-wrap .front-part .front-content-part .front-desc-part p {
  color: #444;
  margin: 0;
}
.rs-services.style5 .flip-box-inner .flip-box-wrap .back-part {
  position: absolute;
  z-index: -1;
  padding: 30px;
  border-radius: 5px;
  background-color: #562dd4;
  transform: rotateY(-180deg);
  top: 0;
  right: 0;
  left: 0;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
  align-items: center;
  -ms-flex-align: center;
  height: 300px;
  background-color: initial;
  background-position: 50%;
  background-clip: padding-box;
  background-size: cover;
  background-repeat: no-repeat;
  text-align: center;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  -webkit-perspective: 1e3px;
  perspective: 1e3px;
  -webkit-transition: -webkit-transform 0.6s cubic-bezier(0.2, 0.85, 0.4, 1.275);
  transition: -webkit-transform 0.6s cubic-bezier(0.2, 0.85, 0.4, 1.275);
  transition: transform 0.6s cubic-bezier(0.2, 0.85, 0.4, 1.275);
  transition: transform 0.6s cubic-bezier(0.2, 0.85, 0.4, 1.275), -webkit-transform 0.6s cubic-bezier(0.2, 0.85, 0.4, 1.275);
}
.rs-services.style5 .flip-box-inner .flip-box-wrap .back-part .back-front-content {
  position: relative;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  width: 100%;
  transition: all .9s ease 0s;
}
.rs-services.style5 .flip-box-inner .flip-box-wrap .back-part .back-front-content .back-title-part .back-title {
  font-size: 20px;
  line-height: 30px;
  font-weight: 600;
  margin-bottom: 15px;
}
.rs-services.style5 .flip-box-inner .flip-box-wrap .back-part .back-front-content .back-title-part .back-title a {
  color: #ffffff;
}
.rs-services.style5 .flip-box-inner .flip-box-wrap .back-part:before {
  position: absolute;
  top: 0;
  left: 0;
  z-index: -9;
  width: 100%;
  height: 100%;
  background-image: linear-gradient(90deg, #0082f1 0%, #0082f1 58%);
  content: "";
}
.rs-services.style5 .flip-box-inner:hover .flip-box-wrap .front-part {
  transform: rotateY(180deg);
}
.rs-services.style5 .flip-box-inner:hover .flip-box-wrap .back-part {
  transform: rotateY(0);
  z-index: 1;
}
.rs-services.style5 .flip-box-inner:hover .flip-box-wrap .back-part .back-front-content {
  right: 0;
}
.rs-services.style5 .flip-box-inner:hover .flip-box-wrap .back-part .back-front-content .back-title-part .back-title {
  color: #ffffff;
}
.rs-services.style5 .flip-box-inner:hover .flip-box-wrap .back-part .back-front-content .back-desc-part .back-desc {
  color: #ffffff;
}
.rs-services.style6 {
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  position: relative;
  margin-top: -20px;
  margin-bottom: 0px;
  padding: 0px 0px 0px 0px;
  z-index: 2;
  display: flex;
}
.rs-services.style6 .services-box-area {
  border-style: solid;
  border-width: 0px 0px 5px 0px;
  border-color: #FD4A36;
  box-shadow: 0px 11px 35px 0px #e9d1cf;
}
.rs-services.style6 .services-box-area .services-item {
  border-style: dashed;
  border-width: 0px 1px 0px 0px;
  border-color: #FD4A363D;
  margin: 0px 8px 0px 8px;
  padding: 40px 35px 40px 35px;
}
.rs-services.style6 .services-box-area .services-item .services-icon {
  margin-bottom: 17px;
}
.rs-services.style6 .services-box-area .services-item .services-icon img {
  width: 54px;
  padding: 8px 0px 0px 0px;
}
.rs-services.style6 .services-box-area .services-item .services-content .title {
  font-size: 18px;
  line-height: 1.5;
  font-weight: 700;
  margin-bottom: 10px;
}
.rs-services.style6 .services-box-area .services-item .services-content .title a {
  color: #0a0a0a;
}
.rs-services.style6 .services-box-area .services-item .services-content .title a:hover {
  color: #fd4a36;
}
.rs-services.style6 .services-box-area .services-item .services-content p {
  font-size: 15px;
}
.rs-services.style7 .services-item {
  display: flex;
  align-items: center;
}
.rs-services.style7 .services-item .services-icon img {
  height: 50px;
  width: 50px;
  max-width: unset;
}
.rs-services.style7 .services-item .services-content {
  padding: 0px 20px 0px 26px;
}
.rs-services.style7 .services-item .services-content .title {
  font-size: 20px;
  line-height: 34px;
  font-weight: 700;
  color: #ffffff;
  margin: 0;
}
.rs-services.style7 .services-item .services-content .title:hover {
  color: #4caf50;
}
.rs-services.style7 .services-item .services-content .services-txt {
  color: #ffffff;
  margin: 0;
}
.rs-services.style8 .flip-box-inner {
  position: relative;
  z-index: 1;
  padding: 0;
  backface-visibility: hidden;
}
.rs-services.style8 .flip-box-inner .flip-box-wrap {
  position: relative;
  -webkit-transform: translateZ(0);
  perspective: 1000px;
}
.rs-services.style8 .flip-box-inner .flip-box-wrap .front-part {
  position: relative;
  bottom: 0;
  z-index: 10;
  text-align: left;
  border-style: solid;
  border-width: 0px 0px 0px 0px;
  top: 0;
  right: 0;
  left: 0;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
  align-items: center;
  -ms-flex-align: center;
  height: 300px;
  background-color: transparent;
  background-position: center;
  background-clip: padding-box;
  background-size: cover;
  background-repeat: no-repeat;
  text-align: center;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  -webkit-perspective: 1000px;
  perspective: 1000px;
  -webkit-transition: -webkit-transform 0.6s cubic-bezier(0.2, 0.85, 0.4, 1.275);
  transition: -webkit-transform 0.6s cubic-bezier(0.2, 0.85, 0.4, 1.275);
  transition: transform 0.6s cubic-bezier(0.2, 0.85, 0.4, 1.275);
  transition: transform 0.6s cubic-bezier(0.2, 0.85, 0.4, 1.275), -webkit-transform 0.6s cubic-bezier(0.2, 0.85, 0.4, 1.275);
}
.rs-services.style8 .flip-box-inner .flip-box-wrap .front-part .front-content-part {
  background-color: #fff;
  text-align: left;
  padding: 38px 38px 38px 38px;
  margin: 0 6px 0 6px;
  border-style: solid;
  border-width: 0px 0px 0px 0px;
  border-radius: 4px 4px 4px 4px;
  box-shadow: 3px 7px 25px 0px rgba(0, 0, 0, 0.05);
}
.rs-services.style8 .flip-box-inner .flip-box-wrap .front-part .front-content-part .front-icon-part {
  margin-bottom: 15px;
}
.rs-services.style8 .flip-box-inner .flip-box-wrap .front-part .front-content-part .front-icon-part .icon-part img {
  width: 60px;
  height: 60px;
}
.rs-services.style8 .flip-box-inner .flip-box-wrap .front-part .front-content-part .front-title-part .title {
  font-size: 20px;
  line-height: 1.5;
  font-weight: 700;
  line-height: 30px;
  margin-bottom: 10px;
}
.rs-services.style8 .flip-box-inner .flip-box-wrap .front-part .front-content-part .front-title-part .title a {
  color: #101010;
}
.rs-services.style8 .flip-box-inner .flip-box-wrap .front-part .front-content-part .front-title-part .title a:hover {
  color: #bb0b0b;
}
.rs-services.style8 .flip-box-inner .flip-box-wrap .front-part .front-content-part .front-desc-part p {
  font-size: 15px;
  color: #444444;
  margin-bottom: 0;
}
.rs-services.style8 .flip-box-inner .flip-box-wrap .back-front {
  transform: rotateY(-180deg);
  background-color: #562dd4;
  position: absolute;
  z-index: -1;
  padding: 30px;
  margin: 0 6px 0 6px;
  border-radius: 4px 4px 4px 4px;
  top: 0;
  right: 0;
  left: 0;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
  align-items: center;
  -ms-flex-align: center;
  height: 300px;
  background-color: transparent;
  background-position: center;
  background-clip: padding-box;
  background-size: cover;
  background-repeat: no-repeat;
  text-align: center;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  -webkit-perspective: 1000px;
  perspective: 1000px;
  -webkit-transition: -webkit-transform 0.6s cubic-bezier(0.2, 0.85, 0.4, 1.275);
  transition: -webkit-transform 0.6s cubic-bezier(0.2, 0.85, 0.4, 1.275);
  transition: transform 0.6s cubic-bezier(0.2, 0.85, 0.4, 1.275);
  transition: transform 0.6s cubic-bezier(0.2, 0.85, 0.4, 1.275), -webkit-transform 0.6s cubic-bezier(0.2, 0.85, 0.4, 1.275);
}
.rs-services.style8 .flip-box-inner .flip-box-wrap .back-front .back-front-content .back-title-part .back-title {
  font-size: 20px;
  line-height: 30px;
  font-weight: 600;
  margin-bottom: 15px;
}
.rs-services.style8 .flip-box-inner .flip-box-wrap .back-front .back-front-content .back-title-part .back-title a {
  color: #ffffff;
}
.rs-services.style8 .flip-box-inner .flip-box-wrap .back-front:before {
  position: absolute;
  top: 0;
  left: 0;
  z-index: -9;
  width: 100%;
  height: 100%;
  background-color: transparent;
  content: "";
}
.rs-services.style8 .flip-box-inner:hover .flip-box-wrap .front-part {
  transform: rotateY(180deg);
}
.rs-services.style8 .flip-box-inner:hover .flip-box-wrap .back-front {
  transform: rotateY(0);
  z-index: 1;
}
.rs-services.style8 .flip-box-inner:hover .flip-box-wrap .back-front:before {
  background-color: transparent;
  background-image: linear-gradient(90deg, #4caf50 0%, #4caf50 58%);
  border-radius: 4px 4px 4px 4px;
}
.rs-services.style8 .flip-box-inner:hover .flip-box-wrap .back-front .back-front-content .back-title-part .back-title {
  color: #ffffff;
}
.rs-services.style8 .flip-box-inner:hover .flip-box-wrap .back-front .back-front-content .back-desc-part .back-desc {
  color: #ffffff;
}
.rs-services.style1 {
  margin-top: -120px;
}
.rs-services.style1 .service-wrap {
  box-shadow: 0 6px 30px 0 rgba(0, 0, 0, 0.08);
  z-index: 9;
  position: relative;
  border-radius: 0 100px 0 0;
}
.rs-services.style1 .service-wrap .service-grid {
  padding: 50px 45px 40px;
  transition: all 0.3s ease;
  background: #ffffff;
  transform: scale(1);
  border-right: 1px solid;
  border-color: #f5f5f5;
}
.rs-services.style1 .service-wrap .service-grid.bdru {
  border-radius: 0 100px 0 0;
}
.rs-services.style1 .service-wrap .service-grid .service-icon img {
  max-width: 51px;
  height: 56px;
  filter: grayscale(1);
  transition: all 0.3s ease;
}
.rs-services.style1 .service-wrap .service-grid .title a {
  color: #043d72;
}
.rs-services.style1 .service-wrap .service-grid .title a:hover {
  color: #e94d65;
}
.rs-services.style1 .service-wrap .service-grid .btn-wrap .readmore {
  font-weight: 600;
  display: inline-flex;
  align-items: center;
}
.rs-services.style1 .service-wrap .service-grid .btn-wrap .readmore .btn-arrow {
  position: relative;
  width: 20px;
  height: 16px;
  overflow: hidden;
  margin-left: 8px;
}
.rs-services.style1 .service-wrap .service-grid .btn-wrap .readmore .btn-arrow:before,
.rs-services.style1 .service-wrap .service-grid .btn-wrap .readmore .btn-arrow:after {
  position: absolute;
  content: "\f114";
  font-family: Flaticon;
  top: 50%;
  transform: translateY(-50%);
  color: #043d72;
  transition: all 0.3s ease;
}
.rs-services.style1 .service-wrap .service-grid .btn-wrap .readmore .btn-arrow:before {
  right: 1px;
}
.rs-services.style1 .service-wrap .service-grid .btn-wrap .readmore .btn-arrow:after {
  right: 22px;
}
.rs-services.style1 .service-wrap .service-grid .btn-wrap .readmore:hover {
  color: #e94d65;
}
.rs-services.style1 .service-wrap .service-grid .btn-wrap .readmore:hover div:before,
.rs-services.style1 .service-wrap .service-grid .btn-wrap .readmore:hover div:after {
  color: #e94d65;
}
.rs-services.style1 .service-wrap .service-grid .btn-wrap .readmore:hover div:before {
  right: -20px;
}
.rs-services.style1 .service-wrap .service-grid .btn-wrap .readmore:hover div:after {
  right: 1px;
}
.rs-services.style1 .service-wrap .service-grid.br-none {
  border-right: none;
}
.rs-services.style1 .service-wrap .service-grid:hover {
  transform: scale(1.1);
  position: relative;
  z-index: 1;
  box-shadow: 0 6px 30px 0 rgba(0, 0, 0, 0.08);
}
.rs-services.style1 .service-wrap .service-grid:hover .service-icon img {
  filter: grayscale(0);
}
.rs-services.style1.modify {
  margin: 0;
}
.rs-services.style1.modify .service-wrap {
  border-radius: 0;
}
.rs-services.style1.modify .service-wrap .service-grid {
  border-bottom: 2px solid #f5f5f5;
}
.rs-services-single .services-add {
  background: url(assets/images/services/single/side-img.jpg);
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  text-align: center;
  padding: 50px 35px 28px;
}
.rs-services-single .services-add .title {
  font-size: 24px;
  line-height: 42px;
  font-weight: 700;
  color: #ffffff;
  margin-bottom: 30px;
}
.rs-services-single .services-add a {
  font-size: 30px;
  line-height: 40px;
  font-weight: 700;
  color: #ffffff;
}
.rs-services-single .brochures {
  background: #ebebeb;
  padding: 35px 30px 45px 30px;
}
.rs-services-single .brochures .title-part .title {
  font-size: 28px;
  font-weight: 700;
  display: block;
  position: relative;
}
.rs-services-single .brochures .title-part .title:before {
  content: "";
  position: absolute;
  border: 0;
  width: 65px;
  height: 5px;
  background: #bb0b0b;
  z-index: 1;
  margin-left: 0;
  left: 0;
  bottom: -20px;
}
.rs-services-single .brochures .btn-wrapper {
  position: relative;
  text-align: center;
  font-weight: 400;
  display: inline-block;
}
.rs-services-single .brochures .btn-wrapper .dual-btn {
  padding: 15px 30px 15px 30px;
  font-size: 17px;
  font-weight: 600;
  background-color: #BB0B0B;
  color: #ffffff;
  margin: 0 -4px;
}
.rs-services-single .brochures .btn-wrapper .dual-btn-connector {
  position: absolute;
  z-index: 9;
  overflow: hidden;
  width: 30px;
  height: 30px;
  border-radius: 100%;
  background-color: #fff;
  box-shadow: 0 0 0 5px #bfb2b2;
  color: #27374c;
  text-transform: uppercase;
  font-size: 12px;
  line-height: 30px;
  left: 47%;
}
.rs-services-single .brochures .btn-wrapper .rselement-dual-btn {
  padding: 15px 25px 15px 25px;
  border-radius: 0px 0px 0px 0px;
  font-size: 17px;
  font-weight: 600;
  background-color: #132235;
  color: #ffffff;
}
.rs-services-single .dot {
  list-style: disc;
  margin: 0 0 1.3em 3em;
}
.rs-services-single .widget-item li {
  border-top-style: solid;
  border-top-width: 1px;
  border-color: #eee;
  width: 79%;
  padding-bottom: calc(16px);
  padding-top: 16px;
}
.rs-services-single .widget-item li a {
  font-size: 16px;
  font-weight: 600;
  color: #000000;
}
.rs-services-single .widget-item li a i {
  color: #000000;
  font-size: 16px;
  font-weight: 900;
}
.rs-services-single .widget-item li a span {
  color: #020202;
  padding-left: 16px;
}
.rs-services-single .widget-item li a:hover i,
.rs-services-single .widget-item li a:hover span {
  color: #bb0b0b;
}
.rs-services-single .widget-item li:first-child {
  border-top: none;
}
.rs-services-single .social-icons li {
  display: inline;
  margin-right: 5px;
}
.rs-services-single .social-icons li a i {
  padding: 0 0px 0 0;
  font-size: 25px;
  background: #3b5998;
  width: 50px;
  height: 50px;
  line-height: 50px;
  border-radius: 0;
  color: #fff;
  text-align: center;
}
.rs-services-single .social-icons li a i:hover {
  opacity: .9;
}
.rs-services-single .social-icons li a.blue-bg i {
  background: #1da1f2;
}
.rs-services-single .social-icons li a.light-bg i {
  background: #0a0a0a;
}
.rs-services-single .social-icons li a.red-bg i {
  background: #cd201f;
}
.rs-services-single .call-us {
  background: url(assets/images/choose/call-us.html);
  background-repeat: no-repeat;
  padding: 110px 0px 110px 0px;
}
.rs-services-single .call-us .contact-widget {
  text-align: center;
}
.rs-services-single .call-us .contact-widget .icon-part {
  margin-bottom: 40px;
}
.rs-services-single .call-us .contact-widget .icon-part img {
  max-width: 84px;
}
.rs-services-single .call-us .contact-widget .title {
  font-size: 36px;
  line-height: 46px;
  font-weight: 700;
  margin: 0;
}
.rs-services-single .call-us .contact-widget .title a {
  color: #ffffff;
}
.rs-services-single .call-us .contact-widget .title a:hover {
  color: #bb0b0b;
}
/* ------------------------------------
    14. Faq Section CSS
---------------------------------------*/
.rs-faq .faq-content .accordion {
  border-width: 12px;
}
.rs-faq .faq-content .accordion .card {
  margin-bottom: 15px;
  border: none;
  background-color: #fff; 
}
.rs-faq .faq-content .accordion .card:last-child {
  margin-bottom: 0;
}
.rs-faq .faq-content .accordion .card .card-header {
  display: flex;
  align-items: center;
  padding: 0 !important;
  border: none;
  box-shadow: 0 0 10px #efefef;
  background: #fff;
}
.rs-faq .faq-content .accordion .card .card-header .card-link {
  position: relative;
  display: block;
  width: 100%;
  padding: 20px 60px 20px 30px;
  height:auto;
  line-height: 1.3;
  font-size: 18px;
  font-weight: 600;
  background: #ffffff;
  box-shadow: 0 0 10px #efefef;
  color: #0a0a0a;
}
.rs-faq .faq-content .accordion .card .card-header .card-link:after {
  position: absolute;
  font-family: FontAwesome;
  content: "\f068";
  right: 20px;
  top: 50%;
  transform: translateY(-50%);
  text-align: center;
  font-size: 12px;
  color: #ffffff;
  background:#4ec226;
  width: 35px;
  height: 35px;
  line-height: 35px;
  border-radius: 100%;
}
.rs-faq .faq-content .accordion .card .card-header .card-link.collapsed:after {
  color: #ffffff;
  content: "\f067";
}
.rs-faq .faq-content .accordion .card .card-body {
  color: #0a0a0a;
  padding: 0 30px 30px 30px !important;
  width: 100%;
  background: #fff;
  box-shadow: 0 4px 10px #efefef;
}
.rs-faq.faq-home-style5 .faq-content .accordion .card .card-header .card-link:after {
  left: 13px;
  font-size: 12px;
  color: #0a0a0a;
  box-shadow: unset;
  background: unset;
}
.rs-faq.faq-home-style5 .faq-content .accordion .card .card-header {
  box-shadow: unset;
}
.rs-faq.faq-home-style5 .faq-content .accordion .card .card-header .card-link {
  padding: 22px 36px 0 60px;
  font-size: 16px;
  font-weight: 600;
  background: #ffffff;
  box-shadow: unset;
}
.rs-faq.faq-home-style5 .faq-content .accordion .card .card-header.active .card-link {
  background: #4caf50;
  color: #ffffff;
  padding: 22px 36px 0 60px;
}
.rs-faq.faq-home-style5 .faq-content .accordion .card .card-header.active .card-link:after {
  color: #ffffff;
}
.rs-faq.faq-home-style5 .faq-content .accordion .card .card-body {
  background: #fff;
  box-shadow: unset;
}
/* ------------------------------------
    15. Counter Section CSS
---------------------------------------*/
.rs-counter.style1 .counter-border-top {
  border-style: solid;
  border-width: 4px 0px 0px 0px;
  border-color: #BB0B0B08;
  transition: background 0.3s, border 0.3s, border-radius 0.3s, box-shadow 0.3s;
  margin-top: 80px;
  margin-bottom: 0px;
  padding: 50px 0px 0px 0px;
}
.rs-counter.style1 .counter-area .counter-list {
  display: flex;
  align-items: center;
}
.rs-counter.style1 .counter-area .counter-list .counter-icon img {
  width: 80px;
  display: inline-block;
  padding-right: 15px;
}
.rs-counter.style1 .counter-area .counter-list .counter-number .rs-count {
  font-size: 50px;
  line-height: 60px;
  font-weight: 700;
  color: #0a0a0a;
}
.rs-counter.style1 .counter-area .counter-list .counter-number .prefix {
  font-size: 30px;
  font-weight: 500;
  color: #0a0a0a;
}
.rs-counter.style1 .counter-area .content-part .title {
  font-size: 15px;
  font-weight: 600;
  line-height: 20px;
  color: #363636;
  margin: 0px 54px 0px 0px;
  position: relative;
  padding-bottom: 20px;
}
.rs-counter.style1 .counter-area .content-part .title:before {
  content: "";
  position: absolute;
  border: 0;
  width: 65px;
  height: 5px;
  background: #bb0b0b;
  z-index: 1;
  margin-left: 0;
  left: 0;
  bottom: 0px;
}
.rs-counter.style1.project-single .counter-area .counter-list .counter-icon img {
  width: 70px;
  padding-right: 16px;
}
.rs-counter.style1.project-single .counter-area .counter-list .counter-number .rs-count {
  font-size: 44px;
  line-height: 54px;
}
.rs-counter.style1.project-single .counter-area .content-part .title {
  margin: 0px 38px 0px 0px;
}
.rs-counter.style1.counter-home5-style .counter-area .counter-list .counter-number .prefix,
.rs-counter.style1.counter-home5-style .counter-area .counter-list .counter-number .rs-count {
  color: #ffffff;
}
.rs-counter.style1.counter-home5-style .counter-area .content-part .title {
  color: #ffffff;
  margin: unset;
  position: relative;
  padding: 0px 0px 0px 82px;
}
.rs-counter.style1.counter-home5-style .counter-area .content-part .title:before {
  display: none;
}
/* ------------------------------------
    16. Choose Us Section CSS
---------------------------------------*/
.rs-why-choose.style1 .choose-content {
  padding: 100px 130px 100px;
}
/* ------------------------------------
    17. Team Section CSS
---------------------------------------*/
.rs-team.style1 .team-wrap {
  position: relative;
  transition: all 0.3s ease;
}
.rs-team.style1 .team-wrap .image-wrap {
  position: relative;
  overflow: hidden;
}
.rs-team.style1 .team-wrap .image-wrap .social-icons {
  position: absolute;
  top: 0;
  right: -50px;
  width: 50px;
  background: #BB0B0B;
  padding: 12px 0 0;
  text-align: center;
  opacity: 0;
  visibility: hidden;
  transition: all .4s ease 0s;
}
.rs-team.style1 .team-wrap .image-wrap .social-icons a i {
  background: transparent;
  color: #fff;
  font-size: 15px;
  width: 35px;
  height: 35px;
  line-height: 35px;
  text-align: center;
  transition: 0.3s all ease;
  border-radius: 50%;
  margin: 0;
}
.rs-team.style1 .team-wrap .image-wrap .social-icons:after {
  content: "";
  position: absolute;
  width: 0;
  height: 0;
  border-top: 50px solid #bb0b0b;
  border-right: 50px solid transparent;
  bottom: 0;
  left: 0;
  display: block;
  top: 100%;
}
.rs-team.style1 .team-wrap .team-content {
  text-align: center;
  margin: 0;
  padding: 20px 20px 25px;
  background-color: #191919;
  position: unset;
  opacity: 1;
}
.rs-team.style1 .team-wrap .team-content .team-name {
  font-size: 20px;
  font-weight: 600;
  line-height: 34px;
  margin-bottom: 0px;
  text-transform: capitalize;
}
.rs-team.style1 .team-wrap .team-content .team-name a {
  color: #ffffff;
}
.rs-team.style1 .team-wrap .team-content .team-name a:hover {
  color: #bb0b0b;
}
.rs-team.style1 .team-wrap .team-content .team-title {
  font-size: 13px;
  font-weight: 500;
  line-height: 3em;
  color: #C5C2C2;
  text-transform: uppercase;
}
.rs-team.style1 .team-wrap:hover .image-wrap .social-icons {
  right: 0;
  width: 50px;
  opacity: 1;
  visibility: visible;
}
.rs-team.style1.team-style1 .team-wrap {
  box-shadow: 0 5px 20px #eee;
}
.rs-team.style1.team-style1 .team-wrap .image-wrap a img {
  opacity: 1;
  transition: 0.3s ease-in-out;
  transform: scale(1.1);
}
.rs-team.style1.team-style1 .team-wrap .team-content .team-name a {
  color: #ffffff;
}
.rs-team.style1.team-style1 .team-wrap .team-content .team-name a:hover {
  color: #bb0b0b;
}
.rs-team.style1.team-style1 .team-wrap:hover .image-wrap a img {
  opacity: .7;
  transform: scale(1);
}
.rs-team.style2 .team-item {
  position: relative;
  overflow: hidden;
  padding-bottom: 95px;
  background: #fff;
}
.rs-team.style2 .team-item .team-img img {
  transition: 0.4s;
}
.rs-team.style2 .team-item .team-content {
  height: 100px;
  width: 100%;
  bottom: 0;
  position: absolute;
  padding: 20px 20px;
  color: #444;
  text-align: center;
  overflow: hidden;
  background: #fff;
  transition: .3s all ease;
}
.rs-team.style2 .team-item .team-content .team-info .name {
  font-size: 22px;
  font-weight: 600;
  line-height: 30px;
  margin: 0;
  text-transform: capitalize;
}
.rs-team.style2 .team-item .team-content .team-info .name a {
  color: #0a0a0a;
}
.rs-team.style2 .team-item .team-content .team-info .post {
  font-size: 13px;
  line-height: 26px;
  font-weight: 500;
  color: #333333;
  text-transform: uppercase;
}
.rs-team.style2 .team-item .team-content .social-icon {
  position: absolute;
  bottom: 0;
  width: 100%;
  text-align: center;
  z-index: 100;
  left: 50%;
  transform: translateX(-50%) scale(0.5);
  transition: all 0.4s ease-in-out 0s;
  opacity: 0;
}
.rs-team.style2 .team-item .team-content .social-icon li {
  display: inline-block;
  margin-right: 30px;
}
.rs-team.style2 .team-item .team-content .social-icon li a {
  width: 25px;
  height: 25px;
  line-height: 25px;
  color: #ffffff;
  transition: all 0.3s ease 0s;
  background: transparent;
}
.rs-team.style2 .team-item .team-content .social-icon li a i {
  font-size: 30px;
  color: #ffffff;
}
.rs-team.style2 .team-item .team-content .social-icon li a i:hover {
  color: #0a0a0a;
}
.rs-team.style2 .team-item .team-content .social-icon li:last-child {
  margin-right: 0;
}
.rs-team.style2 .team-item .team-content:before {
  content: "";
  position: absolute;
  bottom: 0;
  width: 100%;
  height: 3px;
  background: #bb0b0b;
  transition: .4s all ease;
  left: 0;
}
.rs-team.style2 .team-item:hover .team-img img {
  transform: scale(1.1);
}
.rs-team.style2 .team-item:hover .team-content {
  opacity: 1;
  width: 100%;
  visibility: visible;
  transform: translate(0, 0);
  bottom: 0;
  height: 140px;
  color: #fff;
  background: #bb0b0b;
}
.rs-team.style2 .team-item:hover .team-content .team-info .name a {
  color: #ffffff;
}
.rs-team.style2 .team-item:hover .team-content .team-info .post {
  color: #ffffff;
}
.rs-team.style2 .team-item:hover .team-content .social-icon {
  bottom: 20px;
  opacity: 1;
}
.rs-team.style3 .team-item {
  position: relative;
  overflow: hidden;
}
.rs-team.style3 .team-item .team-img {
  overflow: hidden;
}
.rs-team.style3 .team-item .team-img img {
  transition: 0.4s;
}
.rs-team.style3 .team-item .team-content {
  padding: 25px 20px 30px;
  color: #444;
  text-align: center;
  overflow: hidden;
  background: #fff;
  transition: .3s all ease;
}
.rs-team.style3 .team-item .team-content .team-info .name {
  font-size: 22px;
  font-weight: 600;
  line-height: 30px;
  margin-bottom: 6px;
}
.rs-team.style3 .team-item .team-content .team-info .name a {
  color: #0a0a0a;
}
.rs-team.style3 .team-item .team-content .team-info .name a:hover {
  color: #bb0b0b;
}
.rs-team.style3 .team-item .team-content .team-info .post {
  font-size: 13px;
  line-height: 26px;
  font-weight: 500;
  color: #333333;
  text-transform: uppercase;
  display: block;
  margin-bottom: 7px;
}
.rs-team.style3 .team-item .team-content .social-icon li {
  display: inline-block;
  margin-right: 22px;
}
.rs-team.style3 .team-item .team-content .social-icon li a {
  font-size: 15px;
  width: 25px;
  height: 25px;
  line-height: 25px;
  color: #ffffff;
  transition: all 0.3s ease 0s;
  background: transparent;
}
.rs-team.style3 .team-item .team-content .social-icon li a i {
  color: #4a4a4a;
}
.rs-team.style3 .team-item .team-content .social-icon li a i:hover {
  color: #bb0b0b;
}
.rs-team.style3 .team-item .team-content .social-icon li:last-child {
  margin-right: 0;
}
.rs-team.style3 .team-item:hover .team-img img {
  transform: scale(1.2);
}
.rs-team.style4 .team-item-wrap .team-wrap .image-inner {
  overflow: hidden;
  border-radius: 50%;
}
.rs-team.style4 .team-item-wrap .team-wrap .image-inner a img {
  transform: scale(1);
  -webkit-transition: 0.3s ease-in-out;
  transition: 0.3s ease-in-out;
}
.rs-team.style4 .team-item-wrap .team-content {
  padding: 20px 20px 20px;
}
.rs-team.style4 .team-item-wrap .team-content .person-name {
  font-size: 20px;
  font-weight: 600;
  line-height: 30px;
  margin-bottom: 5px;
}
.rs-team.style4 .team-item-wrap .team-content .person-name a {
  color: #0a0a0a;
}
.rs-team.style4 .team-item-wrap .team-content .person-name a:hover {
  color: #0082f1;
}
.rs-team.style4 .team-item-wrap .team-content .designation {
  display: block;
  font-size: 15px;
  font-weight: 400;
  line-height: 26px;
  color: #666;
  margin-bottom: 15px;
}
.rs-team.style4 .team-item-wrap .team-content .team-social li {
  display: inline-block;
  margin-right: 16px;
}
.rs-team.style4 .team-item-wrap .team-content .team-social li a i {
  font-size: 18px;
  line-height: 18px;
  font-weight: 400;
  color: #0082f1;
}
.rs-team.style4 .team-item-wrap .team-content .team-social li:last-child {
  margin-right: 0;
}
.rs-team.style4 .team-item-wrap:hover .team-wrap .image-inner a img {
  transform: scale(1.2);
}
.rs-team.style4.home-4-team-style .team-item-wrap .team-wrap .image-inner {
  border-radius: unset;
}
.rs-team.style4.home-4-team-style .team-item-wrap .team-wrap .image-inner a img {
  transform: unset;
}
.rs-team.style4.home-4-team-style .team-item-wrap .team-content {
  padding: 20px 20px 20px;
  background: #ffffff;
  box-shadow: 0 6px 25px #eee;
}
.rs-team.style4.home-4-team-style .team-item-wrap .team-content .person-name a {
  color: #0a0a0a;
}
.rs-team.style4.home-4-team-style .team-item-wrap .team-content .person-name a:hover {
  color: #fd4a36;
}
.rs-team.style4.home-4-team-style .team-item-wrap .team-content .designation {
  display: block;
  font-size: 16px;
  font-weight: 400;
  line-height: 26px;
  color: #454545;
  margin-bottom: 15px;
}
.rs-team.style4.home-4-team-style .team-item-wrap:hover .team-wrap .image-inner a img {
  transform: unset;
}
.rs-team.style4.home-4-team-style .owl-stage-outer {
  padding: 20px;
  margin: -20px;
}
.rs-team-Single .btm-info-team {
  position: relative;
  box-shadow: 0px 0px 32px 0px rgba(7, 28, 31, 0.05);
  background: #fff;
  margin-bottom: 70px;
  padding: 40px;
}
.rs-team-Single .btm-info-team .images-part {
  padding: 0 30px 0 0;
}
.rs-team-Single .btm-info-team .con-info {
  position: relative;
}
.rs-team-Single .btm-info-team .con-info .designation-info {
  font-size: 15px;
  line-height: 26px;
  font-weight: 600;
  color: #bb0b0b;
  text-transform: uppercase;
  display: block;
}
.rs-team-Single .btm-info-team .con-info .title {
  font-size: 36px;
  line-height: 46px;
  font-weight: 700;
  color: #0a0a0a;
  margin-bottom: 15px;
}
.rs-team-Single .btm-info-team .con-info .short-desc {
  font-size: 15px;
  line-height: 26px;
  font-weight: 400;
  color: #454545;
  margin-bottom: 25px;
}
.rs-team-Single .btm-info-team .con-info .team-title {
  font-size: 28px;
  line-height: 38px;
  font-weight: 700;
  color: #0a0a0a;
  margin-bottom: 15px;
}
.rs-team-Single .btm-info-team .con-info .ps-informations .personal-info li {
  margin: 5px 0 10px;
  padding: 0;
  display: block;
}
.rs-team-Single .btm-info-team .con-info .ps-informations .personal-info li span i:before {
  color: #bb0b0b;
  margin-right: 18px;
  position: relative;
  top: 2px;
  font-weight: 500;
  display: inline-block;
  text-align: center;
}
.rs-team-Single .btm-info-team .con-info .ps-informations .personal-info li a {
  color: #555;
}
.rs-team-Single .btm-info-team .con-info .ps-informations .personal-info li a:hover {
  color: #bb0b0b;
}
.rs-team-Single .btm-info-team .con-info .ps-informations .social-info {
  margin-top: 15px;
}
.rs-team-Single .btm-info-team .con-info .ps-informations .social-info li {
  display: inline-block;
  margin-right: 18px;
}
.rs-team-Single .btm-info-team .con-info .ps-informations .social-info li a {
  color: #555;
}
.rs-team-Single .btm-info-team .con-info .ps-informations .social-info li a:hover {
  color: #bb0b0b;
}
.rs-team-Single .btm-info-team .con-info .ps-informations .social-info li:last-child {
  margin-right: 0;
}
.rs-team-Single .btm-info-team .con-info:before {
  content: "\f10b";
  font-size: 250px;
  position: absolute;
  right: 92px;
  color: #0a0a0a;
  font-family: "Flaticon";
  height: 100%;
  top: 74px;
  opacity: 0.02;
}
/* ------------------------------------
    18. Process Section CSS
---------------------------------------*/
.rs-process.style1 .process-effects-layer {
  background-image: url(assets/images/process/style1/steps.png);
  background-position: center center;
  background-repeat: no-repeat;
  background-size: 1050px auto;
  margin-top: -110px;
  margin-bottom: 0px;
  padding: 160px 0px 0px 0px;
}
.rs-process.style1 .process-effects-layer .rs-addon-number .number-part .number-image {
  background: #fff;
  padding:44px;
  border-radius: 50%;
  display: block;
  margin: 0 auto;
  width: 140px;
}
.rs-process.style1 .process-effects-layer .rs-addon-number .number-part .number-image img {
  width: 60px;
  display: block;
}
.rs-process.style1 .process-effects-layer .rs-addon-number .number-part .number-text {
  position: relative;
  text-align: center;
}
.rs-process.style1 .process-effects-layer .rs-addon-number .number-part .number-text .number-area {
  position: relative;
  transition: all .3s ease 0s;
  text-align: center;
  width: 50px;
  height: 50px;
  left: -50px;
  top: -36px;
  margin: -13px 0px 0px 0px;
  display: inline-block;
  border-radius: 50px 50px 50px 50px;
  background:#ffe100;
}
.rs-process.style1 .process-effects-layer .rs-addon-number .number-part .number-text .number-area .number-prefix {
  font-size: 18px;
  line-height: 50px;
  font-weight: 700;
  color: #000;
}
.rs-process.style1 .process-effects-layer .rs-addon-number .number-part .number-text .number-title .title {
  font-size: 20px;
  line-height: 38px;
  font-weight: 700;
  color: #F1EAEA;
  margin: 0 0 10px; text-transform: capitalize;
}
.rs-process.style1 .process-effects-layer .rs-addon-number .number-part .number-text .number-txt {
  margin: 0 24px 0 24px;
  color: #FFFFFF;
}
.rs-process.style1.careers-style .process-effects-layer {
  background-image: unset;
  background-position: center center;
  background-repeat: no-repeat;
  background-size: 1050px auto;
  margin-top: -110px;
  margin-bottom: 0px;
  padding: 160px 0px 0px 0px;
}
.rs-process.style1.careers-style .process-effects-layer .rs-addon-number .number-part .number-image {
  background: rgb(4,119,200); background: linear-gradient(59deg, rgba(4,119,200,1) 0%, rgba(9,97,156,1) 100%);
}
.rs-process.style1.careers-style .process-effects-layer .rs-addon-number .number-part .number-text .number-title .title {
  color: #0a0a0a;
}
.rs-process.style1.careers-style .process-effects-layer .rs-addon-number .number-part .number-text .number-txt {
  margin: 0 17px 0 17px;
  color: #454545;
}
.rs-process.style2 .rs-addon-number .number-part {
  padding: 50px 45px 40px 30px;
  background-color: #FFFFFF;
  border-style: solid;
  border-width: 1px 1px 1px 1px;
  border-color: #F2F2F2;
  /*margin: 0 3px 0 3px;*/
}
.rs-process.style2 .rs-addon-number .number-part .number-area .number-prefix {
  font-size: 50px;
  line-height: 50px;
  font-weight: 700;
  color: #00000021;
  display: block;
  margin-bottom: 22px;
}
.rs-process.style2 .rs-addon-number .number-part .number-title .title {
  font-size: 20px;
  line-height: 30px;
  font-weight: 700;
  color: #0a0a0a;
  margin: 0 0 12px; text-transform: capitalize;
}
.rs-process.style2 .rs-addon-number .number-part .number-txt {
  margin-bottom: 27px;
}
.rs-process.style2 .rs-addon-number .number-part .number-txt .loac-text {
  padding-top: 12px;
}
.rs-process.style2 .rs-addon-number .number-part .number-txt .loac-text strong {
  font-weight: 700;
}
/*------------------------------------
    19. Pricing Section CSS
------------------------------------*/
.rs-pricing.style1 .pricing-table {
  padding: 45px 40px 45px 40px;
  border-radius: 5px 5px 5px 5px;
  text-align: center;
}
.rs-pricing.style1 .pricing-table .pricing-table-header .table-title {
  font-size: 22px;
  line-height: 30px;
  font-weight: 600;
  color: #14171C;
  margin-bottom: 22px;
}
.rs-pricing.style1 .pricing-table .pricing-icon img {
  width: 60px;
}
.rs-pricing.style1 .pricing-table .pricing-table-price .pricing-table-bags {
  line-height: .66;
  display: flex;
  justify-content: center;
  margin-bottom: 30px;
}
.rs-pricing.style1 .pricing-table .pricing-table-price .pricing-table-bags .pricing-currency {
  font-size: 22px;
  font-weight: 500;
  color: #0a0a0a;
}
.rs-pricing.style1 .pricing-table .pricing-table-price .pricing-table-bags .table-price-text {
  font-size: 90px;
  font-weight: 600;
  color: #0a0a0a;
}
.rs-pricing.style1 .pricing-table .pricing-table-price .pricing-table-bags .table-period {
  display: flex;
  align-items: flex-end;
  font-size: 15px;
  font-weight: 500;
  color: #0a0a0a;
}
.rs-pricing.style1 .pricing-table .pricing-table-body {
  padding: 10px 15px 30px 15px;
  text-align: left;
}
.rs-pricing.style1 .pricing-table .pricing-table-body ul li {
  padding: 9px 0px 9px 0px;
  margin-bottom: 0px;
  color: #555555;
  font-size: 15px;
  font-weight: 400;
}
.rs-pricing.style1 .pricing-table .pricing-table-body ul li i {
  float: right;
  margin-right: 0;
  min-width: 15px;
  font-size: 16px;
}
.rs-pricing.style1 .pricing-table.white-bg {
  background-color: #fff;
  box-shadow: 0 0.2rem 2.8rem #eee;
}
.rs-pricing.style1 .pricing-table.white-bg .pricing-table-body ul li i,
.rs-pricing.style1 .pricing-table.white-bg .pricing-table-price .pricing-table-bags .pricing-currency,
.rs-pricing.style1 .pricing-table.white-bg .pricing-table-price .pricing-table-bags .table-period,
.rs-pricing.style1 .pricing-table.white-bg .pricing-table-price .pricing-table-bags .table-price-text {
  color: #bb0b0b;
}
.rs-pricing.style2 .pricing-wrap .head-part {
  padding: 60px 60px 37px;
}
.rs-pricing.style2 .pricing-wrap .head-part .title {
  color: #222;
  font-size: 22px;
  font-weight: 700;
  line-height: 1;
  margin-bottom: 18px;
}
.rs-pricing.style2 .pricing-wrap .head-part .price {
  color: #043d72;
  font-weight: 700;
  font-size: 50px;
  line-height: 1;
}
.rs-pricing.style2 .pricing-wrap .head-part .price .period {
  font-size: 16px;
  font-weight: 600;
}
.rs-pricing.style2 .pricing-wrap .body-part {
  padding: 0 60px 60px;
}
.rs-pricing.style2 .pricing-wrap .body-part ul {
  padding: 33px 0 0;
  border-top: 1px solid #eee;
}
.rs-pricing.style2 .pricing-wrap .body-part ul li {
  text-align: left;
  position: relative;
  margin-bottom: 8px;
}
.rs-pricing.style2 .pricing-wrap .body-part ul li i {
  position: absolute;
  content: '';
  right: 0;
  top: 50%;
  transform: translateY(-50%);
  color: #043d72;
}
.rs-pricing.style2 .pricing-wrap .body-part ul li i:before {
  font-size: 12px;
  font-weight: 800;
}
.rs-pricing.style2 .pricing-wrap .body-part ul li.no i {
  color: #bb0b0b;
}
.rs-pricing.style2 .pricing-wrap .body-part ul li:last-child {
  margin-bottom: 0;
}
.rs-pricing.style2 .pricing-wrap .body-part .btn-part {
  margin-top: 33px;
}
.rs-pricing.style2 .pricing-wrap .body-part .btn-part .price-btn {
  padding: 9px 40px;
  border-radius: 30px;
  color: #043d72;
  position: relative;
  display: inline-block;
  background: transparent;
  border: 1px solid #043d72;
  text-align: center;
  outline: none;
  font-weight: 500;
  transition: all 0.3s ease;
}
.rs-pricing.style2 .pricing-wrap .body-part .btn-part .price-btn:hover {
  background: #043d72;
  color: #ffffff;
}
.rs-pricing.style2 .pricing-wrap.active {
  box-shadow: 0 6px 30px 0 rgba(0, 0, 0, 0.08);
}
.rs-pricing.style2 .pricing-wrap.active .head-part .price {
  color: #e94d65;
}
.rs-pricing.style2 .pricing-wrap.active .body-part .btn-part .price-btn {
  border-color: #e94d65;
  color: #ffffff;
  background: #e94d65;
}
.rs-pricing.style2 .pricing-wrap.active .body-part .btn-part .price-btn:hover {
  background: transparent;
  color: #e94d65;
}
/*------------------------------------
    20. Project Section CSS
------------------------------------*/
.rs-project.style1 .project-item {
  position: relative;
  overflow: hidden;
}
.rs-project.style1 .project-item .project-content {
  position: absolute;
  padding: 17px 25px 15px;
  opacity: 0;
  visibility: hidden;
  z-index: 1;
  left: 0;
  bottom: -100px;
  text-align: left;
  width: 100%;
  transition: 0.4s;
  height: 100%;
}
.rs-project.style1 .project-item .project-content .project-inner {
  position: absolute;
  bottom: -100px;
  transition: 0.5s;
  left: 0;
  padding: 0 10px;
  width: 100%;
}
.rs-project.style1 .project-item .project-content .project-inner .title {
  font-size: 20px;
  line-height: 30px;
  font-weight: 700;
  margin-bottom: 20px;
}
.rs-project.style1 .project-item .project-content .project-inner .title a {
  color: #ffffff;
}
.rs-project.style1 .project-item .project-content .project-inner .title a:hover {
  color: #bb0b0b;
}
.rs-project.style1 .project-item .project-content .project-inner .category {
  font-size: 15px;
  line-height: 27px;
  font-weight: 400;
  display: block;
  margin-bottom: 5px;
}
.rs-project.style1 .project-item .project-content .project-inner .category a {
  color: #C3C2C2;
}
.rs-project.style1 .project-item .project-content .project-inner .p-icon {
  width: 50px;
  height: 50px;
  text-align: center;
  line-height: 50px;
  border-radius: 50px;
  color: #ffffff;
  background: #bb0b0b;
  font-size: 18px;
  display: block;
}
.rs-project.style1 .project-item .project-content .project-inner .p-icon .custom-icon {
  position: relative;
}
.rs-project.style1 .project-item .project-content .project-inner .p-icon .custom-icon:before {
  font-size: 20px;
  font-weight: 700;
  color: #ffffff;
  margin-left: 0;
  content: "\f114";
  font-family: Flaticon;
}
.rs-project.style1 .project-item .project-content:before {
  content: '';
  position: absolute;
  bottom: -150px;
  left: 0;
  opacity: 0;
  visibility: hidden;
  width: 100%;
  height: 100%;
  background-color: #1A1111E0;
  transition: all 0.4s;
}
.rs-project.style1 .project-item:hover .project-content {
  opacity: 1;
  visibility: visible;
  bottom: 0;
}
.rs-project.style1 .project-item:hover .project-content .project-inner {
  bottom: 40px;
}
.rs-project.style1 .project-item:hover .project-content:before {
  opacity: 1;
  bottom: 0;
  visibility: visible;
}
.rs-project.style1.red-overly-bg .project-item .project-img {
  position: relative;
  z-index: 1;
  overflow: hidden;
}
.rs-project.style1.red-overly-bg .project-item .project-img img {
  transition: 1.3s all ease;
}
.rs-project.style1.red-overly-bg .project-item .project-content .project-inner {
  left: 0;
}
.rs-project.style1.red-overly-bg .project-item .project-content .project-inner .title {
  margin-bottom: 0px;
}
.rs-project.style1.red-overly-bg .project-item .project-content .project-inner .title a {
  color: #ffffff;
}
.rs-project.style1.red-overly-bg .project-item .project-content .project-inner .title a:hover {
  color:#ffe100;
}
.rs-project.style1.red-overly-bg .project-item .project-content .project-inner .category {
  margin-bottom: 0px;
}
.rs-project.style1.red-overly-bg .project-item .project-content .project-inner .category a {
  color: #ffffff; cursor: pointer;
}
.rs-project.style1.red-overly-bg .project-item .project-content:before {
  background-color:#586e7ecc;
}
.rs-project.style1.red-overly-bg .project-item:hover .project-img img {
  transform: scale(1.2);
}
.rs-project.style1.red-overly-bg .project-item:hover .project-content {
  opacity: 1;
  visibility: visible;
  bottom: 0;
}
.rs-project.style1.red-overly-bg .project-item:hover .project-content .project-inner {
  bottom: 25px;
}
.rs-project.style1.red-overly-bg .project-item:hover .project-content:before {
  opacity: 1;
  bottom: 0;
  visibility: visible;
}
.rs-project.style1.project-home5-style .project-item .project-content .project-inner .title a:hover {
  color: #4caf50;
}
.rs-project.style1.project-home5-style .project-item .project-content .project-inner .p-icon {
  background: #4caf50;
}
.rs-project.style2 .project-item {
  position: relative;
  overflow: hidden;
  z-index: 1;
  transition: all 0.3s ease;
}
.rs-project.style2 .project-item .project-content {
  opacity: 0;
  visibility: hidden;
}
.rs-project.style2 .project-item .project-content .p-icon {
  position: absolute;
  top: 40px;
  right: 0px;
  bottom: 20px;
  text-align: center;
  width: 50px;
  height: 50px;
  line-height: 50px;
  border-radius: 50px;
  color: #ffffff;
  background: #bb0b0b;
  font-size: 18px;
  display: block;
}
.rs-project.style2 .project-item .project-content .p-icon .custom-icon {
  position: relative;
}
.rs-project.style2 .project-item .project-content .p-icon .custom-icon:before {
  font-size: 20px;
  font-weight: 700;
  color: #ffffff;
  margin-left: 0;
  content: "\f114";
  font-family: Flaticon;
}
.rs-project.style2 .project-item .project-content .project-inner {
  position: absolute;
  bottom: 0px;
  left: 25px;
  transition: all 0.3s ease;
}
.rs-project.style2 .project-item .project-content .project-inner .category {
  font-size: 15px;
  line-height: 1.5em;
  font-weight: 400;
  margin-bottom: 7px;
  display: block;
}
.rs-project.style2 .project-item .project-content .project-inner .category a {
  color: #C3C2C2;
}
.rs-project.style2 .project-item .project-content .project-inner .title {
  font-size: 20px;
  font-weight: 600;
  line-height: 30px;
  margin-bottom: 0;
}
.rs-project.style2 .project-item .project-content .project-inner .title a {
  color: #ffffff;
}
.rs-project.style2 .project-item .project-content .project-inner .title a:hover {
  color: #bb0b0b;
}
.rs-project.style2 .project-item:before {
  background-color: #1A1111E0;
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
  visibility: hidden;
  transition: all 0.4s ease 0s;
}
.rs-project.style2 .project-item:hover .project-content {
  opacity: 1;
  visibility: visible;
}
.rs-project.style2 .project-item:hover .project-content .p-icon {
  right: 25px;
}
.rs-project.style2 .project-item:hover .project-content .project-inner {
  bottom: 25px;
}
.rs-project.style2 .project-item:hover:before {
  opacity: .9;
  visibility: visible;
}
.rs-project.style3 .project-item {
  position: relative;
  overflow: hidden;
  border-radius: 0;
  z-index: 1;
}
.rs-project.style3 .project-item .project-img {
  position: relative;
  overflow: hidden;
}
.rs-project.style3 .project-item .project-img a img {
  transition: 1.3s all ease;
}
.rs-project.style3 .project-item .project-content {
  position: absolute;
  padding: 24px 30px;
  opacity: 1;
  z-index: 10;
  top: 0;
  border: none;
  bottom: 0;
  left: 0;
  right: 0;
  text-align: left;
  width: 100%;
}
.rs-project.style3 .project-item .project-content .portfolio-inner {
  position: absolute;
  bottom: 30px;
  right: 40px;
  left: 40px;
  transition: all .5s ease;
  width: 100%;
}
.rs-project.style3 .project-item .project-content .portfolio-inner .category {
  font-size: 15px;
  line-height: 26px;
  font-weight: 400;
  margin-bottom: 5px;
  display: block;
}
.rs-project.style3 .project-item .project-content .portfolio-inner .category a {
  color: #C3C2C2;
}
.rs-project.style3 .project-item .project-content .portfolio-inner .title {
  font-size: 20px;
  line-height: 26px;
  font-weight: 700;
  margin-bottom: 0px;
}
.rs-project.style3 .project-item .project-content .portfolio-inner .title a {
  color: #ffffff;
}
.rs-project.style3 .project-item .project-content .portfolio-inner .title a:hover {
  color: #bb0b0b;
}
.rs-project.style3 .project-item .project-content:before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  transition: all .3s ease 0s;
  background: rgba(0, 0, 0, 0.4);
  opacity: 1;
}
.rs-project.style3 .project-item:hover .project-img a img {
  transform: scale(1.2);
}
.rs-project.style3 .project-item:hover .project-content {
  opacity: 1;
}
.rs-project.style3 .project-item:hover .project-content .portfolio-inner {
  bottom: 50px;
}
.rs-project.style3.home3-project-style .project-item .project-content .portfolio-inner .category a:hover,
.rs-project.style3.home3-project-style .project-item .project-content .portfolio-inner .title a:hover {
  color: #0082f1;
}
.rs-project.style3.home3-project-style .project-item .project-content .portfolio-inner .category a {
  color: #FCFCFC;
  font-weight: 500;
}
.rs-project.style4 .project-item {
  position: relative;
  overflow: hidden;
  border-radius: 0;
  transition: all 0.3s ease;
  z-index: 1;
}
.rs-project.style4 .project-item .project-content {
  position: absolute;
  padding: 20px 25px 20px 25px;
  opacity: 0;
  z-index: 1;
  bottom: -150px;
  left: 50%;
  transform: translateX(-50%);
  width: 86%;
  z-index: 9;
  background: #fff;
  transition: .6s;
}
.rs-project.style4 .project-item .project-content .category {
  font-size: 15px;
  line-height: 26px;
  font-weight: 400;
  margin-bottom: 5px;
  display: block;
}
.rs-project.style4 .project-item .project-content .category a {
  color: #454545;
}
.rs-project.style4 .project-item .project-content .category a:hover {
  color: #bb0b0b;
}
.rs-project.style4 .project-item .project-content .title {
  font-size: 20px;
  line-height: 26px;
  font-weight: 700;
  margin-bottom: 0px;
}
.rs-project.style4 .project-item .project-content .title a {
  color: #0a0a0a;
}
.rs-project.style4 .project-item .project-content .title a:hover {
  color: #bb0b0b;
}
.rs-project.style4 .project-item:hover .project-content {
  opacity: 1;
  bottom: 30px;
}
.rs-project.style5 .project-item {
  position: relative;
  overflow: hidden;
  z-index: 1;
}
.rs-project.style5 .project-item .project-img {
  z-index: 1;
}
.rs-project.style5 .project-item .project-img img {
  transform: scale(1.2);
  transition: 0.6s;
}
.rs-project.style5 .project-item .project-content {
  position: absolute;
  padding: 24px 30px;
  opacity: 0;
  z-index: 10;
  top: 0;
  visibility: hidden;
  width: 100%;
  height: 100%;
  text-align: center;
}
.rs-project.style5 .project-item .project-content .title {
  font-size: 20px;
  line-height: 25px;
  font-weight: 600;
  margin-bottom: 5px;
}
.rs-project.style5 .project-item .project-content .title a {
  color: #ffffff;
}
.rs-project.style5 .project-item .project-content .title a:hover {
  color: #bb0b0b;
}
.rs-project.style5 .project-item .project-content .category {
  font-size: 15px;
  line-height: 26px;
  font-weight: 400;
  display: block;
}
.rs-project.style5 .project-item .project-content .category a {
  color: #ffffff;
}
.rs-project.style5 .project-item .project-content .category a:hover {
  color: #bb0b0b;
}
.rs-project.style5 .project-item .project-content:before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  opacity: 0;
  visibility: hidden;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  background-color: #BB0B0B6E;
  transition: all .4s;
  transform: scale(1.2);
  transition: .6s;
}
.rs-project.style5 .project-item:hover .project-img img {
  transform: scale(1);
}
.rs-project.style5 .project-item:hover .project-content {
  opacity: 1;
  visibility: visible;
  transform: scale(1);
}
.rs-project.style5 .project-item:hover .project-content:before {
  opacity: 0.7;
  visibility: visible;
}
.rs-project.style6 .project-item {
  position: relative;
  overflow: hidden;
  transition: all 0.3s ease;
}
.rs-project.style6 .project-item .project-img {
  position: relative;
}
.rs-project.style6 .project-item .project-img .p-icon {
  width: 50px;
  height: 50px;
  text-align: center;
  line-height: 50px;
  border-radius: 50px;
  color: #ffffff;
  background: #bb0b0b;
  font-size: 18px;
  display: block;
  position: absolute;
  right: 30px;
  top: 30px;
  opacity: 0;
  visibility: hidden;
  transition: all 0.3s ease;
}
.rs-project.style6 .project-item .project-img .p-icon .custom-icon {
  position: relative;
}
.rs-project.style6 .project-item .project-img .p-icon .custom-icon:before {
  font-size: 20px;
  font-weight: 700;
  color: #ffffff;
  margin-left: 0;
  content: "\f114";
  font-family: Flaticon;
}
.rs-project.style6 .project-item .project-content {
  margin-top: 25px;
}
.rs-project.style6 .project-item .project-content .title {
  font-size: 20px;
  font-weight: 700;
  line-height: 1.1;
  margin-bottom: 5px;
}
.rs-project.style6 .project-item .project-content .title a {
  color: #0a0a0a;
}
.rs-project.style6 .project-item .project-content .title a:hover {
  color: #bb0b0b;
}
.rs-project.style6 .project-item .project-content .category {
  display: block;
}
.rs-project.style6 .project-item .project-content .category a {
  color: #454545;
}
.rs-project.style6 .project-item:hover .project-img .p-icon {
  opacity: 1;
  visibility: visible;
}
.rs-project.style7 .project-item {
  position: relative;
  overflow: hidden;
}
.rs-project.style7 .project-item .project-img {
  position: relative;
}
.rs-project.style7 .project-item .project-img:before {
  content: "";
  height: 3px;
  background: #2B2A2A;
  position: absolute;
  bottom: -20px;
  left: 0;
  transform: rotate(19deg) scale(1.5);
  transition: .5s;
  width: 100%;
  height: 35%;
  opacity: 0;
  visibility: hidden;
}
.rs-project.style7 .project-item .project-content {
  position: relative;
  padding: 0;
  width: 100%;
  border-top: 0;
  z-index: 11;
  left: 0;
  transition: .6s;
  height: 100%;
  left: 50%;
  transform: translateX(-50%);
  padding: 0 40px;
  text-align: left;
}
.rs-project.style7 .project-item .project-content .project-inner {
  position: absolute;
  width: 100%;
  transition: .6s;
  z-index: 9;
  bottom: 0;
  opacity: 0;
  visibility: hidden;
}
.rs-project.style7 .project-item .project-content .project-inner .title {
  font-size: 20px;
  line-height: 30px;
  font-weight: 700;
  margin-bottom: 5px;
}
.rs-project.style7 .project-item .project-content .project-inner .title a {
  color: #ffffff;
}
.rs-project.style7 .project-item .project-content .project-inner .title a:hover {
  color: #bb0b0b;
}
.rs-project.style7 .project-item .project-content .project-inner .category a {
  color: #CCCCCC;
  font-weight: 500;
  display: block;
}
.rs-project.style7 .project-item .project-content .p-icon {
  position: absolute;
  transition: .6s;
  left: 40px;
  bottom: 0;
  opacity: 0;
  visibility: hidden;
  z-index: 9;
}
.rs-project.style7 .project-item .project-content .p-icon i {
  width: 50px;
  height: 50px;
  border-radius: 50px;
  background: #bb0b0b;
  line-height: 55px;
  color: #fff;
  text-align: center;
  display: block;
}
.rs-project.style7 .project-item .project-content .p-icon i:before {
  font-size: 22px;
  color: #ffffff;
}
.rs-project.style7 .project-item:hover .project-img:before {
  opacity: 1;
  visibility: visible;
}
.rs-project.style7 .project-item:hover .project-content .project-inner {
  bottom: 100px;
  opacity: 1;
  visibility: visible;
}
.rs-project.style7 .project-item:hover .project-content .p-icon {
  bottom: 30px;
  opacity: 1;
  visibility: visible;
}
/*------------------------------------
    21. Partner Section CSS
------------------------------------*/
.rs-patter-section .logo-img {
  text-align: center;
}
.rs-patter-section .logo-img a {
  overflow: hidden;
  display: inline-block;
  position: relative;
  transition: all 0.3s ease-in-out;
}
.rs-patter-section .logo-img a .hovers-logos {
  display: block;
  position: absolute;
  transition: all 0.3s ease-in-out;
  z-index: 1;
  top: 0;
  left: 0;
  visibility: hidden;
  -webkit-transform: translateY(-100%);
}
.rs-patter-section .logo-img a .mains-logos {
  transition: all 0.3s ease-in-out;
  display: block;
  filter: grayscale(1);
}
.rs-patter-section .logo-img a:hover .hovers-logos {
  display: block;
  opacity: 3;
  visibility: visible;
  transform: translateY(0%);
  filter: grayscale(0%);
}
.rs-patter-section .logo-img a:hover .mains-logos {
  display: block;
  visibility: hidden;
  transform: translateY(100%);
  filter: grayscale(1);
}
.rs-patter-section.style1 .logo-img {
  padding: 0 50px;
}
/*------------------------------------
    22. Blog Section CSS
------------------------------------*/
.rs-blog.blog-main-home .blog-item {
  position: relative;
  transition: all 500ms ease;
}
.rs-blog.blog-main-home .blog-item .image-wrap {
  position: relative;
}
.rs-blog.blog-main-home .blog-item .image-wrap a img {
  transition: all 0.5s ease 0s;
}
.rs-blog.blog-main-home .blog-item .blog-content {
  transition: all 500ms ease;
  position: relative;
  padding: 33px 24px 38px 42px;
  background: #fff;
  box-shadow: 0 6px 25px #eee;
}
.rs-blog.blog-main-home .blog-item .blog-content .blog-meta {
  display: flex;
  background: #B90A0A;
  position: absolute;
  top: -46px;
  left: 44px;
  right: 0;
  text-align: center;
  justify-content: center;
  padding: 13px 0 9px;
  margin: 0;
}
.rs-blog.blog-main-home .blog-item .blog-content .blog-meta .date {
  position: relative;
  margin-right:5px;
  padding-right: 11px;
  font-size: 14px;
  font-weight: 500;
  color: #ffffff;
}
.rs-blog.blog-main-home .blog-item .blog-content .blog-meta .date:after {
  content: "";
  height: 14px;
  width: 1px;
  position: absolute;
  right: 0;
  transform: rotate(20deg) translateY(-55%);
  top: 50%;
  background: #ffffff;
}
.rs-blog.blog-main-home .blog-item .blog-content .blog-meta .admin a {
  font-size: 14px;
  font-weight: 500;
  text-transform: capitalize;
  margin-right: 5px;
  color: #ffffff;
  position: relative;
  padding-right: 4px;
}
.rs-blog.blog-main-home .blog-item .blog-content .blog-meta:after {
  content: "";
  position: absolute;
  width: 0;
  height: 0;
  border-bottom: 47px solid #B90A0A;
  border-left: 45px solid transparent;
  left: -45px;
  display: block;
  top: 0;
}
.rs-blog.blog-main-home .blog-item .blog-content .blog-title {
  font-size: 18px;
  line-height: 1.4;
  font-weight: 500;
  margin-bottom: 20px;
}
.rs-blog.blog-main-home .blog-item .blog-content .blog-title a {
  color: #0a0a0a;
}
.rs-blog.blog-main-home .blog-item .blog-content .blog-title a:hover {
  color:#0069b3;
}
.rs-blog.blog-main-home .blog-item .blog-content .desc {
  margin-bottom: 28px;
}
.rs-blog.blog-main-home .owl-stage-outer {
  padding: 20px 0;
  margin: -20px 0;
}
.rs-blog.style2 .blog-item {
  transition: all 500ms ease;
}
.rs-blog.style2 .blog-item .image-wrap {
  position: relative;
  overflow: hidden;
}
.rs-blog.style2 .blog-item .image-wrap img {transition: all .5s ease 0s; height:190px; object-fit: cover;}
.rs-blog.style2 .blog-item .image-wrap .post-categories {
  position: absolute;
  z-index: 11;
  bottom: 20px;
  right: 20px;
  z-index: 10;
}
.rs-blog.style2 .blog-item .image-wrap .post-categories li {
  display: inline-block;
}
.rs-blog.style2 .blog-item .image-wrap .post-categories li a {
  background: #bb0b0b;
  color: #ffffff;
  transition: 0.4s;
  font-size: 14px;
  font-weight: 500;
  padding: 6px 20px;
  display: block;
}
.rs-blog.style2 .blog-item .blog-content {
  padding: 40px 24px 40px 42px;
  background: #ffffff;
  box-shadow: 0 6px 25px #eee;
}
.rs-blog.style2 .blog-item .blog-content .blog-meta {
  display: flex;
}
.rs-blog.style2 .blog-item .blog-content .blog-meta .admin {
  position: relative;
  margin-right: 20px;
  padding-right: 11px;
  font-size: 14px;
  font-weight: 500;
  color: #454545;
}
.rs-blog.style2 .blog-item .blog-content .blog-meta .admin i {
  margin-right: 5px;
  font-size: 14px;
  font-weight: 400;
  color: #bb0b0b;
}
.rs-blog.style2 .blog-item .blog-content .blog-meta .admin:after {
  content: "";
  height: 14px;
  width: 1px;
  position: absolute;
  right: 0;
  transform: rotate(20deg) translateY(-55%);
  top: 50%;
  background: #ccc;
}
.rs-blog.style2 .blog-item .blog-content .blog-meta .date {
  font-size: 14px;
  font-weight: 500;
}
.rs-blog.style2 .blog-item .blog-content .blog-meta .date i {
  margin-right: 5px;
  font-size: 14px;
  font-weight: 400;
  color: #bb0b0b;
}
.rs-blog.style2 .blog-item .blog-content .blog-title {
  font-size: 16px;
  line-height: 26px;
  font-weight: 600;
  margin: 0px 0px 18px 0px;
}
.rs-blog.style2 .blog-item .blog-content .blog-title a {
  color: #0a0a0a;
}
.rs-blog.style2 .blog-item .blog-content .blog-title a:hover {
    color: #0476c6;
}
.rs-blog.style2 .blog-item .blog-content p {
  margin: 0;
}
.rs-blog.style2 .blog-item:hover {
  transform: translateY(-10px);
}
.rs-blog.style2 .blog-item:hover .image-wrap {
  position: relative;
  overflow: hidden;
}
.rs-blog.style2 .blog-item:hover .image-wrap img {
  transform: scale(1.1);
}
.rs-blog.style2 .owl-stage-outer {
  padding: 20px;
  margin: -20px;
}
.rs-blog.style2.home3-blog-style .blog-item {
  box-shadow: 0 6px 25px rgba(12, 89, 219, 0.09);
  margin-top: 15px;
  padding: 15px;
  background: #fff;
}
.rs-blog.style2.home3-blog-style .blog-item .image-wrap img {
  transition: all .5s ease 0s;
}
.rs-blog.style2.home3-blog-style .blog-item .blog-content {
  padding: 25px 30px 30px 30px;
  box-shadow: unset;
}
.rs-blog.style2.home3-blog-style .blog-item .blog-content .blog-meta .admin {
  color: #454545;
}
.rs-blog.style2.home3-blog-style .blog-item .blog-content .blog-meta .admin i {
  color: #0082f1;
}
.rs-blog.style2.home3-blog-style .blog-item .blog-content .blog-meta .admin:after {
  display: none;
}
.rs-blog.style2.home3-blog-style .blog-item .blog-content .blog-meta .date i {
  color: #0082f1;
}
.rs-blog.style2.home3-blog-style .blog-item .blog-content .blog-title a {
  color: #0a0a0a;
}
.rs-blog.style2.home3-blog-style .blog-item .blog-content .blog-title a:hover {
  color: #0082f1;
}
.rs-blog.style2.home3-blog-style.home4-blog-style .blog-item .blog-content .blog-meta .date i,
.rs-blog.style2.home3-blog-style.home4-blog-style .blog-item .blog-content .blog-meta .admin i,
.rs-blog.style2.home3-blog-style.home4-blog-style .blog-item .blog-content .blog-title a:hover {
  color: #fd4a36;
}
.rs-blog.style2.home5-blog-style .blog-item .image-wrap .post-categories li a {
  border-radius: 4px 4px 4px 4px;
  background-color: transparent;
  background-image: linear-gradient(180deg, #4caf50 0%, #4caf50 100%);
}
.rs-blog.style2.home5-blog-style .blog-item .blog-content .blog-meta .date i,
.rs-blog.style2.home5-blog-style .blog-item .blog-content .blog-meta .admin i {
  color: #4caf50;
}
.rs-blog.style2.home5-blog-style .blog-item:hover .blog-content .blog-title a {
  color: #4caf50;
}
.rs-blog.style1 .owl-stage-outer {
  padding: 20px;
  margin: -20px;
}
.rs-blog.style1 .blog-wrap {
  background: #ffffff;
  box-shadow: 2px 4px 11px 0 rgba(0, 0, 0, 0.03);
  transition: all 0.3s ease;
}
.rs-blog.style1 .blog-wrap:hover {
  margin-top: -10px;
}
.rs-blog.style1 .blog-wrap .blog-img img {
  border-radius: 0 0 0 70px;
}
.rs-blog.style1 .blog-wrap .blog-contant {
  padding: 44px 38px 40px;
}
.rs-blog.style1 .blog-wrap .blog-contant .title {
  margin-bottom: 18px;
  font-size: 22px;
}
.rs-blog.style1 .blog-wrap .blog-contant .title a {
  color: #222;
}
.rs-blog.style1 .blog-wrap .blog-contant .title a:hover {
  color: #e94d65;
}
.rs-blog.style1 .blog-wrap .blog-contant .blog-meta {
  border-top: 1px solid #eee;
  padding-top: 22px;
  margin-top: 18px;
}
.rs-blog.style1 .blog-wrap .blog-contant .blog-meta ul {
  display: flex;
  align-items: center;
}
.rs-blog.style1 .blog-wrap .blog-contant .blog-meta ul li {
  margin-right: 35px;
}
.rs-blog.style1 .blog-wrap .blog-contant .blog-meta ul li .avatar {
  width: 22px;
  height: 22px;
  border-radius: 100%;
  display: inline;
  margin-right: 10px;
}
.rs-blog.style1 .blog-wrap .blog-contant .blog-meta ul li a {
  color: #454545;
}
.rs-blog.style1 .blog-wrap .blog-contant .blog-meta ul li a:hover {
  color: #e94d65;
}
.rs-blog.style1 .blog-wrap .blog-contant .blog-meta ul li i {
  font-size: 17px;
  margin-right: 10px;
}
.rs-blog.style1 .blog-wrap .blog-contant .blog-meta ul li:last-child {
  margin: 0;
}
.rs-inner-blog .blog-item {
  background: #fff;
  box-shadow: 0 2px 20px rgba(0, 0, 0, 0.08);
  padding: 15px;
  transition: 0.3s;
}
.rs-inner-blog .blog-item .blog-img {
  position: relative;
}
.rs-inner-blog .blog-item .blog-img .post-categories {
  position: absolute;
  z-index: 11;
  bottom: 20px;
  right: 20px;
  z-index: 10;
}
.rs-inner-blog .blog-item .blog-img .post-categories li {
  display: inline-block;
}
.rs-inner-blog .blog-item .blog-img .post-categories li a {
  background:#016ab3;
  color: #ffffff;
  transition: 0.4s;
  font-size: 14px;
  font-weight: 500;
  padding: 6px 20px;
  display: block;
}
.rs-inner-blog .blog-item .blog-img .post-categories li a:hover {
  background: #bb0b0b;
}
.rs-inner-blog .blog-item .blog-content {
  padding: 25px 15px 25px;
  overflow: hidden;
}
.rs-inner-blog .blog-item .blog-content .blog-title {
  margin: 4px 0 10px;
  font-size: 20px;
  line-height:26px;
  font-weight: 700;
}
.rs-inner-blog .blog-item .blog-content .blog-title a {
  color: #0a0a0a;
  display: -webkit-box;
    max-width: 400px;
    height:56.2px;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
}
.rs-inner-blog .blog-item .blog-content .blog-title a:hover {
  color:#016ab3;
}
.rs-inner-blog .blog-item .blog-content .blog-meta .btm-cate {
  overflow: hidden;
  margin: 0 0 5px;
  padding: 0 0 12px;
  font-size: 13px;
  display: flex;
  align-items: center;
}
.rs-inner-blog .blog-item .blog-content .blog-meta .btm-cate li {
  margin-right: 15px;
}
.rs-inner-blog .blog-item .blog-content .blog-meta .btm-cate li .blog-date {
  display: inline-block;
  font-weight: 400;
  font-size: 13px;
  color: #555;
}
.rs-inner-blog .blog-item .blog-content .blog-meta .btm-cate li .blog-date i {
  color:#52ac32;
  margin-right: 3px;
  font-size: 14px;
  font-weight: 600;
}
.rs-inner-blog .blog-item .blog-content .blog-meta .btm-cate li .author {
  display: inline-block;
  padding: 0;
  margin-right: 0;
  line-height: normal;
  color: #555;
}
.rs-inner-blog .blog-item .blog-content .blog-meta .btm-cate li .author i {
  color: #52ac32;
  margin-right: 3px;
  font-size: 14px;
  font-weight: 600;
}
.rs-inner-blog .blog-item .blog-content .blog-meta .btm-cate li:last-child {
  margin-right: 0;
}
.rs-inner-blog .blog-item .blog-content .blog-desc {
  font-size: 15px;
  line-height: 22px;
  font-weight: 400;
  color: #454545;
  margin-bottom: 20px;
  display: -webkit-box;
    max-width: 400px;
    height:66.2px;
    -webkit-line-clamp:3;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
}
.rs-inner-blog .widget-area .widget-title .title {
  font-size: 20px;
  line-height: 26px;
  font-weight: 600;
  color: #0a0a0a;
  position: relative;
  z-index: 1;
  padding-bottom: 12px;
  margin: 0;
}
.rs-inner-blog .widget-area .widget-title .title:after {
  content: "";
  position: absolute;
  border: 0;
  width: 50px;
  height: 2px;
  background: #bb0b0b;
  z-index: 1;
  margin-left: 0;
  bottom: 0;
  left: 0;
}
.rs-inner-blog .widget-area .search-widget {
  background: #fff;
  padding: 40px 30px 40px;
  box-shadow: 0 6px 25px rgba(0, 0, 0, 0.07);
}
.rs-inner-blog .widget-area .search-widget .search-wrap {
  position: relative;
}
.rs-inner-blog .widget-area .search-widget .search-wrap [type=search] {
  color: #444444;
  padding: 12px 17px;
  width: 100%;
  border: none;
  border-radius: 5px;
  position: relative;
  background: #F6F7F9;
}
.rs-inner-blog .widget-area .search-widget .search-wrap button {
  background: transparent;
  border: medium none;
  color: #454545;
  padding: 11px 15px 12px;
  position: absolute;
  display: block;
  right: 0px;
  top: 0;
  z-index: 10;
  font-size: 20px;
  border-radius: 0 5px 5px;
}
.rs-inner-blog .widget-area .search-widget .search-wrap button i:before {
  font-weight: 600;
  font-size: 18px;
}
.rs-inner-blog .widget-area .search-widget .search-wrap button:hover {
  color: #bb0b0b;
}
.rs-inner-blog .widget-area .recent-posts {
  background: #fff;
  padding: 40px 30px;
  box-shadow: 0 6px 25px rgba(0, 0, 0, 0.07);
}
.rs-inner-blog .widget-area .recent-posts .recent-post-widget {
  margin-top: 15px;
  padding-top: 15px;
  border-top: 1px solid rgba(0, 0, 0, 0.06);
  overflow: hidden;
}
.rs-inner-blog .widget-area .recent-posts .recent-post-widget .post-img {
  width: 100px;
  float: left;
  padding-right: 15px;
}
.rs-inner-blog .widget-area .recent-posts .recent-post-widget .post-img img {
  border-radius: 1px;
}
.rs-inner-blog .widget-area .recent-posts .recent-post-widget .post-desc {
  overflow: hidden;
  margin-top: -8px;
}
.rs-inner-blog .widget-area .recent-posts .recent-post-widget .post-desc a {
  font-size: 15px;
  line-height: 22px;
  font-weight: 600;
  color: #0a0a0a;
  margin-bottom: 5px;
}
.rs-inner-blog .widget-area .recent-posts .recent-post-widget .post-desc a:hover {
  color: #bb0b0b;
}
.rs-inner-blog .widget-area .recent-posts .recent-post-widget .post-desc .date-post {
  display: block;
  font-size: 13px;
  color: #555;
  font-weight: 500;
}
.rs-inner-blog .widget-area .recent-posts .recent-post-widget .post-desc .date-post i {
  margin-right: 5px;
}
.rs-inner-blog .widget-area .recent-posts .recent-post-widget .post-desc .date-post i:before {
  font-size: 12px;
  color: #bb0b0b;
}
.rs-inner-blog .widget-area .categories {
  background: #fff;
  padding: 40px 30px 40px;
  box-shadow: 0 2px 20px rgba(0, 0, 0, 0.08);
}
.rs-inner-blog .widget-area .categories li {
  margin-top: 13px;
  padding-top: 13px;
  border-top: 1px solid rgba(0, 0, 0, 0.06);
}
.rs-inner-blog .widget-area .categories li a {
  font-size: 15px;
  font-weight: 500;
  color: #0a0a0a;
}
.rs-inner-blog .widget-area .categories li a:hover {
  color: #bb0b0b;
}
.rs-inner-blog .widget-area .categories li:first-child {
  border-top: none;
}
.rs-inner-blog .blog-details .blog-full .single-post-meta {
  display: flex;
  align-items: center;
  padding: 0 0 30px;
}
.rs-inner-blog .blog-details .blog-full .single-post-meta li .p-date {
  color: #555;
  font-size: 14px;
  margin-right: 8px;
}
.rs-inner-blog .blog-details .blog-full .single-post-meta li .p-date i {
  font-size: 13px;
  margin-right: 3px;
  color: #51ad32;
}
.rs-inner-blog .blog-details .blog-full .single-post-meta li .p-date i:before {
  margin-right: 5px;
}
.rs-inner-blog .blog-details .blog-full .single-post-meta .Post-cate {
  list-style: none;
  display: inline-block;
  padding-right: 10px;
  color: #555;
  font-size: 14px;
}
.rs-inner-blog .blog-details .blog-full .single-post-meta .Post-cate .tag-line i {
  color: #51ad32;
  margin-right: 3px;
}
.rs-inner-blog .blog-details .blog-full .single-post-meta .Post-cate .tag-line i:before {
  margin-right: 4px;
}
.rs-inner-blog .blog-details .blog-full .single-post-meta .Post-cate .tag-line a {
  font-weight: 400;
  color: #555;
  font-size: 14px;
}
.rs-inner-blog .blog-details .blog-full .single-post-meta .Post-cate .tag-line a:hover {
  color: #bb0b0b;
}
.rs-inner-blog .blog-details .blog-full .single-post-meta .post-comment {
  color: #555;
  font-size: 14px;
}
.rs-inner-blog .blog-details .blog-full .single-post-meta .post-comment i{ color: #51ad32; }
.rs-inner-blog .blog-details .blog-full .single-post-meta .post-comment i:before {
  color: #51ad32;
}
.rs-inner-blog .blog-details .blog-full .comment-title {
  font-size: 24px;
  margin-bottom: 40px;
  margin-top: 35px;
}
.rs-inner-blog .blog-details .blog-full .comment-note .from-control {
  width: 100%;
  padding: 15px 20px;
  background-color: #F6F7F9;
  border: none;
  border-radius: 5px;
}
.rs-inner-blog .blog-details .blog-full .comment-note textarea {
  height: 160px;
}
.rs-inner-blog .blog-details .blog-full .comment-note ::-webkit-input-placeholder {
  /* Chrome/Opera/Safari */
  color: #777777;
  opacity: 1;
}
.rs-inner-blog .blog-details .blog-full .comment-note ::-moz-placeholder {
  /* Firefox 19+ */
  color: #777777;
  opacity: 1;
}
.rs-inner-blog .blog-details .blog-full .comment-note :-ms-input-placeholder {
  /* IE 10+ */
  color: #777777;
  opacity: 1;
}
.rs-inner-blog .blog-details .blog-full .comment-note :-moz-placeholder {
  /* Firefox 18- */
  color: #777777;
  opacity: 1;
}
/* ------------------------------------
    23. Testimonial Section CSS
---------------------------------------*/
.rs-testimonial.main-home .testi-image img {
  position: relative;
  max-width: 600px;
  right: 10%;
}
.rs-testimonial.main-home .testi-item .item-content .icon-img img {
  width: 75px;
}
.rs-testimonial.main-home .testi-item .item-content .desc {
  font-size: 20px;
  font-weight: 500;
  color: #ffffff;
  padding: 40px 0px 30px 0px;
}
.rs-testimonial.main-home .testi-item .testi-content {
  display: flex;
  align-items: center;
}
.rs-testimonial.main-home .testi-item .testi-content .image-wrap img {
  width: 80px;
  height: 80px;
  border-radius: 50px 50px 50px 50px;
  margin: 0 auto;
}
.rs-testimonial.main-home .testi-item .testi-content .testi-information {
  padding-left: 25px;
}
.rs-testimonial.main-home .testi-item .testi-content .testi-information .testi-name {
  font-size: 20px;
  line-height: 30px;
  font-weight: 500;
  color: #ffffff;
  padding-bottom: 5px;
  display: block;
}
.rs-testimonial.main-home .testi-item .testi-content .testi-information .testi-title {
  color: #878787;
}
.rs-testimonial.main-home .rs-carousel .owl-dots {
  text-align: left;
  margin-top: 45px;
}
.rs-testimonial.style2 .testi-wrap {
  background: /*url(assets/images/testimonial/test-bg.jpg)*/;
  background-position: top center;
  background-repeat: no-repeat;
  background-size: cover;
  padding: 30px 40px 60px 40px;
}
.rs-testimonial.style2 .testi-wrap .item-content span img {
  width: 48px;
}
.rs-testimonial.style2 .testi-wrap .item-content p {
  color: #FFFFFF;
  font-size: 15px;
  font-weight: 500;
  padding: 15px 0 10px;
}
.rs-testimonial.style2 .testi-wrap .testi-content {
  display: flex;
  justify-content: flex-start;
}
.rs-testimonial.style2 .testi-wrap .testi-content .image-wrap img {
  border-radius: 50px 50px 50px 50px;
  width: 80px;
  height: 80px;
}
.rs-testimonial.style2 .testi-wrap .testi-content .testi-information {
  padding: 0 0 0 25px;
  text-align: left;
}
.rs-testimonial.style2 .testi-wrap .testi-content .testi-information .testi-name {
  font-size: 20px;
  line-height: 30px;
  font-weight: 600;
  color: #ffffff;
}
.rs-testimonial.style2 .testi-wrap .testi-content .testi-information .testi-title {
  font-weight: 600;
  color: #878787;
  padding-bottom: 10px;
  display: block;
}
.rs-testimonial.style2 .testi-wrap .testi-content .testi-information .ratting-img img {
  width: 100px;
}
.rs-testimonial.style3 .testi-item {
  background-color: #EBEBEB;
  padding: 40px 20px 40px 20px;
  text-align: center;
}
.rs-testimonial.style3 .testi-item .testi-img img {
  width: 70px;
  height: 70px;
}
.rs-testimonial.style3 .testi-item p {
  color: #0a0a0a;
  font-size: 18px;
  line-height: 35px;
  margin-bottom: 0;
  padding: 19px 5px 20px 19px;
}
.rs-testimonial.style3 .testi-item .testi-content {
  display: flex;
  align-items: center;
  justify-content: center;
}
.rs-testimonial.style3 .testi-item .testi-content .testi-img {
  padding-right: 15px;
}
.rs-testimonial.style3 .testi-item .testi-content .testi-img img {
  width: 80px;
  height: 80px;
  border-radius: 50%;
}
.rs-testimonial.style3 .testi-item .testi-content .author-part {
  text-align: left;
}
.rs-testimonial.style3 .testi-item .testi-content .author-part .name {
  font-size: 16px;
  font-weight: 600;
  line-height: 35px;
  color: #0a0a0a;
  letter-spacing: 1px;
  margin-bottom: 0;
  display: block;
}
.rs-testimonial.style3 .testi-item .testi-content .author-part .designation {
  font-size: 14px;
  line-height: 24px;
  color: #0a0a0a;
  display: block;
}
.rs-testimonial.style4 .testi-item .testi-wrap {
  padding: 40px 40px 40px 40px;
  background-color: #0082F136;
  text-align: center;
  margin: 0 -13px 0 0;
}
.rs-testimonial.style4 .testi-item .testi-wrap .image-wrap {
  justify-content: center;
  display: flex;
  flex-wrap: nowrap;
}
.rs-testimonial.style4 .testi-item .testi-wrap .image-wrap img {
  width: 85px;
  height: 85px;
  border-radius: 50%;
}
.rs-testimonial.style4 .testi-item .testi-wrap .item-contents p {
  color: #ffffff;
  font-size: 15px;
  font-style: italic;
  padding: 30px 0px 0px 0px;
  margin-bottom: 20px;
}
.rs-testimonial.style4 .testi-item .testi-wrap .testi-information .testi-name {
  font-size: 17px;
  font-weight: 600;
  color: #ffffff;
}
.rs-testimonial.style4 .testi-item .testi-wrap .testi-information .testi-title {
  color: #ffffff;
}
.rs-testimonial.style4 .testimonial-overly-bg {
  background-color: #FFFFFF61;
}
.rs-testimonial.style4.home4-testi-style .testi-item .testi-wrap {
  background-color: #FFFFFFB0;
}
.rs-testimonial.style4.home4-testi-style .testi-item .testi-wrap .testi-information .testi-title,
.rs-testimonial.style4.home4-testi-style .testi-item .testi-wrap .testi-information .testi-name,
.rs-testimonial.style4.home4-testi-style .testi-item .testi-wrap .item-contents p {
  color: #333333;
}
.rs-testimonial.home5-testi-style .testi-box-style {
  background: #ffffff;
  box-shadow: 0 6px 25px #eee;
  padding: 20px 43px 57px 58px;
}
.rs-testimonial.home5-testi-style .testi-box-style .testi-item .item-content .icon-img {
  top: 36px;
  position: absolute;
  left: 0;
}
.rs-testimonial.home5-testi-style .testi-box-style .testi-item .item-content .icon-img img {
  width: 75px;
}
.rs-testimonial.home5-testi-style .testi-box-style .testi-item .item-content .desc {
  color: #333333;
  font-size: 17px;
  font-weight: 500;
  font-style: italic;
  padding: 124px 40px 15px 0;
}
.rs-testimonial.home5-testi-style .testi-box-style .testi-item .testi-content {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}
.rs-testimonial.home5-testi-style .testi-box-style .testi-item .testi-content .image-wrap img {
  width: 80px;
  height: 80px;
  border-radius: 50px 50px 50px 50px;
  position: relative;
}
.rs-testimonial.home5-testi-style .testi-box-style .testi-item .testi-content .testi-information {
  padding-left: 25px;
}
.rs-testimonial.home5-testi-style .testi-box-style .testi-item .testi-content .testi-information .testi-name {
  font-size: 20px;
  font-weight: 500;
  color: #333333;
}
.rs-testimonial.home5-testi-style .testi-box-style .testi-item .testi-content .testi-information .testi-title {
  color: #878787;
}
.rs-testimonial.home5-testi-style .testi-box-style .rs-carousel .owl-dots {
  margin-top: 40px;
  text-align: left;
}
.rs-testimonial.style1 .left-radius {
  border-radius: 100px 0 0 0;
}
.rs-testimonial.style1 .item .testi-img {
  margin-bottom: 30px;
}
.rs-testimonial.style1 .item .testi-img img {
  max-width: 80px;
  margin: 0 auto;
}
.rs-testimonial.style1 .item .rating {
  margin-bottom: 13px;
}
.rs-testimonial.style1 .item .rating li {
  display: inline;
  margin-right: 5px;
}
.rs-testimonial.style1 .item .rating li i {
  color: #e94d65;
  font-size: 26px;
}
.rs-testimonial.style1 .item .rating li:last-child {
  margin: 0;
}
.rs-testimonial.style1 .item .author-detail .desc {
  color: #f9f9f9;
  max-width: 90%;
  margin: 0 auto 18px;
}
.rs-testimonial.style1 .item .author-detail .name {
  color: #ffffff;
  font-weight: 700;
  line-height: 1;
  margin-bottom: 3px;
}
.rs-testimonial.style1 .item .author-detail .designation {
  color: #f9f9f9;
  font-size: 14px;
}
.rs-testimonial.style1 .center .item {
  border-right: 1px solid;
  border-left: 1px solid;
  border-color: #1d507f;
}
.rs-testimonial.style1 .pattern-img .common {
  position: absolute;
  content: '';
}
.rs-testimonial.style1 .pattern-img .common.img1 {
  top: 93px;
  left: 200px;
  opacity: 0.1;
  max-width: 190px;
  animation: rotate-anim 30s infinite linear;
  -webkit-animation: rotate-anim 30s infinite linear;
  -moz-animation: rotate-anim 30s infinite linear;
}
.rs-testimonial.style1 .pattern-img .common.img2 {
  bottom: 0;
  left: 0;
}
.rs-testimonial.style1 .pattern-img .common.img3 {
  top: 0;
  right: 0;
  max-width: 330px;
  margin: 0 auto 0 0;
}
/*-----------------------------------------------
    #. Partner Section Css
-----------------------------------------------*/
.rs-partner {
  /* Owl Carousel Nav */
}
.rs-partner .item {
  text-align: center;
}
.rs-partner .item a {
  display: inline-block;
}
.rs-partner .item a img {
  max-width: 150px;
  margin: 0 auto;
  height: auto;
}
.rs-partner .owl-carousel {
  padding-top: 0;
}
.rs-partner .owl-carousel .owl-nav [class*="owl-"] {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  background: #043d72;
  color: #ffffff;
  width: 40px;
  height: 40px;
  line-height: 40px;
  border-radius: 50%;
  display: inline-block;
  text-align: center;
  opacity: 0 !important;
  visibility: hidden !important;
  transition: all 0.3s ease;
  outline: none;
  border: none;
}
.rs-partner .owl-carousel .owl-nav [class*="owl-"] i {
  font-size: 0;
  position: relative;
}
.rs-partner .owl-carousel .owl-nav [class*="owl-"].owl-prev {
  left: 15px;
}
.rs-partner .owl-carousel .owl-nav [class*="owl-"].owl-next {
  right: 15px;
}
.rs-partner .owl-carousel .owl-nav [class*="owl-"]:hover {
  background-color: #e94d65;
}
.rs-partner .owl-carousel:hover [class*="owl-"] {
  opacity: 1 !important;
  visibility: visible !important;
}
.rs-partner .owl-carousel:hover [class*="owl-"] .owl-prev {
  left: -50px;
}
.rs-partner .owl-carousel:hover [class*="owl-"] .owl-next {
  right: -50px;
}
/*-----------------------------------------------
    #. Achievement Section Css
-----------------------------------------------*/
.rs-achievement.style1 .img-part {
  position: relative;
  right: -100px;
}
.rs-achievement.style1 .img-part img {
  border-radius: 50px 0 0 0;
}
.rs-achievement.style1 .rs-counter {
  display: flex;
  justify-content: center;
  border-top: 1px solid #e8e8e8;
}
.rs-achievement.style1 .rs-counter .rs-counter-list {
  text-align: center;
  width: 100%;
  transition: all 0.3s ease;
  padding: 40px 0 35px;
}
.rs-achievement.style1 .rs-counter .rs-counter-list .counter-icon {
  margin-bottom: 25px;
}
.rs-achievement.style1 .rs-counter .rs-counter-list .counter-icon i {
  height: 46.3px;
  line-height: 46.3px;
  display: block;
  color: #e94d65;
}
.rs-achievement.style1 .rs-counter .rs-counter-list .counter-icon i:before {
  font-size: 48px;
}
.rs-achievement.style1 .rs-counter .rs-counter-list .counter-icon i.flaticon-group:before {
  font-size: 65px;
}
.rs-achievement.style1 .rs-counter .rs-counter-list .counter-icon i.flaticon-briefing:before {
  font-size: 46px;
}
.rs-achievement.style1 .rs-counter .rs-counter-list .counter-number {
  font-size: 40px;
  line-height: 1;
  color: #222222;
  font-weight: 700;
  margin-bottom: 8px;
}
.rs-achievement.style1 .rs-counter .rs-counter-list .counter-number:after {
  content: "+";
}
.rs-achievement.style1 .rs-counter .rs-counter-list .counter-text {
  font-weight: 500;
  text-transform: capitalize;
}
.rs-achievement.style1 .rs-counter:hover .rs-counter-list:hover,
.rs-achievement.style1 .rs-counter:not(:hover) .active {
  box-shadow: 0 6px 30px 0 rgba(0, 0, 0, 0.08);
}
.rs-achievement.style1 .pattern-img {
  position: absolute;
  content: '';
  right: 0;
  top: 141px;
  z-index: -1;
}
/*-----------------------------------------------
    #. Estimate Section Css
-----------------------------------------------*/
.rs-estimate .image-part {
  position: relative;
  left: -70px;
}
.rs-estimate .estimate-info li {
  position: relative;
  padding-left: 30px;
  margin-bottom: 35px;
}
.rs-estimate .estimate-info li .title {
  font-weight: 800;
  color: #222;
}
.rs-estimate .estimate-info li:before {
  position: absolute;
  content: '';
  left: 0;
  top: 10px;
  width: 10px;
  height: 10px;
  border-radius: 100%;
  background: #043d72;
}
.rs-estimate .estimate-info li:last-child {
  margin: 0;
}
.rs-estimate .pattern-img {
  position: absolute;
  content: '';
  left: 50px;
  top: 100px;
  z-index: -1;
}
/*-----------------------------------------------
    #. Call to Action Section Css
-----------------------------------------------*/
.rs-cta.style1 {
  overflow: hidden;
}
.rs-cta.style1 .pattern-right {
  position: absolute;
  content: '';
  right: 0;
  top: 50%;
  transform: translateY(-50%);
}
.rs-cta.style1 .pattern-left {
  position: absolute;
  content: '';
  left: 20px;
  bottom: 0;
}
.rs-cta .sec-title4,
.rs-cta .readon2 {
  position: relative;
  z-index: 1;
}
/*----------------------------------------
    24. Shop Section CSS
----------------------------------------*/
.rs-shop-part .woocommerce-result-count {
  font-size: 15px;
  line-height: 26px;
  color: #454545;
  font-weight: 400;
  margin: 0;
}
.rs-shop-part .from-control {
  float: right;
  font-size: 15px;
  color: #454545;
  font-weight: 400;
  vertical-align: top;
  padding: 10px;
  border: 1px solid #ced4da;
  border-radius: 5px;
}
.rs-shop-part .product-list .image-product {
  position: relative;
}
.rs-shop-part .product-list .image-product .overley i {
  position: absolute;
  bottom: 150px;
  right: 13px;
  background: transparent;
  opacity: 0;
  visibility: hidden;
  transition: 0.4s;
}
.rs-shop-part .product-list .image-product .overley i:before {
  font-size: 30px;
  color: #ffffff;
  font-weight: 400;
  background: #bb0b0b;
  border: none ;
  padding: 8px 10px 8px;
  height: 45px;
  width: 45px;
  line-height: 45px;
  border-radius: 5px;
}
.rs-shop-part .product-list .image-product .onsale {
  font-size: 13px;
  color: #ffffff;
  font-weight: 700;
  line-height: 40px;
  background: #bb0b0b;
  width: 40px;
  height: 40px;
  text-align: center;
  border-radius: 50%;
  display: inline-block;
  position: absolute;
  top: 13px;
  right: 13px;
}
.rs-shop-part .product-list .content-desc .loop-product-title {
  font-size: 18px;
  line-height: 28px;
  font-weight: 700;
  margin-bottom: 10px;
  padding-top: 30px;
}
.rs-shop-part .product-list .content-desc .loop-product-title a {
  color: #0a0a0a;
}
.rs-shop-part .product-list .content-desc .loop-product-title a:hover {
  color: #bb0b0b;
}
.rs-shop-part .product-list .content-desc .price {
  font-size: 16px;
  line-height: 27px;
  color: #bb0b0b;
  font-weight: 600;
  margin: 0;
}
.rs-shop-part .product-list .content-desc .price del {
  opacity: 0.6;
  padding-right: 10px;
}
.rs-shop-part .product-list:hover .image-product .overley i {
  opacity: 1;
  visibility: visible;
  bottom: 13px;
}
/*----------------------------------------
    25. Single Shop Section CSS
----------------------------------------*/
.rs-single-shop .single-product-image .images-single {
  z-index: 1 !important;
}
.rs-single-shop .single-product-image img {
  width: 100%;
}
.rs-single-shop .single-price-info .product-title {
  font-size: 25px;
  font-weight: 700;
  margin-bottom: 12px;
}
.rs-single-shop .single-price-info .single-price {
  color: #bb0b0b;
  font-weight: 600;
}
.rs-single-shop .single-price-info .some-text {
  margin-top: 15px;
}
.rs-single-shop .single-price-info form {
  margin-bottom: 30px;
}
.rs-single-shop .single-price-info form input {
  height: 40px;
  width: 70px;
  line-height: 40px;
  text-align: center;
  padding-left: 10px;
  border: 1px solid rgba(54, 54, 54, 0.1);
  outline: none;
}
.rs-single-shop .single-price-info p.category {
  margin: 0;
  padding-top: 25px;
  border-top: 1px solid #e6e6e6;
  font-size: 14px;
}
.rs-single-shop .single-price-info p.category span {
  font-weight: 700;
  padding-right: 10px;
}
.rs-single-shop .single-price-info p.category a {
  color: #454545;
}
.rs-single-shop .single-price-info p.category a:hover {
  color: #bb0b0b;
}
.rs-single-shop .single-price-info .tag {
  margin: 0;
}
.rs-single-shop .single-price-info .tag span {
  font-weight: 700;
}
.rs-single-shop .tab-area {
  margin-top: 50px;
}
.rs-single-shop .tab-area ul.nav-tabs {
  border-bottom: 1px solid #f0f0f0;
  margin-bottom: 32px;
}
.rs-single-shop .tab-area ul.nav-tabs li {
  margin-right: 10px;
}
.rs-single-shop .tab-area ul.nav-tabs li .nav-link {
  padding: 20px 45px;
  display: inline-block;
  border-radius: 8px 8px 0 0 ;
  font-size: 18px;
  background: #fbfbfb;
  border: 1px solid #fbfbfb;
  background-color: #fbfbfb;
  color: #0a0a0a;
  font-weight: 400;
}
.rs-single-shop .tab-area ul.nav-tabs li .nav-link:hover,
.rs-single-shop .tab-area ul.nav-tabs li .nav-link.active {
  background:#0069b3;
  color: #ffffff;
}
.rs-single-shop .tab-area ul.nav-tabs li:last-child {
  margin-right: 0;
}
.rs-single-shop .tab-area .tab-content .tab-pane .tab-title {
  font-weight: 700;
  margin-bottom: 34px;
}
.rs-single-shop .tab-area .tab-content .tab-pane .dsc-p {
  margin: 0;
}
.rs-single-shop .tab-area .tab-content .tab-pane .reviews-grid .top-area {
  margin-bottom: 5px;
}
.rs-single-shop .tab-area .tab-content .tab-pane .reviews-grid .top-area p {
  margin-bottom: 23px;
}
.rs-single-shop .tab-area .tab-content .tab-pane .reviews-grid .top-area h6 {
  font-size: 15px;
  font-weight: 600;
  color: #454545;
}
.rs-single-shop .tab-area .tab-content .tab-pane .reviews-grid .comment-form {
  max-width: 600px;
}
.rs-single-shop .tab-area .tab-content .tab-pane .reviews-grid .comment-form p.comment-notes {
  margin-bottom: 15px;
}
.rs-single-shop .tab-area .tab-content .tab-pane .reviews-grid .comment-form .placeholder-cntrl label {
  font-weight: 600;
  display: block;
}
.rs-single-shop .tab-area .tab-content .tab-pane .reviews-grid .comment-form .placeholder-cntrl input {
  border: 1px solid rgba(54, 54, 54, 0.1);
  padding: 10px 15px;
  margin-bottom: 10px;
  width: 100%;
}
.rs-single-shop .tab-area .tab-content .tab-pane .reviews-grid .comment-form .placeholder-cntrl textarea {
  width: 100%;
  padding: 10px 15px;
  height: 75px;
  border: 1px solid rgba(54, 54, 54, 0.1);
  margin-bottom: 20px;
}
.rs-single-shop .tab-area .tab-content .tab-pane .reviews-grid .comment-form .ratings {
  font-weight: 600;
  margin-bottom: 18px;
  display: block;
}
.rs-single-shop .tab-area .tab-content .tab-pane .reviews-grid .comment-form .ratings ul li {
  display: inline;
}
.rs-single-shop .tab-area .tab-content .tab-pane .reviews-grid .comment-form .ratings ul li i {
  color: #bb0b0b;
  font-weight: normal;
}
/*----------------------------------------
    26. Cart Section CSS
----------------------------------------*/
.rs-cart .cart-wrap table.cart-table {
  border: 1px solid rgba(0, 0, 0, 0.1);
  width: 100%;
  border-collapse: collapse;
}
.rs-cart .cart-wrap table.cart-table td,
.rs-cart .cart-wrap table.cart-table th {
  padding: 25px;
  text-align: center;
  border: 1px solid #ccc;
}
.rs-cart .cart-wrap table.cart-table th {
  border: none;
  font-size: 18px;
  padding: 25px;
  text-align: center;
  vertical-align: middle;
  font-weight: 700;
}
.rs-cart .cart-wrap table.cart-table td {
  border-bottom: none;
  border-left: none;
  border-right: none;
  border-top: 1px solid #e6e6e6;
}
.rs-cart .cart-wrap table.cart-table .product-remove a {
  margin: 0 auto;
  color: #0a0a0a;
  display: block;
  border-radius: 100%;
  border: 1px solid #ddd;
  font-size: 16px;
  font-weight: 400;
  height: 20px;
  width: 20px;
  line-height: 17px;
  text-align: center;
}
.rs-cart .cart-wrap table.cart-table .product-remove a:hover {
  background: #ff0000;
  color: #ffffff;
}
.rs-cart .cart-wrap table.cart-table .product-thumbnail {
  min-width: 32px;
}
.rs-cart .cart-wrap table.cart-table .product-thumbnail a img {
  width: 80px;
  height: auto;
}
.rs-cart .cart-wrap table.cart-table .product-name a {
  color: #454545;
  font-weight: 700;
}
.rs-cart .cart-wrap table.cart-table .product-name a:hover {
  color: #bb0b0b;
}
.rs-cart .cart-wrap table.cart-table .product-price {
  font-weight: 700;
}
.rs-cart .cart-wrap table.cart-table .product-quantity input {
  border: 1px solid #e6e6e6;
  border-radius: 4px;
  padding: 0 0 0 10px;
  max-width: 65px;
  margin: 0 auto;
  outline: none;
}
.rs-cart .cart-wrap table.cart-table .action .coupon {
  float: left;
}
.rs-cart .cart-wrap table.cart-table .action .coupon input {
  box-sizing: border-box;
  border: 1px solid #d3ced2;
  padding: 6px 6px 5px;
  margin: 0 4px 0 0;
  outline: none;
  width: 320px;
  border-radius: 4px;
  height: 45px;
  margin-right: 20px;
  text-align: left;
  padding-left: 22px;
}
.rs-cart .cart-collaterals {
  width: 100%;
  overflow: hidden;
}
.rs-cart .cart-collaterals .cart-totals {
  float: right;
  width: 48%;
}
.rs-cart .cart-collaterals .cart-totals .title {
  font-weight: 700;
  color: #454545;
  text-transform: capitalize;
}
.rs-cart .cart-collaterals .cart-totals table.cart-total-table {
  text-align: left;
  width: 100%;
  border-collapse: collapse;
  border-radius: 5px;
}
.rs-cart .cart-collaterals .cart-totals table.cart-total-table tr {
  border-top: 1px solid rgba(0, 0, 0, 0.1);
}
.rs-cart .cart-collaterals .cart-totals table.cart-total-table tr th {
  font-size: 18px;
  font-weight: 700;
}
.rs-cart .cart-collaterals .cart-totals table.cart-total-table tr td,
.rs-cart .cart-collaterals .cart-totals table.cart-total-table tr th {
  padding: 30px 10px;
}
/*-----------------------------------------------
    27. Woocommerce Section CSS
-----------------------------------------------*/
.woocommerce-mini-cart {
  line-height: 20px;
  background-color: #fff;
  position: absolute;
  right: 13.3%;
  font-size: 14px;
  top: 100%;
  width: 278px;
  z-index: 10000;
  transition: all .2s ease 0s;
  box-shadow: 0 0 15px #eee;
  border-radius: 3px;
  opacity: 0;
  visibility: hidden;
  -webkit-transform: scaleY(0);
  transform: scaleY(0);
  -webkit-transform-origin: 0 0 0;
}
.woocommerce-mini-cart .cart-bottom-part {
  padding: 25px 10px 0;
}
.woocommerce-mini-cart .cart-bottom-part .widget-title {
  text-align: center;
  text-transform: uppercase;
  position: relative;
  padding-top: 50px;
  color: #0a0a0a;
  font-size: 14px;
  line-height: 20px;
  font-weight: 400;
}
.woocommerce-mini-cart .cart-bottom-part .widget-title:before {
  position: absolute;
  content: "\f124";
  color: #161616;
  left: 50%;
  transform: translateX(-50%);
  bottom: 42px;
  font: normal normal normal 14px/1 FontAwesome;
  font-size: 30px;
  font-style: normal;
}
.mini-cart-active {
  cursor: pointer;
}
.mini-cart-active:hover .woocommerce-mini-cart {
  visibility: visible;
  opacity: 1;
  -webkit-transform: scaleY(1);
  transform: scaleY(1);
}
/*----------------------------------------
    28. Checkout Section CSS
----------------------------------------*/
.rs-checkout .checkout-title {
  margin-bottom: 30px;
}
.rs-checkout .checkout-title h3 {
  font-size: 26px;
  margin: 0;
}
.rs-checkout .coupon-toggle .accordion .card {
  border: unset;
  border-top: 3px solid #bb0b0b;
  border-radius: 0;
}
.rs-checkout .coupon-toggle .accordion .card .card-header {
  border: none;
  margin: 0;
  border-radius: unset;
}
.rs-checkout .coupon-toggle .accordion .card .card-header .card-title {
  margin: 0;
}
.rs-checkout .coupon-toggle .accordion .card .card-header .card-title span i {
  margin-right: 10px;
}
.rs-checkout .coupon-toggle .accordion .card .card-header .card-title button {
  background: unset;
  border: none;
  color: #bb0b0b;
  transition: all 0.3s ease;
  outline: none;
  cursor: pointer;
}
.rs-checkout .coupon-toggle .accordion .card .card-header .card-title button:hover {
  color: #bb0b0b;
}
.rs-checkout .coupon-toggle .accordion .card .card-body {
  border: 1px solid #d3ced2;
  padding: 20px 20px 50px 20px;
  margin-top: 2em;
  text-align: left;
}
.rs-checkout .coupon-toggle .accordion .card .card-body .coupon-code-input {
  width: 47%;
  float: left;
  margin-right: 50px;
}
.rs-checkout .coupon-toggle .accordion .card .card-body .coupon-code-input input {
  width: 100%;
  height: 45px;
  outline: none;
  padding: 10px 18px;
  color: #454545;
  border: 1px solid rgba(54, 54, 54, 0.1);
}
.rs-checkout .full-grid {
  margin-top: 25px;
}
.rs-checkout .full-grid .form-content-box {
  margin-bottom: 50px;
}
.rs-checkout .full-grid .form-content-box .form-group label {
  line-height: 2;
  display: block;
  margin-bottom: 5px;
  font-weight: 600;
}
.rs-checkout .full-grid .form-content-box .form-group select {
  color: #666666;
  opacity: .5;
  padding-left: 8px;
  padding-right: 20px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  border: 1px solid #ccc;
  border-radius: 0;
  height: 45px;
  line-height: 45px;
  cursor: pointer;
  width: 100%;
  outline: none;
}
.rs-checkout .full-grid .form-content-box .form-group textarea {
  height: 4em;
  line-height: 1.5;
  display: block;
  box-shadow: none;
  width: 100%;
  border: 1px solid rgba(54, 54, 54, 0.1);
  padding: 10px 18px;
  margin: 0;
  outline: none;
}
.rs-checkout .full-grid .form-content-box .form-group .form-control-mod {
  border: 1px solid rgba(54, 54, 54, 0.1);
  height: 45px;
  padding: 10px 18px;
  width: 100%;
  margin: 0;
  outline: none;
  line-height: normal;
  border-radius: unset;
}
.rs-checkout .full-grid .form-content-box .form-group .form-control-mod.margin-bottom {
  margin-bottom: 10px !important;
}
.rs-checkout .full-grid .ordered-product table {
  width: 100%;
}
.rs-checkout .full-grid .ordered-product table tr th {
  border: 1px solid #ccc;
  padding: 9px 12px;
}
.rs-checkout .full-grid .ordered-product table tr td {
  border: 1px solid #ccc;
  padding: 6px 12px;
}
.rs-checkout .full-grid .payment-method .top-area {
  border-bottom: 1px solid #d3ced2;
}
.rs-checkout .full-grid .payment-method .top-area .payment-co {
  margin-bottom: 20px;
}
.rs-checkout .full-grid .payment-method .top-area .payment-co span {
  font-weight: 600;
  margin-right: 10px;
}
.rs-checkout .full-grid .payment-method .top-area .p-msg {
  position: relative;
  box-sizing: border-box;
  width: 100%;
  padding: 1em;
  margin: 1em 0 2em;
  font-size: .92em;
  border-radius: 2px;
  line-height: 1.5;
  background-color: #f9f9f9;
}
.rs-checkout .full-grid .payment-method .top-area .p-msg:before {
  content: '';
  display: block;
  border: 1em solid #f9f9f9;
  border-right-color: transparent;
  border-left-color: transparent;
  border-top-color: transparent;
  position: absolute;
  top: -0.75em;
  left: 0;
  margin: -1em 0 0 2em;
}
/*----------------------------------------
    29. Account Section CSS
----------------------------------------*/
.rs-my-account .title {
  font-size: 36px;
  line-height: 46px;
  font-weight: 700;
  color: #0a0a0a;
  margin: 0;
}
.rs-my-account .rs-login {
  border: 1px solid rgba(54, 54, 54, 0.1);
  padding: 20px;
  text-align: left;
  border-radius: 5px;
}
.rs-my-account .rs-login .form-group label {
  display: block;
  font-size: 15px;
  line-height: 30px;
  font-weight: 600;
  color: #454545;
}
.rs-my-account .rs-login .form-group label span {
  color: #bb0b0b;
}
.rs-my-account .rs-login .form-group input {
  width: 100%;
  margin: 0;
  outline: 0;
  line-height: normal;
  border: 1px solid rgba(54, 54, 54, 0.1);
  height: 45px;
}
.rs-my-account .rs-login .last-password a {
  font-size: 15px;
  line-height: 26px;
  font-weight: 400;
  color: #bb0b0b;
}
.rs-my-account .rs-login p {
  font-size: 15px;
  line-height: 26px;
  font-weight: 400;
  color: #454545;
  margin-bottom: 14px;
}
.rs-my-account .rs-login p a {
  font-size: 15px;
  line-height: 26px;
  font-weight: 400;
  color: #bb0b0b;
}
/* ------------------------------------
    30. Contact Section CSS
---------------------------------------*/
.rs-contact.main-home .contact-wrap .from-control {
  padding: 17px 17px 17px 17px;
  color: #FFFFFF;
  border-style: solid;
  border-width: 1px 1px 1px 1px;
  border-color: #0D0D0D;
  background-color: #0D0D0D;
  width: 100%;
  max-width: 100%;
  opacity: 1;
}
.rs-contact.main-home .contact-wrap textarea {
  height: 120px;
}
.rs-contact.main-home .contact-wrap ::-webkit-input-placeholder {
  /* Chrome/Opera/Safari */
  color: #fff;
  opacity: 1;
}
.rs-contact.main-home .contact-wrap ::-moz-placeholder {
  /* Firefox 19+ */
  color: #fff;
  opacity: 1;
}
.rs-contact.main-home .contact-wrap :-ms-input-placeholder {
  /* IE 10+ */
  color: #fff;
  opacity: 1;
}
.rs-contact.main-home .contact-wrap :-moz-placeholder {
  /* Firefox 18- */
  color: #fff;
  opacity: 1;
}
.rs-contact.main-home .contact-wrap input[type=submit]:hover {
  opacity: .9;
}
.rs-contact.main-home .contact-map {
  overflow: hidden;
}
.rs-contact.main-home .contact-map iframe {
  float: left;
  width: 100%;
  height: 400px;
  border: none;
}
.rs-contact.main-home .contact-icons-style .contact-item {
  display: flex;
  padding: 30px 30px 30px 30px;
  margin: 0 -5px 0 0;
  background-image: url(assets/images/contact/con-box.png);
}
.rs-contact.main-home .contact-icons-style .contact-item .contact-icon img {
  max-width: unset;
  width: 50px;
  height: 50px;
  padding: 5px 5px 0px 0px;
}
.rs-contact.main-home .contact-icons-style .contact-item .content-text {
  padding: 0px 0px 0px 15px;
}
.rs-contact.main-home .contact-icons-style .contact-item .content-text .title {
  font-size: 20px;
  font-weight: 600;
  line-height: 38px;
  margin: 0 0 10px;
}
.rs-contact.main-home .contact-icons-style .contact-item .content-text .title a {
  color: #ffffff;
}
.rs-contact.main-home .contact-icons-style .contact-item .content-text .title a:hover {
  color: #bb0b0b;
}
.rs-contact.main-home .contact-icons-style .contact-item .content-text a {
  color: #C5C2C2;
}
.rs-contact.main-home .contact-icons-style .contact-item .content-text a:hover {
  color: #bb0b0b;
}
.rs-contact.main-home .contact-icons-style .contact-item .content-text .services-txt {
  margin: 0;
  color: #C5C2C2;
}
.rs-contact.main-home .contact-icons-style.box-address .contact-item {
  background-image: url(assets/images/contact/box-bg2.png);
}
.rs-contact.main-home .contact-icons-style.box-address .contact-item .content-text .title a {
  color: #0a0a0a;
}
.rs-contact.main-home .contact-icons-style.box-address .contact-item .content-text .title a:hover {
  color: #bb0b0b;
}
.rs-contact.main-home .contact-icons-style.box-address .contact-item .content-text a {
  color: #444444;
}
.rs-contact.main-home .contact-icons-style.box-address .contact-item .content-text a:hover {
  color: #bb0b0b;
}
.rs-contact.main-home .contact-icons-style.box-address .contact-item .content-text .services-txt {
  color: #444444;
}
.rs-contact.main-home .contact-icons-style.box-address.box-style2 .contact-item {
  background-image: url(assets/images/contact/box-bg3.png);
}
.rs-contact.main-home.office-modify1 .contact-map iframe {
  height: 761px;
}
.rs-contact.main-home.office-modify1 .contact-section {
  background-color: #ebebeb;
  padding: 100px 80px 121px;
}
.rs-contact.main-home.office-modify1 .contact-section .contact-wrap .from-control {
  color: #000000;
  border-style: solid;
  border-color: #FFFFFF;
  background-color: #fff;
}
.rs-contact.main-home.office-modify1 .contact-section .contact-wrap textarea {
  height: 120px;
}
.rs-contact.main-home.office-modify1 .contact-section .contact-wrap ::-webkit-input-placeholder {
  /* Chrome/Opera/Safari */
  color: #454545;
  opacity: 0.55;
}
.rs-contact.main-home.office-modify1 .contact-section .contact-wrap ::-moz-placeholder {
  /* Firefox 19+ */
  color: #454545;
  opacity: 0.55;
}
.rs-contact.main-home.office-modify1 .contact-section .contact-wrap :-ms-input-placeholder {
  /* IE 10+ */
  color: #454545;
  opacity: 1;
}
.rs-contact.main-home.office-modify1 .contact-section .contact-wrap :-moz-placeholder {
  /* Firefox 18- */
  color: #454545;
  opacity: 0.55;
}
.rs-contact.main-home.office-modify1 .contact-section.contact-style2 {
  padding: 90px 80px 100px;
}
.rs-contact.main-home.office-modify1 .contact-box {
  padding: 35px 35px 35px 35px;
  background-color: #ebebeb;
  margin: 0 -4px 0 0;
}
.rs-contact.main-home.office-modify1 .contact-box .contact-icon img {
  width: 64px;
  height: 64px;
  max-width: unset;
  margin-bottom: 20px;
}
.rs-contact.main-home.office-modify1 .contact-box .content-text .title {
  font-size: 20px;
  font-weight: 600;
  line-height: 38px;
  margin: 0 0 10px;
}
.rs-contact.main-home.office-modify1 .contact-box .content-text .title a {
  color: #14171C;
}
.rs-contact.main-home.office-modify1 .contact-box .content-text .title a:hover {
  color: #bb0b0b;
}
.rs-contact.main-home.office-modify1 .contact-box .content-text a {
  color: #444444;
  font-weight: 500;
}
.rs-contact.main-home.office-modify1 .contact-box .content-text a:hover {
  color: #bb0b0b;
}
.rs-contact.main-home.office-modify1 .contact-box .content-text .services-txt {
  margin: 0;
  color: #444444;
  font-weight: 500;
}
.rs-contact.main-home.office-modify1 .office-buliding {
  background: url(assets/images/contact/office.jpg);
  background-repeat: no-repeat;
  background-size: cover;
}
.rs-contact.main-home.home5-contact-style .contact-wrap .from-control {
  color: #0a0a0a;
  border-color: #F1F1F1;
  background-color: #F1F1F1;
}
.rs-contact.main-home.home5-contact-style ::-webkit-input-placeholder {
  /* Chrome/Opera/Safari */
  color: #454545;
  opacity: 0.4;
}
.rs-contact.main-home.home5-contact-style ::-moz-placeholder {
  /* Firefox 19+ */
  color: #454545;
  opacity: 0.4;
}
.rs-contact.main-home.home5-contact-style :-ms-input-placeholder {
  /* IE 10+ */
  color: #454545;
  opacity: 0.4;
}
.rs-contact.main-home.home5-contact-style :-moz-placeholder {
  /* Firefox 18- */
  color: #454545;
  opacity: 0.4;
}
.rs-contact.main-home.home5-contact-style input[type=submit]:hover {
  opacity: .9;
}
.rs-contact.contact-style2 .contact-address {
  background-color: #ffffff;
  padding: 70px 50px 60px 60px;
}
.rs-contact.contact-style2 .contact-address .address-item {
  display: flex;
  position: relative;
  align-items: center;
  margin-bottom: 30px;
}
.rs-contact.contact-style2 .contact-address .address-item .address-icon img {
  width: 35px;
  height: 35px;
  max-width: unset;
}
.rs-contact.contact-style2 .contact-address .address-item .address-text {
  padding-left: 25px;
  color: #444444;
  font-weight: 500;
}
.rs-contact.contact-style2 .contact-address .address-item .address-text a {
  color: #444444;
  font-weight: 500;
}
.rs-contact.contact-style2 .contact-address .address-item .address-text a:hover {
  color: #bb0b0b;
}
.rs-contact.contact-style2 .contact-map {
  overflow: hidden;
}
.rs-contact.contact-style2 .contact-map iframe {
  float: left;
  width: 100%;
  height: 644px;
  border: none;
}
.rs-contact.contact-style2 .contact-wrap .from-control {
  padding: 17px 17px 17px 17px;
  color: #000000;
  border-style: solid;
  border-width: 1px 1px 1px 1px;
  border-color: #EBEBEB;
  background-color: #F1F1F1;
  width: 100%;
  max-width: 100%;
  opacity: 1;
}
.rs-contact.contact-style2 .contact-wrap textarea {
  height: 120px;
}
.rs-contact.contact-style2 .contact-wrap ::-webkit-input-placeholder {
  /* Chrome/Opera/Safari */
  color: #454545;
  opacity: 0.55;
}
.rs-contact.contact-style2 .contact-wrap ::-moz-placeholder {
  /* Firefox 19+ */
  color: #454545;
  opacity: 0.55;
}
.rs-contact.contact-style2 .contact-wrap :-ms-input-placeholder {
  /* IE 10+ */
  color: #454545;
  opacity: 1;
}
.rs-contact.contact-style2 .contact-wrap :-moz-placeholder {
  /* Firefox 18- */
  color: #454545;
  opacity: 0.55;
}
.rs-contact.faq-style .contact-item {
  padding: 35px 35px 35px 35px;
  background-color: #fff;
}
.rs-contact.faq-style .contact-item .contact-icon img {
  width: 64px;
}
.rs-contact.faq-style .contact-item .content-text .title {
  font-size: 20px;
  line-height: 28px;
  font-weight: 600;
  margin: 25px 0px 5px 0px;
}
.rs-contact.faq-style .contact-item .content-text .title a {
  color: #14171C;
}
.rs-contact.faq-style .contact-item .content-text a {
  color: #333333;
}
.rs-contact.faq-style .contact-item .content-text a:hover {
  color: #bb0b0b;
}
.rs-contact.faq-style .contact-item .content-text .services-txt {
  color: #333333;
  margin: 0;
}
.rs-contact.home3-contact-style .contact-wrap {
  padding: 60px 60px 83px 60px;
  background: #ffffff;
}
.rs-contact.home3-contact-style .contact-wrap .from-control {
  padding: 20px 20px 20px 20px;
  border-style: solid;
  border-width: 0px 0px 0px 0px;
  border-color: #FFFFFF;
  background-color: #F9F9F9;
  width: 100%;
  max-width: 100%;
  opacity: 1;
}
.rs-contact.home3-contact-style .contact-wrap textarea {
  height: 120px;
}
.rs-contact.home3-contact-style .contact-wrap ::-webkit-input-placeholder {
  /* Chrome/Opera/Safari */
  color: #454545;
  opacity: 0.55;
}
.rs-contact.home3-contact-style .contact-wrap ::-moz-placeholder {
  /* Firefox 19+ */
  color: #454545;
  opacity: 0.55;
}
.rs-contact.home3-contact-style .contact-wrap :-ms-input-placeholder {
  /* IE 10+ */
  color: #454545;
  opacity: 0.55;
}
.rs-contact.home3-contact-style .contact-wrap :-moz-placeholder {
  /* Firefox 18- */
  color: #454545;
  opacity: 0.55;
}
.rs-contact.home3-contact-style .contact-wrap input[type=submit]:hover {
  opacity: .9;
}
.rs-contact.home3-contact-style .icon-box-wraper {
  background-image: url(assets/images/contact/con-home3.jpg);
  background-position: top left;
  background-size: cover;
  padding: 70px 50px 99px 50px;
}
.rs-contact.home3-contact-style .icon-box-wraper .icon-box-item {
  display: flex;
}
.rs-contact.home3-contact-style .icon-box-wraper .icon-box-item .icon-box {
  margin-right: 25px;
}
.rs-contact.home3-contact-style .icon-box-wraper .icon-box-item .icon-box i {
  background-color: #1273EB;
  width: 60px;
  height: 60px;
  line-height: 60px;
  border-radius: 50%;
  color: #fff;
  text-align: center;
  display: inline-block;
}
.rs-contact.home3-contact-style .icon-box-wraper .icon-box-item .icon-box i:before {
  font-size: 23px;
}
.rs-contact.home3-contact-style .icon-box-wraper .icon-box-item .icon-content .title {
  font-size: 18px;
  font-weight: 600;
  line-height: 25px;
  margin-bottom: 5px;
}
.rs-contact.home3-contact-style .icon-box-wraper .icon-box-item .icon-content .title a {
  color: #0a0a0a;
}
.rs-contact.home3-contact-style .icon-box-wraper .icon-box-item .icon-content .title a:hover {
  color: #0082f1;
}
.rs-contact.home3-contact-style .icon-box-wraper .icon-box-item .icon-content a {
  color: #454545;
}
.rs-contact.home3-contact-style .icon-box-wraper .icon-box-item .icon-content a:hover {
  color: #0082f1;
}
.rs-contact.home3-contact-style .icon-box-wraper .icon-box-item .icon-content p {
  margin: 0;
}
/*------------------------------------
    31. Footer Section CSS
------------------------------------*/
.rs-footer.style1 {
  background: #121212;
  background-size: cover;
}
.rs-footer.style1 .footer-top {
  padding: 92px 0 65px;
}
.rs-footer.style1 .footer-top .footer-logo img {
  height: 40px;
}
.rs-footer.style1 .footer-top .footer-title {
  font-size: 20px;
  line-height: 30px;
  font-weight: 600;
  color: #ffffff;
  letter-spacing: 0;
  position: relative;
  margin-bottom: 30px;
  text-transform: uppercase;
  padding-bottom: 14px;
}
.rs-footer.style1 .footer-top .footer-title:after {
  content: "";
  width: 50px;
  height: 4px;
  background: #bb0b0b;
  position: absolute;
  bottom: -4px;
  right: 0;
  left: 0;
  margin-left: 0;
  border-radius: 0;
  -webkit-transition: all .3s ease;
  transition: all .3s ease;
  z-index: 0;
}
.rs-footer.style1 .footer-top .site-map li {
  margin-bottom: 15px;
}
.rs-footer.style1 .footer-top .site-map li a {
  font-size: 15px;
  color: #ffffff;
}
.rs-footer.style1 .footer-top .site-map li a:hover {
  color: #bb0b0b;
}
.rs-footer.style1 .footer-top .site-map li:last-child {
  margin-bottom: 0;
}
.rs-footer.style1 .footer-top .footer-social li {
  display: inline;
  margin-right: 5px;
}
.rs-footer.style1 .footer-top .footer-social li a i {
  padding: 0 0px 0 0;
  font-size: 15px;
  margin-right: 3px;
  transition: all .8s ease;
  background: #1c2024;
  width: 35px;
  height: 35px;
  line-height: 35px;
  border-radius: 0;
  color: #fff;
  text-align: center;
}
.rs-footer.style1 .footer-top .footer-social li a i:hover {
  color: #ffffff;
  background: #bb0b0b;
}
.rs-footer.style1 .footer-top .footer-social li:last-child {
  margin: 0;
}
.rs-footer.style1 .footer-top .address-widget li {
  padding: 0px 0px 16px 40px;
  position: relative;
}
.rs-footer.style1 .footer-top .address-widget li .desc {
  color: #ffffff;
}
.rs-footer.style1 .footer-top .address-widget li .desc a {
  color: #ffffff;
}
.rs-footer.style1 .footer-top .address-widget li .desc a:hover {
  color: #bb0b0b;
}
.rs-footer.style1 .footer-top .address-widget li i {
  color: #bb0b0b;
  position: absolute;
  left: 0px;
}
.rs-footer.style1 .footer-top .address-widget li i:before {
  font-size: 20px;
}
.rs-footer.style1 .footer-top .widget-desc {
  margin: 0;
}
.rs-footer.style1 .footer-top p {
  margin: 30px 0 0;
  position: relative;
}
.rs-footer.style1 .footer-top p input[type=email] {
  border: none;
  width: 92%;
  font-size: 13px;
  padding: 20px 60px 20px 20px;
  margin: 0;
  color: #0a0a0a;
  overflow: hidden;
  border-radius: 0px;
  background: #fff;
}
.rs-footer.style1 .footer-top p input[type="submit"] {
  border: none;
  padding: 17px 30px;
  font-size: 14px;
  background: #bb0b0b;
  color: #ffffff;
  border-radius: 0px;
  margin-top: 15px;
  text-transform: uppercase;
  font-weight: 600;
  transition: all .5s ease;
}
.rs-footer.style1 .footer-top p input[type=submit]:hover {
  opacity: .9;
}
.rs-footer.style1 .footer-top p ::-webkit-input-placeholder {
  /* Chrome/Opera/Safari */
  color: #0a0a0a;
  opacity: 1;
}
.rs-footer.style1 .footer-top p ::-moz-placeholder {
  /* Firefox 19+ */
  color: #0a0a0a;
  opacity: 1;
}
.rs-footer.style1 .footer-top p :-ms-input-placeholder {
  /* IE 10+ */
  color: #0a0a0a;
  opacity: 1;
}
.rs-footer.style1 .footer-top p :-moz-placeholder {
  /* Firefox 18- */
  color: #0a0a0a;
  opacity: 1;
}
.rs-footer.style1 .footer-bottom {
  padding: 20px 0;
  background: #000;
}
.rs-footer.style1 .footer-bottom .copyright p {
  margin: 0;
  color: #ffffff;
}
.rs-footer.style1 .footer-bottom .copy-right-menu li {
  display: inline-block;
  position: relative;
  padding: 0px 19px 0 25px;
}
.rs-footer.style1 .footer-bottom .copy-right-menu li a {
  color: #ffffff;
}
.rs-footer.style1 .footer-bottom .copy-right-menu li a:hover {
  color: #bb0b0b;
}
.rs-footer.style1 .footer-bottom .copy-right-menu li:before {
  display: block;
  content: "";
  position: absolute;
  font-size: 18px;
  background: #bb0b0b;
  left: 0;
  top: 50%;
  width: 6px;
  height: 6px;
  transform: translateY(-50%);
  border-radius: 50%;
}
.rs-footer.style1 .footer-bottom .copy-right-menu li:first-child:before {
  display: none;
}
.rs-footer.style1.footer-home3-style .footer-bottom .copy-right-menu li:before,
.rs-footer.style1.footer-home3-style .footer-top p input[type="submit"],
.rs-footer.style1.footer-home3-style .footer-top .footer-social li a i:hover,
.rs-footer.style1.footer-home3-style .footer-top .footer-title:after {
  background: #0082f1;
}
.rs-footer.style1.footer-home3-style .footer-top .address-widget li .desc a:hover,
.rs-footer.style1.footer-home3-style .footer-bottom .copy-right-menu li a:hover,
.rs-footer.style1.footer-home3-style .footer-top .address-widget li i,
.rs-footer.style1.footer-home3-style .footer-top .site-map li a:hover {
  color: #0082f1;
}
.rs-footer.style1.footer-home4-style .footer-bottom .copy-right-menu li:before,
.rs-footer.style1.footer-home4-style .footer-top p input[type="submit"],
.rs-footer.style1.footer-home4-style .footer-top .footer-social li a i:hover,
.rs-footer.style1.footer-home4-style .footer-top .footer-title:after {
  background: #fd4a36;
}
.rs-footer.style1.footer-home4-style .footer-top .address-widget li .desc a:hover,
.rs-footer.style1.footer-home4-style .footer-bottom .copy-right-menu li a:hover,
.rs-footer.style1.footer-home4-style .footer-top .address-widget li i,
.rs-footer.style1.footer-home4-style .footer-top .site-map li a:hover {
  color: #fd4a36;
}
.rs-footer.style1.footer-home5-style .footer-bottom .copy-right-menu li:before,
.rs-footer.style1.footer-home5-style .footer-top p input[type="submit"],
.rs-footer.style1.footer-home5-style .footer-top .footer-social li a i:hover,
.rs-footer.style1.footer-home5-style .footer-top .footer-title:after {
  background: #4caf50;
}
.rs-footer.style1.footer-home5-style .footer-top .address-widget li .desc a:hover,
.rs-footer.style1.footer-home5-style .footer-bottom .copy-right-menu li a:hover,
.rs-footer.style1.footer-home5-style .footer-top .address-widget li i:before,
.rs-footer.style1.footer-home5-style .footer-top .site-map li a:hover {
  color: #4caf50;
}
.rs-footer .bg-wrap {
  background: url(assets/images/footer-bg.png);
  background-repeat: no-repeat;
  background-size: cover;
  border-radius: 70px 0 0 0;
}
.rs-footer .bg-wrap .newslatter-wrap.extra-pt {
  padding-top: 281px;
}
.rs-footer .bg-wrap .newslatter-wrap .footer-newsletter {
  background: #ffffff;
  border-radius: 0 70px 0 0;
  padding: 78px 0 90px;
  box-shadow: 0 6px 30px 0 rgba(0, 0, 0, 0.08);
  position: relative;
}
.rs-footer .bg-wrap .newslatter-wrap .footer-newsletter.fly-up {
  position: absolute;
  content: '';
  top: -185.5px;
  width: 100%;
  z-index: 1;
  left: 0;
}
.rs-footer .bg-wrap .newslatter-wrap .footer-newsletter .pattern {
  position: absolute;
  content: '';
}
.rs-footer .bg-wrap .newslatter-wrap .footer-newsletter .pattern.img1 {
  bottom: 0;
  left: 50px;
}
.rs-footer .bg-wrap .newslatter-wrap .footer-newsletter .pattern.img2 {
  top: 0;
  right: 50px;
}
.rs-footer .bg-wrap .newslatter-wrap .footer-newsletter .subscribe-form {
  max-width: 450px;
  margin: 0 auto;
  position: relative;
  z-index: 1;
}
.rs-footer .bg-wrap .newslatter-wrap .footer-newsletter .subscribe-form input {
  width: 100%;
  background: #f5f5f5;
  border-radius: 30px;
  border: none;
  outline: none;
  padding: 20px 25px;
  height: 65px;
}
.rs-footer .bg-wrap .newslatter-wrap .footer-newsletter .subscribe-form .readon2 {
  position: absolute;
  content: '';
  padding-right: 25px;
  padding-left: 25px;
  right: 7px;
  top: 7px;
  border: none;
  outline: none;
  text-transform: capitalize;
  font-weight: 500;
}
.rs-footer .bg-wrap .newslatter-wrap .footer-newsletter .subscribe-form ::-webkit-input-placeholder {
  color: #999999;
}
.rs-footer .bg-wrap .newslatter-wrap .footer-newsletter .subscribe-form :-ms-input-placeholder {
  color: #999999;
}
.rs-footer .bg-wrap .newslatter-wrap .footer-newsletter .subscribe-form ::placeholder {
  color: #999999;
}
.rs-footer .bg-wrap .footer-content .about-widget {
  color: #ffffff;
}
.rs-footer .bg-wrap .footer-content .about-widget .logo-area {
  margin-bottom: 26px;
}
.rs-footer .bg-wrap .footer-content .about-widget .logo-area a img {
  height: 40px;
}
.rs-footer .bg-wrap .footer-content .about-widget a {
  color: #e2e2e2;
}
.rs-footer .bg-wrap .footer-content .about-widget a:hover {
  color: #e94d65;
}
.rs-footer .bg-wrap .footer-content .about-widget .footer-desc {
  color: #e2e2e2;
  margin-bottom: 12px;
}
.rs-footer .bg-wrap .footer-content .about-widget .social-links {
  margin-top: 20px;
}
.rs-footer .bg-wrap .footer-content .about-widget .social-links li {
  display: inline;
  margin-right: 10px;
}
.rs-footer .bg-wrap .footer-content .about-widget .social-links li a {
  width: 35px;
  height: 35px;
  text-align: center;
  line-height: 37px;
  border-radius: 100%;
  display: inline-block;
  background: #e94d65;
  font-size: 16px;
  color: #ffffff;
}
.rs-footer .bg-wrap .footer-content .about-widget .social-links li a:hover {
  background: #ffffff;
  color: #e94d65;
}
.rs-footer .bg-wrap .footer-content .about-widget .social-links li:last-child {
  margin: 0;
}
.rs-footer .bg-wrap .footer-content .widget .widget-title {
  color: #ffffff;
  font-size: 24px;
  line-height: 30px;
  font-weight: 600;
  margin-bottom: 26px;
}
.rs-footer .bg-wrap .footer-content .widget ul li {
  margin-bottom: 10px;
}
.rs-footer .bg-wrap .footer-content .widget ul li a {
  color: #e2e2e2;
  display: inline-block;
}
.rs-footer .bg-wrap .footer-content .widget ul li a:hover {
  color: #e94d65;
  margin-left: 5px;
}
.rs-footer .bg-wrap .footer-content .widget ul li:last-child {
  margin: 0;
}
.rs-footer .bg-wrap .footer-bottom {
  padding: 23px 0;
  border-top: 1px solid #13487a;
}
.rs-footer .bg-wrap .footer-bottom .copyright {
  margin: 0;
  color: #dedede;
  font-size: 14px;
}
.rs-footer .pattern-right {
  position: absolute;
  bottom: 0;
  right: 0;
}
.extra-pb {
  padding-bottom: 306px;
}
/* -----------------------
    32. Scroll Up CSS
--------------------------*/
#scrollUp {
  text-align: center;
  bottom:70px;
  cursor: pointer;
  display: none;
  position: fixed;
  right: 30px;
  z-index: 999;
  border-radius: 50px 50px 4px 4px;
}
#scrollUp i {
  height: 40px;
  font-size: 24px;
  width: 40px;
  border-radius:40px;
  color:#7b7021;
  line-height: 36px;
  transition: all 0.3s ease 0s;
  background:#ffe100;
  margin-left: 2px;
  box-shadow: 0 0 10px rgb(0 0 0 / 14%);
}
#scrollUp i:hover {
  opacity: 0.8;
}
#scrollUp.blue-color i {
  background: #0082f1;
}
#scrollUp.blue-color i:hover {
  opacity: 0.8;
}
#scrollUp.orange2-color i {
  background: #fd4a36;
}
#scrollUp.orange2-color i:hover {
  opacity: 0.8;
}
#scrollUp.paste-color i {
  background: #4caf50;
}
#scrollUp.paste-color i:hover {
  opacity: 0.8;
}
.text-center .banner-border-line {
  margin: 0 auto 26px;
}
.home-blue-color .search-modal,
.home-blue-color .rs-carousel .owl-dots .owl-dot,
.home-blue-color .rs-carousel .owl-dots .owl-dot:hover,
.home-blue-color .rs-carousel .owl-dots .owl-dot.active {
  background: #0082f1;
}
.home-blue-color .search-modal .search-block form button {
  background: unset;
  border: none;
  padding: 0px 20px;
  right: 0;
  top: 0px;
}
.home-orange-color .search-modal,
.home-orange-color .rs-carousel .owl-dots .owl-dot,
.home-orange-color .rs-carousel .owl-dots .owl-dot:hover,
.home-orange-color .rs-carousel .owl-dots .owl-dot.active {
  background: #fd4a36;
}
.home-orange-color .search-modal .search-block form button {
  background: unset;
  border: none;
  padding: 0px 20px;
  right: 0;
  top: 0px;
}
.home-paste-color .search-modal,
.home-paste-color .rs-carousel .owl-dots .owl-dot,
.home-paste-color .rs-carousel .owl-dots .owl-dot:hover,
.home-paste-color .rs-carousel .owl-dots .owl-dot.active {
  background: #4caf50;
}
.home-paste-color .search-modal .search-block form button {
  background: unset;
  border: none;
  padding: 0px 20px;
  right: 0;
  top: 0px;
}
.home-paste-color .full-width-header .right_menu_togle .canvas-contact .address-area .address-list .info-content em a:hover,
.home-paste-color .full-width-header .right_menu_togle .canvas-contact .address-area .address-list .info-icon i::before {
  color: #4caf50;
}
.home-paste-color .full-width-header .rs-header .expand-btn-inner ul .humburger .nav-expander .bar span {
  background: #4caf50;
}
.home-paste-color .full-width-header .right_menu_togle .close-btn:hover .nav-close .line span {
  background: #4caf50;
}
.home-paste-color .gridFilter button:hover,
.home-paste-color .gridFilter button.active {
  background: #4caf50 !important;
}
.home-6 a {
  color: #043d72;
  text-decoration: none;
}
.home-6 a:hover {
  color: #e94d65;
}
.home-6 .desc {
  font-weight: 400;
}
.home-6 .readon.submit.paste-btn {
  border-radius: 30px;
  padding-top: 14px;
  padding-bottom: 14px;
}
.home-6 #scrollUp i,
.home-6 .readon.submit.paste-btn,
.home-6 .gridFilter button.active,
.home-6 .gridFilter button:hover,
.home-6 .rs-project.style1 .project-item .project-content .project-inner .p-icon,
.home-6 .full-width-header .rs-header.style2 .topbar-area,
.home-6 .full-width-header .right_menu_togle .canvas-contact .social li a i:hover,
.home-6 .full-width-header .rs-header .expand-btn-inner ul .humburger .nav-expander .bar span {
  background: #e94d65;
}
.home-6 .full-width-header .rs-header .topbar-area.style1 .toolbar-sl-share ul li a:hover {
  opacity: 0.85;
  color: #fff;
}
.home-6 .rs-project.style1 .project-item .project-content .project-inner .title a:hover,
.home-6 .full-width-header .right_menu_togle .canvas-contact .address-area .address-list .info-icon i::before,
.home-6 .full-width-header .rs-header.style2 .expand-btn-inner ul li > a:hover {
  color: #e94d65;
}
.home-6 .full-width-header .rs-header .menu-area .main-menu .rs-menu ul li a:hover,
.home-6 .full-width-header .rs-header .menu-area .main-menu .rs-menu ul li.active a,
.home-6 .full-width-header .rs-header .menu-area .main-menu .rs-menu ul li.current-menu-item > a,
.home-6 .full-width-header .rs-header .menu-area .main-menu .rs-menu ul li.current-menu-item > a::before {
  color: #e94d65 !important;
}
.pl-30 {
  padding-left: 30px;
}
.pr-30 {
  padding-right: 30px;
}
/*----------------------------------------------
    33. Preloader CSS
----------------------------------------------*/
#pre-load {
  background-color: #fff;
  height: 100%;
  width: 100%;
  position: fixed;
  margin-top: 0px;
  top: 0px;
  z-index: 9999;
}
.loader .loader-container {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 100px;
  height: 100px;
  border: 5px solid #ebebec;
  border-radius: 50%;
}
.loader .loader-container:before {
  position: absolute;
  content: "";
  display: block;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 100px;
  height: 100px;
  border-top:4px solid #51ad32;
  border-radius: 50%;
  animation: loaderspin 1.8s infinite ease-in-out;
  -webkit-animation: loaderspin 1.8s infinite ease-in-out;
}
.loader .loader-icon {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 80px;
  text-align: center;
}
.loader .loader-icon img {
  animation: loaderpulse alternate 900ms infinite;
  width: 30px;
}
.woocommerce .quantity input[type="number"]::-webkit-input-placeholder {
  /* Chrome/Opera/Safari */
  color: #363636 !important;
  opacity: 1;
}
.woocommerce .quantity input[type="number"]::-moz-placeholder {
  /* Firefox 19+ */
  color: #363636 !important;
  opacity: 1;
}
.woocommerce .quantity input[type="number"]:-ms-input-placeholder {
  /* IE 10+ */
  color: #363636 !important;
  opacity: 1;
}
.woocommerce .quantity input[type="number"]:-moz-placeholder {
  /* Firefox 18- */
  color: #363636 !important;
  opacity: 1;
}
/* ------------------------------------
    34. Animation Part CSS
---------------------------------------*/
@keyframes loaderspin {
  0% {
    transform: translate(-50%, -50%) rotate(0deg);
  }
  100% {
    transform: translate(-50%, -50%) rotate(360deg);
  }
}
@-webkit-keyframes loaderspin {
  0% {
    transform: translate(-50%, -50%) rotate(0deg);
  }
  100% {
    transform: translate(-50%, -50%) rotate(360deg);
  }
}
@keyframes loaderpulse {
  0% {
    transform: scale(1);
  }
  100% {
    transform: scale(1.2);
  }
}
@keyframes pulse-border {
  0% {
    transform: translateX(-50%) translateY(-50%) translateZ(0) scale(1);
    opacity: 1;
  }
  100% {
    transform: translateX(-50%) translateY(-50%) translateZ(0) scale(1.5);
    opacity: 0;
  }
}
@-webkit-keyframes pulse-border {
  0% {
    transform: translateX(-50%) translateY(-50%) translateZ(0) scale(1);
    opacity: 1;
  }
  100% {
    transform: translateX(-50%) translateY(-50%) translateZ(0) scale(1.5);
    opacity: 0;
  }
}
.move-y {
  animation: move-y 2s alternate infinite;
  -webkit-animation: move-y 2s alternate infinite;
}
@keyframes move-y {
  0% {
    transform: translate3d(0, 0, 0);
    -webkit-transform: translate3d(0, 0, 0);
  }
  100% {
    transform: translate3d(0, 10px, 0);
    -webkit-transform: translate3d(0, 10px, 0);
  }
}
.scale {
  animation: scale 2s alternate infinite;
}
@-webkit-keyframes scale {
  0% {
    transform: scale(0.8);
    -webkit-transform: scale(0.8);
  }
  100% {
    transform: scale(1);
    -webkit-transform: scale(1);
  }
}
.dance2 {
  -webkit-animation: dance2 4s alternate infinite;
}
@keyframes dance2 {
  0% {
    -webkit-transform: translate3d(0, 0, 0);
  }
  50% {
    -webkit-transform: translate3d(15px, -15px, 0);
  }
  100% {
    -webkit-transform: translate3d(0, -15px, 15px);
  }
}
.up-down {
  animation: up-down 7s alternate infinite;
  -webkit-animation: up-down 7s alternate infinite;
}
@keyframes up-down {
  0% {
    transform: translateY(20px);
    -webkit-transform: translateY(0);
  }
  50% {
    transform: translateY(-20px);
    -webkit-transform: translateY(-20px);
  }
  100% {
    transform: translateY(0);
    -webkit-transform: translateY(0);
  }
}
@-webkit-keyframes up-down {
  0% {
    transform: translateY(30px);
    -webkit-transform: translateY(0);
  }
  50% {
    transform: translateY(-50px);
    -webkit-transform: translateY(-50px);
  }
  100% {
    transform: translateY(0);
    -webkit-transform: translateY(0);
  }
}
.spine {
  animation: spine 8s linear infinite;
}
@keyframes spine {
  0% {
    transform: rotate(0);
    -webkit-transform: rotate(0);
  }
  100% {
    transform: rotate(360deg);
    -webkit-transform: rotate(360deg);
  }
}
@-webkit-keyframes spine {
  0% {
    transform: rotate(0);
    -webkit-transform: rotate(0);
  }
  100% {
    transform: rotate(360deg);
    -webkit-transform: rotate(360deg);
  }
}
.horizontal {
  animation-name: horizontal;
  animation-duration: 12s;
  animation-timing-function: linear;
  animation-iteration-count: infinite;
}
.horizontal.new-style {
  animation-duration: 15s;
}
@keyframes horizontal {
  0% {
    transform: translateX(0);
  }
  50% {
    transform: translateX(100px);
  }
  100% {
    transform: translateX(0);
  }
}
.vertical {
  animation-name: vertical;
  animation-duration: 12s;
  animation-timing-function: linear;
  animation-iteration-count: infinite;
}
.vertical.new-style {
  animation-duration: 15s;
}
@keyframes vertical {
  0% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(100px);
  }
  100% {
    transform: translateY(0);
  }
}
.fadeinup {
  animation-duration: 2s;
  animation-name: fadeInUp;
}
.fadeinup.new {
  animation-duration: 3s;
}
@keyframes fadeinup {
  0% {
    opacity: 0;
    transform: translate3d(0, 100%, 0);
  }
  100% {
    opacity: 1;
    transform: none;
  }
}
.fadeinright {
  animation-duration: 3s;
  animation-name: fadeInRight;
}
@keyframes fadeinup {
  0% {
    opacity: 0;
    transform: translate3d(0, 100%, 0);
  }
  100% {
    opacity: 1;
    transform: none;
  }
}
.rotate_Y {
  animation: mymove 5s infinite;
}
@keyframes mymove {
  0% {
    transform: rotateY(-50deg);
  }
  100% {
    transform: rotateY(50deg);
  }
}
.rs-services.style6 .services-box-area .services-item:hover .dance_hover,
.rs-services.style4 .services-item:hover .dance_hover,
.rs-iconbox-area .box-inner .box-item:hover .dance_hover {
  animation-name: dance_hover;
  animation-duration: 1s;
  animation-timing-function: ease-in-out;
  animation-iteration-count: 1;
}
@keyframes dance_hover {
  16.65% {
    transform: translateY(8px);
  }
  33.3% {
    transform: translateY(-6px);
  }
  49.95% {
    transform: translateY(4px);
  }
  66.6% {
    transform: translateY(-2px);
  }
  83.25% {
    transform: translateY(1px);
  }
  100% {
    transform: translateY(0);
  }
}
/* Draw Line Animation */
@keyframes start-draw {
  to {
    height: 95%;
  }
}
/* Infinite Rotation Animation */
@keyframes rotate-anim {
  from {
    -webkit-transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(359deg);
  }
}
/*Pulse Border Animation*/
@keyframes pulse-border {
  0% {
    transform: translateX(-50%) translateY(-50%) translateZ(0) scale(1);
    opacity: 1;
  }
  100% {
    transform: translateX(-50%) translateY(-50%) translateZ(0) scale(1.5);
    opacity: 0;
  }
}
/**Custom**/

.slider-des{ display: flex; justify-content: space-between; align-items: center;}
.rs-slider.rs-slider-style4.slider3 .content-part .sl-title span{ font-weight: 400; font-size: 32px;}
.readon.consultant.white-slide { background: #ffffff2e;}
.select-language select{ background-color: #111f4f; padding:0.475rem 2.05rem 0.475rem 1.05rem;  line-height:19px; font-size:13px; color: #FFF; border:none; font-weight:500; height:32px; background-image:url(assets/images/select-arw.png); background-size:10px 6px; width: 100px; }
.full-width-header .rs-header .topbar-area.style1 .toolbar-sl-share ul li a i{ font-size:20px }
.mgl-network-section{ display:flex; justify-content: space-between; }
.mgl-network-section .mgl-network-caption{ width:24%; background: rgb(50,151,173); background: linear-gradient(48deg, rgba(50,151,173,1) 0%, rgba(133,241,98,1) 100%); padding: 40px; position: relative; min-height: 370px}
.mgl-network-section .mgl-network-caption h2{ font-size:35px; color:#FFF; font-weight:400; position:relative; z-index:9 }
.mgl-network-section .mgl-network-caption h2 span{ font-weight:600; display:block; }
.mgl-network-section .mgl-network-caption img{ opacity:0.6; width:100% ; position: absolute; left: 0; bottom: 0; height: 100%}
.mgl-network-section .mgl-slider{ width:75% }
.mgl-slider .icon-service{  width: 88px; height: 88px; background: #FFF;  padding:20px; border-radius: 100%; margin:0 auto 20px; box-shadow: 0px 16px 20px 0px rgb(69 94 60 / 11%)}
.mgl-slider .service-wrap{ text-align:center; }
.rs-services.style2 .service-wrap{ background:#f6fff6; border:1px solid #e2fbe2; box-shadow:none; flex-direction: column; justify-content: center; min-height: 370px; padding: 35px 15px 30px; border-radius:6px }
.rs-services.style2 .mgl-slider .service-wrap .content-part .desc{ font-size:16px; font-weight: 400; line-height: 18px;}
.rs-services.style2 .mgl-slider .service-wrap .content-part .desc span{ font-size:36px;  display: block; font-weight:300; line-height: 36px; margin: 10px 0; }
.news-latest{ display:flex; }
.news-latest .news-section{ padding:5% 0 5% 2%; background:#233440; width:68% }
.site-heading{ font-size:36px; color:#FFF; font-weight:300 }
.site-heading span{ font-weight:600 }

.news-section .rs-blog.style2 .blog-item .blog-content{ box-shadow:0 6px 25px #20323e; padding:25px 22px 25px 22px; border-bottom:2px solid #1485d4}
.news-section .rs-blog.style2 .owl-stage-outer{ padding:0; margin:0 }
.news-section .rs-blog.style2 .blog-item .blog-content .blog-meta .admin{ padding-right: 6px; }
.news-section .rs-blog.style2 .blog-item .blog-content .blog-meta .date i, .news-section .rs-blog.style2 .blog-item .blog-content .blog-meta .admin i{ color:#878787 }
.news-section .rs-blog.style2 .blog-item .blog-content .blog-title a{ display: -webkit-box; max-width: 400px; height:48.2px; margin:0; font-size: 16px; -webkit-line-clamp: 2; -webkit-box-orient: vertical;  overflow: hidden; text-overflow: ellipsis;     line-height: 24px; font-weight: 400}
.news-section .rs-blog.style2 .blog-item .blog-content .view-details{ color:#037cd1 }

.news-section{ position:relative; }
.news-section .newsbg{ position:absolute; left:0; bottom:0; width:100%; opacity:0.1; height: 100%; }
.blog-know-btn{ margin-top:5%; position:relative; z-index:9; display: inline-flex; }
.blog-know-btn a{ padding:14px 45px 14px 25px; color: #fff; background: #037cd1; overflow: hidden; position: relative; width:280px; display:block; margin-left:45px; border-radius:6px }
.blog-know-btn a i{ position:absolute; right:10px; top: 12px; font-size:18px; }
.news-section .rs-blog .rs-carousel .owl-nav{ text-align:right; position:absolute; right:70px; bottom:-130px}
.news-section .rs-blog .rs-carousel .owl-nav i:before{ font-size:56px; }
.news-section .rs-blog .rs-carousel .owl-nav .owl-prev, .news-section .rs-blog .rs-carousel .owl-nav .owl-next{ background:none; border:none; color:#FFF; width: 45px; }

.news-latest .latest-section{  width:32% ; background: rgb(3,124,209); background: linear-gradient(48deg, rgba(3,124,209,1) 0%, rgba(9,95,152,1) 100%); padding:5% 5% 5% 5% }
.news-latest .latest-section select{background-color: #FFF; padding:0.475rem 4.25rem 0.475rem 1.55rem;  line-height: 25px; font-size:14px; color: #000; border:none; font-weight:500; height:54px;}
.latest-details{ margin:20px 0 }
.latest-details ul li{ border-bottom:1px solid #2f83bb; margin-bottom:15px; padding-bottom:15px }
.latest-details ul li .date-highlights{ font-size:14px; color:#94d3fe; margin-bottom:10px; }
.latest-details ul li .date-highlights span{ color:#f6ff00; }
.latest-details ul li p{ color:#94d3fe; margin-bottom:0 }
.latest-details ul li:last-child{ border-bottom:none;margin-bottom: 0; padding-bottom: 0; }
.latest-details ul{ margin-bottom:50px }
.news-section .owl-carousel{ right: -40px; }
.greenbtn{ padding:14px 45px 14px 25px; color: #fff; background: #51ad32; overflow: hidden; position: relative; width:280px; display:block; border-radius:6px }
.greenbtn i{ position:absolute; right:10px; top: 12px; font-size:18px;  }
.news-section .site-heading{ margin-left:45px }
.csr-activity{ display:flex; }
.csr-activity .csr-content{ padding:5% 8% 5% 6%; background:#f4faff; width:68% }
.csr-activity .csr-whitebox{ box-shadow:0px 12px 20px rgb(32 50 62 / 8%); padding:6% 18% 6% 6%; background:#FFF; border-radius: 6px; }
.csr-activity .csr-whitebox h2{ font-size:36px; color:#51ad32; position:relative; margin-bottom:40px }
.csr-activity .csr-whitebox h2 span{ font-weight:600; display:block; }
.csr-activity .csr-whitebox h2:after{ width:50px; height:2px; background:#51ad32; position:absolute; left:0; bottom:-15px; content:"";  }
.csr-activity .csr-img{  width:32% ; background: rgb(50,151,173); background: linear-gradient(48deg, rgba(50,151,173,1) 0%, rgba(134,241,97,1) 100%); padding:5% 5% 5% 5% ; position: relative;}
.bluebtn{ padding:14px 45px 14px 25px; color: #fff; background: #037cd1; overflow: hidden; position: relative; width:280px; display:block; border-radius:6px }
.bluebtn i{ position:absolute; right:10px; top: 12px; font-size:18px;  }
.csr-activity .csr-img img{ width: 585px; position: absolute; right: 70px; top: 110px; max-width: fit-content; box-shadow:0px 12px 20px rgb(32 50 62 / 8%); border-radius: 6px;}
.client-logos .owl-nav{ position:absolute; width:100%; top:20%; transform:translate(0,-50%); }
.client-logos .owl-carousel .owl-nav .owl-next{ position:absolute; right:-70px; background:none; border:none; }
.client-logos .owl-carousel .owl-nav .owl-prev{ position:absolute; left:-70px; background:none; border:none; }
.client-logos .owl-carousel .owl-nav .owl-next i, .client-logos .owl-carousel .owl-nav .owl-prev i{ font-size:56px; color:#acbdc9 }
.client-logos .logo-img{ padding:50px 25px; border-bottom:2px solid #51ad32; }
.client-logos{ background:#f7f8fa; }
.client-logos.rs-patter-section.style1 .logo-img{ background:#FFF }
.client-logos.rs-patter-section.style1 .logo-img { padding: 30px 40px;}
.investor-leadership .investor-zone{ padding:8%; min-height:100% }
.blue-gradient{ background: rgb(3,124,209); background: linear-gradient(48deg, rgba(3,124,209,1) 0%, rgba(9,95,152,1) 100%);}
.green-gradient{ background: rgb(50,151,173); background: linear-gradient(48deg, rgba(50,151,173,1) 0%, rgba(134,241,97,1) 100%);}
.investor-zone .qtr-select select{ background-color: #037cd1; padding: 0.475rem 4.25rem 0.475rem 1.55rem; line-height: 25px; font-size: 14px; color: #fff; font-weight: 500; height: 54px; border: 2px solid #208fdd; border-radius: 0; background-image:url(assets/images/select-arw.png); background-size:10px 6px;}
.investor-zone .qtr-select a{ background-color: #037cd1; padding:0 10px; line-height:54px; font-size: 14px; color: #fff; font-weight: 500; height: 54px; border: 2px solid #208fdd; border-radius:6px; width: 100%;  display:flex; width: 100%; text-align: center; align-items: center; justify-content: center; box-shadow: rgba(9, 30, 66, 0.25) 0px 4px 8px -2px, rgba(9, 30, 66, 0.08) 0px 0px 0px 1px}
.investor-zone .qtr-select a i{ font-size: 22px; }
.investor-zone .qtr-select a:hover{ background:#208fdd }
.nse-bse-section{ background:#208fdd; padding:20px; color:#FFF; margin-top:40px; border-radius: 6px }
.nse-bse-section .nse-bse-content{ display:flex; justify-content: space-between; }
.nse-bse-section .nse-bse-content p{ width:50%; margin-bottom:0; font-size:18px; }
.nse-bse-section p{ margin-bottom:5px }
.investor-zone .investors-events{ padding:50px 0 0  }
.investor-zone .investors-events ul{ display:flex; }
.investor-zone .investors-events ul li{ width:50%; border-right:1px solid #208fdd; padding-right:20px; margin-right:20px }
.investor-zone .investors-events ul li .in-ev-headings{ color:#FFF; font-size:16px; margin-bottom:15px; }
.investor-zone .investors-events ul li .in-ev-date{ color:#74c5fd; margin-bottom:15px }
.investor-zone .investors-events ul li p{ color:#FFF; line-height:22px; font-size:14px; margin-bottom:15px }
.investor-zone .investors-events ul li a{ color:#74c5fd; }
.investor-zone .investors-events ul li:last-child{ border-right:0 }
.rs-videos.choose-video .images-video{ position:relative; }
.rs-videos.choose-video .images-video img{ border-radius:8px; width:100% }
.rs-videos.choose-video .images-video:after{ position:absolute; left:0; top:0; content:""; width:100%; height:100%; background: rgb(0 0 0 / 55%); border-radius: 8px; }
.leadership-desc{ color:#FFF; margin:0 8%; text-align:center; position:relative; }
.leadership-desc:after{ position:absolute; left:-40px; top:-35px; content:"‘‘"; font-size:60px ; font-family:'Times New Roman', sans-serif;  letter-spacing: -3.5px}
.leadership-desc:before{ position:absolute; bottom:-35px; right:-40px; content:",,"; font-size:60px; font-family:'Times New Roman', sans-serif; letter-spacing: -3.5px}

.blogs-article{ position:relative; }
.blogs-article .rs-blog.style2 .blog-item .blog-content{ box-shadow:0 6px 25px rgb(32 50 62 / 12%); padding:25px 22px 25px 22px; border-bottom:2px solid #1485d4}
.blogs-article .rs-blog.style2 .owl-stage-outer{ padding:0; margin:0 }
.blogs-article .rs-blog.style2 .blog-item .blog-content .blog-meta .admin{ padding-right: 6px; }
.blogs-article .rs-blog.style2 .blog-item .blog-content .blog-meta .date i, .blogs-article .rs-blog.style2 .blog-item .blog-content .blog-meta .admin i{ color:#878787 }
.blogs-article .rs-blog.style2 .blog-item .blog-content .blog-title a{ display: -webkit-box; max-width: 400px; height: 50.2px; margin:0; font-size: 16px; -webkit-line-clamp: 2; -webkit-box-orient: vertical;  overflow: hidden; text-overflow: ellipsis;     line-height: 24px; font-weight: 400}
.blogs-article .rs-blog.style2 .blog-item .blog-content .view-details{ color:#037cd1 }
.blogs-article .rs-blog .rs-carousel .owl-nav{ text-align:right; position:absolute; right:0; top:50%; width: 100%}
.blogs-article .rs-blog .rs-carousel .owl-nav i:before{ font-size:56px; }
.blogs-article .rs-blog .rs-carousel .owl-nav .owl-prev, .blogs-article .rs-blog .rs-carousel .owl-nav .owl-next{ background:none; border:none; color:#acbdc9 }
.blogs-article .rs-blog .rs-carousel .owl-nav .owl-prev{ position: absolute; left: -70px;}
.blogs-article .rs-blog .rs-carousel .owl-nav .owl-next{ position: absolute; right: -70px; }
.blogs-article .blog-know-btn a{ margin:0 auto; }
.blogs-article .blog-know-btn{ display: flex; }

footer .footer-left{ background-color:#13181b; padding:8% 6%; color:#FFF; min-height:100% ; display: flex; align-items: center;}
footer .footer-left ul{ margin-top:15px ;display: flex; flex-wrap: wrap;}
footer .footer-left ul li{/* margin-bottom:13px*/  }
footer .footer-left ul li a{ color:#FFF; font-size:14px }
footer .footer-left .copyright{color:#000; margin-bottom: 0}
.download-mgl{ text-align:left; }
.download-mgl .app-store-icon{ display:flex; justify-content: space-between;}
.privacy-policy-links a{ color:#62727f}
.privacy-policy-links{ /*display:flex; justify-content: space-between;*/ margin:25px 0; color:#62727f }
.follow-us-on p{ color: #FFF; margin-bottom:20px }
.follow-us-on ul{  margin-bottom:0px!important  }
.follow-us-on ul li{ display: inline;margin-right:4px; padding:0 10px }
.follow-us-on ul li i{ font-size:22px }
.follow-us-on ul li a:hover{ color:#4ec226 }
footer .footer-right{ background-color:#252e34; padding:8% 6%; color:#FFF; min-height:100%; display: flex; flex-direction: column; justify-content: center; }
footer .footer-right .customer-care{ display:flex; align-items: center; margin-bottom:10px}
footer .footer-right .customer-care i{ margin-right:20px; width: 40px; }
footer .footer-right .customer-care h3{ color:#FFF; font-weight:300; font-size:18px; line-height:28px; margin-bottom: 10px }
footer .footer-right .customer-care p{ color:#6f808d; margin-bottom:8px }

body .modal.show .mgl-menu.modal-dialog{ max-width: 90%}
.newmenu a{ color:#363636; }
.msub-menu >a{ display: block; font-size: 17px; color:#037cd1}
.msub-sub-menu{ padding-left: 10px; float: left; padding-right: 20px; width:20%;}
.msub-sub-menu > a{ border-bottom:1px solid #9dc7bf; display: block; padding: 10px 0; margin-bottom: 10px; font-weight: 600; font-size: 14px;}
.msub-sub-sub-sub-menu> a{ display: block; position: relative; padding-left: 10px; font-size: 13px;}
.msub-sub-sub-sub-menu> a:after{ position: absolute; left: 0; top: 0;content:"-"; font-size: 14px; color:#517e6b}
.msub-sub-sub-sub-menu{  padding: 10px;}
.msub-sub-sub-menu > a{ padding: 4px 0; font-size: 13px}

body .mgl-modal{ background: rgb(209,234,254);background: linear-gradient(54deg, rgba(209,234,254,1) 0%, rgba(224,254,228,1) 100%);}
body .mgl-modal .modal-content { position: initial; border: 0; background: unset;}
body .mgl-modal .modal-content { position: initial; border: 0; background: unset;}
body .mgl-modal .search-block form { position: relative;}
body .mgl-modal .search-block form input { background: none; border: none; color: #fff; border-bottom: 2px solid rgba(255, 255, 255, 0.2); outline: none; font-size: 35px;}
body .mgl-modal .search-block form button { background: #000; border: none; padding: 5px 20px; color: #ffffff; border-radius: 0 5px 5px 0; font-size: 16px; position: absolute; content: ''; right: 0; top: 11px;}
body .mgl-modal .search-block form button i:before { color: #fff; font-size: 35px; font-weight: 600;}
body .mgl-modal .mgl-block ::-webkit-input-placeholder { /* Chrome/Opera/Safari */  color: #fff; opacity: 1;}
body .mgl-modal .mgl-block ::-moz-placeholder { /* Firefox 19+ */ color: #fff; opacity: 0.3;}
body .search-modal .search-block :-ms-input-placeholder { /* IE 10+ */  color: #fff;  opacity: 1;}
body .mgl-modal .search-block :-moz-placeholder {  /* Firefox 18- */ color: #fff;  opacity: 1;}
body .mgl-modal .close {  position: fixed;  right: 50px; top: 50px; background: #ffffff; color: #0a0a0a; width: 55px; height: 55px; line-height: 58px; text-align: center; cursor: pointer; border-radius: 50%; transition: all .5s ease; opacity: unset; box-shadow: unset; border: none;}
body .mgl-modal .close span:before { font-size: 14px; position: relative; top: -2px; left: 0px; font-weight: 700;}
body .mgl-modal .close:hover { opacity: 0.8 !important;}

.right-menu{ /*width:104px; background:#4ec226; padding:20px 10px 5px 10px; border-radius:10px 0 0 10px; position:fixed; right:0; top:20%; z-index:99*/}
.right-menu a{ color:#FFF; text-align:center; display:flex; margin:0 0 5px; flex-direction: column; align-items: center; }
.right-menu a span{ font-size:13px; line-height: 16px; display:block; }
.website-search{ display:none!important }
.right-menu a img{ width:40px; margin-bottom: 5px; }
footer a{ color:#FFF }
footer a:active, footer a:hover, footer .footer-left ul li a:hover{ color:#4ec226 }

/***About MGL***/
.innerpage-banner{ min-height:auto; position:relative; display:flex; align-items: center;justify-content: space-between; height: 120px; overflow:hidden; }
.innerpage-banner:after{ width:100%; height:100%; background: rgb(255,255,255,0.89); background: linear-gradient(90deg, rgba(255,255,255,1) 11%, rgba(255,255,255,0.258140756302521) 100%); position:absolute; left:0; top:0; content:"" }
.innerpage-banner h1{ font-size:36px; color:#0069b3; font-weight: 700; margin-bottom:5px}
.innerpage-banner h1 span{ font-weight:700;  }
.innerpage-banner .banner-heading{ position:absolute; z-index:99; margin:3% 3% }
.innerpage-banner img{ width:100% }
.innerpage-banner .breadcrumbs ul{ ; }
.innerpage-banner .breadcrumbs ul li:first-child{ padding-left:0 }
.innerpage-banner .breadcrumbs ul li{ display:inline-block; padding-left:20px; position:relative; font-size:12px }
.innerpage-banner .breadcrumbs ul li+li:after{ position:absolute; left:3px; top:0px; content:'\e81f'; font-size: 14px; color:#517e6b; font-family: "unicons"; }
.innerpage-banner .breadcrumbs ul li a{ color:#000 }
.innerpage-banner .breadcrumbs ul li.active{ color:#0069b3 }
.looking-for{ width:90%; position: relative; /*height:160px;*/ }
/*.looking-for:before{ position: absolute;right:0; top: 0; width:88.7%; height:calc(100% - -10px); background-color: #51ad32; content: "";}*/
.looking-for .looking-for-bgimg{ position: absolute; left:0px; top: 0; /*height:170px;*/}
.looking-for .looking-for-services{ display: flex; position: relative; align-items: center; min-height:calc(100% - 20px); }
.looking-for .looking-for-services ul{ display: flex; flex-wrap: wrap; width:72%; align-content: center; min-height:160px}
.looking-for .looking-for-services ul li{ width: 20%; margin: 10px 0;}
.looking-for .looking-for-services ul li abbr{ display: flex; align-items: center;}
.looking-for .looking-for-services ul li abbr img{ margin-right: 12px; transition: 0.3s; width: 50px}
.looking-for .looking-for-services ul li abbr:hover img{ animation-name: dance_hover; animation-duration: 1s; animation-timing-function: ease-in-out; animation-iteration-count: 1; }
.looking-for .looking-for-services ul li abbr h6, .looking-for .looking-for-services ul li h6 abbr{ margin-bottom: 0; color: #FFF; font-size: 0.85vw; line-height: 24px;}
.looking-for .looking-for-services h3{ color:#FFF; margin-bottom: 0; padding-left: 8%; width: 25%;}
.looking-for .looking-for-services h3 span, .looking-for .looking-for-services ul li abbr h6 span{ display: block;}
.looking-for .looking-for-services ul li abbr h6 a{ color:#FFF }
.looking-for .looking-for-services ul li abbr h6 a:hover{ color:##f8bf35}
.looking-for .looking-for-services ul li:hover abbr h6, .looking-for .looking-for-services ul li:hover h6 abbr{ color:#fff600 }

.bn-service:after { background: url(assets/images/pattern-bg.jpg) repeat 0 0; position: absolute; content: ""; height:140px; width: 100%; z-index: -9; top: 55px;}
.marketstats{ position: relative; width: 22%;}
.marketstats .mk-bg{ position: absolute; width: 100%}
.marketstats-content{  z-index: 99; padding:5px 10px 0px 40px; position: relative;}
.marketstats-content ul li p{ margin-bottom: 0; color:#616b88; }
.marketstats-content ul li h6{ color: #000; margin-bottom: 5px; font-size:22px;}
.marketstats-content ul li h6 i{ color: #24ce1a; font-size: 20px;}
/*.marketstats-content ul li h6 span{ color: #63a945; font-weight: 400;}*/
.marketstats-content ul li h6 span{ color: #616b88; font-weight: 400; display: block; font-size: 13px}
.marketstats-content ul li{ margin-bottom: 10px; position: relative; }
.marketstats-content ul li:last-child{ margin-bottom:0 }
/*.marketstats-content ul li i{ color: #24ce1a; font-size: 20px; position:absolute; left:0; top:0 }*/
.innerpage-content-section{ padding:3% 0 }
.latestnews-section{ background: rgb(209,234,254); background: linear-gradient(54deg, rgba(209,234,254,1) 0%, rgba(224,254,228,1) 100%); padding: 30px 20px; border-radius: 6px; }
.latest-posts .latest-post-widget {  padding-top: 15px; border-top: 1px solid rgba(0, 0, 0, 0.06); overflow: hidden; border-radius: 6px}
.latest-posts .latest-post-widget .post-img { width: 100px; float: left; padding-right: 15px; height: 70px;}
.latest-posts .latest-post-widget .post-desc { overflow: hidden; margin-top: 0px;}
.latest-posts .latest-post-widget .post-desc a{ font-size:14px; line-height:18px; font-weight: 500; color: #3d4857; margin-bottom: 5px; display: -webkit-box; max-width: 400px; height:39.2px; -webkit-line-clamp:2;  -webkit-box-orient: vertical; overflow: hidden; text-overflow: ellipsis;}
.latest-posts .latest-post-widget .post-desc .date-post { display: block; font-size: 13px; color: #7fae9c; font-weight: 500; margin-right: 5px; text-shadow: none;}
.latest-posts .latest-post-widget{ padding-top: 15px; border-top: 1px solid rgba(0, 0, 0, 0.06); overflow: hidden;}
.latest-posts .recent-posts .widget-title .title, .customer-aw-faq .widget-title .title{ margin-bottom:10px; color:#3d4857; font-size:26px }
.greenbtn2{ padding:14px 45px 14px 25px; color: #fff!important; background: #51ad32; overflow: hidden; position: relative; display:inline-block; border:none; border-radius:6px }
.greenbtn2 i{ position:absolute; right: 10px; top: 12px; font-size:18px }
.bluebtn2{ padding: 14px 45px 14px 25px; color:#fff!important; background: #037cd1; overflow: hidden; position: relative; display:inline-block; border:none; border-radius:6px}
.bluebtn2 i{ position:absolute; right: 10px; top: 12px; font-size:18px }
.customer-slider .blog-item .blog-content{ background:#ffe100; padding:5%; /*min-height:310px;*/ border-radius:6px 6px 0 0; display: flex; }
.customer-slider .blog-item .blog-content .blog-title a{ font-size:20px; color:#000; font-weight:600; }
.customer-slider .blog-item .blog-content .blog-title { margin-bottom: 5px;}
.customer-slider .blog-item .blog-content .number { width: 40px; height: 40px; background: #FFF; display: flex; font-size: 20px; align-items: center; justify-content: center; margin-right: 10px;box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;}
.customer-slider .blog-item .blog-content .tips-content { width: 80%;}
.customer-slider .blog-item .blog-content .desc { margin-bottom: 0;}
.customer-slider .owl-carousel .owl-item img{ height:250px }
.customer-slider .owl-carousel .owl-nav .owl-prev, .customer-slider .owl-carousel .owl-nav .owl-next{ background:none; color: #FFF; border: none; width: 40px}
.customer-slider .owl-carousel .owl-nav{ position:absolute; bottom:20px }
.customer-slider{ position:relative; }
.customer-slider .owl-carousel .owl-nav .owl-prev i, .customer-slider .owl-carousel .owl-nav .owl-next i{ font-size:50px }

.left-blog-section{ padding: 30px 20px; /*box-shadow: 0 6px 25px #eee;*/ border-radius:6px; box-shadow: rgb(9 30 66 / 8%) 0px 4px 8px -2px, rgb(9 30 66 / 5%) 0px 0px 0px 1px}
.left-blog-posts .left-blog-post-widget { margin-top: 15px; padding-top: 15px; border-top: 1px solid rgba(0, 0, 0, 0.06); overflow: hidden;}
.left-blog-posts .left-blog-post-widget .post-img { width: 100px; float: left; padding-right: 15px; height: 70px;}
.left-blog-posts .left-blog-post-widget .post-desc { overflow: hidden; margin-top: 0px;}
.left-blog-posts .left-blog-post-widget .post-desc a{ font-size:14px; line-height:18px; font-weight: 500; color: #3d4857; margin-bottom: 5px; display: -webkit-box; max-width: 400px; height:38.2px; -webkit-line-clamp: 2;  -webkit-box-orient: vertical; overflow: hidden; text-overflow: ellipsis; }
.left-blog-posts .left-blog-post-widget .post-desc .date-post { display: block; font-size: 13px; color: #868686; font-weight: 500;}
.left-blog-posts .left-blog-post-widget{ margin-top: 15px; padding-top: 15px; border-top: 1px solid rgba(0, 0, 0, 0.06); overflow: hidden;}
.left-blog-posts .recent-posts .widget-title .title{ margin-bottom:10px; color:#037cd1; font-size:26px }
.dual-heading{ font-size:46px; color:#51ad32; font-weight:700 }
.dual-heading span{ font-weight:500; color:#0069b3; display:block; }
.light-blue-bg{ background:#f5fbff; padding:30px; border-radius: 6px; }
.innerpage-right-content .certified{ background:#FFF; padding:20px; text-align:center;  box-shadow: rgba(0, 0, 0, 0.1) 0px 1px 3px 0px, rgba(0, 0, 0, 0.06) 0px 1px 2px 0px; border-radius:4px}
.blue-text{ font-size:24px; color:#0069b3; }
.emergency-control{ background-color:#f3fff5; padding:20px; min-height:460px }
.emergency-control-content{ margin:30px 0 0  }
.emergency-control-content ul{ display:flex; }
.emergency-control-content ul li{ width:50%; padding-left:50px; position:relative; }
.emergency-control-content ul li i{ position:absolute; left:0; color:#85abc5; font-size:35px; }
.sahayogi-dial{ background-color:#fffef2; padding:20px; min-height:460px}  
.blue-tick-list ul li{ position:relative; margin-bottom:15px; padding-left: 25px }
.blue-tick-list ul li:after{ position:absolute; left:0; top:-1px; content:'\e8b0'; font-size:20px; color:#006ec5; font-family: "unicons"; }
.twitter-timeline iframe{ width:auto;  }
.twitter-timeline{ height:600px;}
.twitter-timeline{-webkit-box-shadow: inset 0 0 8px rgba(0,0,0,0.1); scrollbar-color:#67bf8a #d5d5d5; scrollbar-width: thin; overflow-y: scroll}
.twitter-timeline::-webkit-scrollbar{width: 8px; background-color:#f5fbff;}
.twitter-timeline::-webkit-scrollbar-thumb{background-color:#0169b2; border-radius: 40px}
.awards-section .awardsimg{ margin-bottom:15px; overflow:hidden;  }
.awards-section .awardsimg img{ transition: 1.3s all ease; }
.awards-section .awardsimg:hover img { transform: scale(1.2);}
.awards-section p{ font-weight:500; }
.commercial-png-applications i{ margin-bottom:20px; display:inline-block; }
.commercial-png-applications .comercial-heading{ color:#000; font-weight:600; text-transform:uppercase; font-size:20px; margin-bottom:20px }
.commercial-png-applications ul li{ position:relative; margin-bottom:15px; padding-left: 25px; width:48%; display:inline-block; }
.commercial-png-applications ul li:after{ position:absolute; left:0; top:0px; content:'\e8b0'; font-size:20px; color:#006ec5; font-family: "unicons"; }

#menu-toggle { display: none;}
.menu { position: fixed; z-index: 999; right: 0; width:130px; height: auto; padding:8px 0; justify-content: space-between; transition: right 500ms cubic-bezier(0.25, 0.46, 0.45, 0.94);}
.menu .animation, .menu .element { -webkit-transform-origin: 0 0; transform-origin: 0 0; animation: scale-to-right 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;}
.menu #logoCSS3, .menu .element { z-index: 1;}
.menu, .menu html, .menu body { overflow-x: hidden;}
.menu .element, .menu .menu-btn { cursor: pointer;}
.menu, .menu #logoCSS3, .menu .element, .menu .menu-btn { color: #FFFFFF;}
.menu #logoCSS3, .menu .element, .menu .menu-container-btn, .menu .menu-toggle-btn { position: relative;}
.menu .menu-btn, .menu .menu-toggle-btn:after, .menu .menu-toggle-btn:before { position: absolute;}
.menu .fa-bars { display: none;}
.menu .fa-close { display: block;}
.menu #logoCSS3, .menu .elements-container { padding: 0 10px 0 10px;}
.menu #logoCSS3 { font-size: 4em; max-width: 250px;}
.menu .elements-container { max-height:auto; overflow-y: auto;}
.menu .elements-container .element { text-decoration: none; padding:2px 0 2px 10px;}
.menu .elements-container .element:hover { background-color: #47b322;}
.menu .menu-container-btn { overflow: hidden; width: 70px; padding: 35px 0; box-shadow: 609px 0 0 600px #4ec226;}
.menu .menu-container-btn .menu-toggle-btn { height: 70px; border-radius: 0 50% 50% 0;box-shadow: 43px 0 0 0 #4ec226;}
.menu .menu-container-btn .menu-toggle-btn:after, .menu .menu-container-btn .menu-toggle-btn:before {  z-index: -1;  width: 100%; height: 50px; left: 0; content: " ";}
.menu .menu-container-btn .menu-toggle-btn:after { top: 0; border-radius: 0 0 0 100%; box-shadow: 8px -43px 0px 0px #4ec226;}
.menu .menu-container-btn .menu-toggle-btn:before { bottom: -86px; border-radius: 100% 0 0 0; box-shadow: 8px -43px 0px 0px #4ec226;}
.menu .menu-container-btn .menu-toggle-btn .menu-btn { text-shadow: 0 0 5px black; padding: 14px 16px; left: 14px; top: 13px; background-color: #4ec226; border: none; border-radius: 50%;}
#menu-toggle:checked + .menu { right:-68px;}
#menu-toggle:checked + .menu #logoCSS3, #menu-toggle:checked + .menu .elements-container { padding: 0 25px 0 65px;}
#menu-toggle:checked + .menu .fa-close { display: none;}
#menu-toggle:checked + .menu .fa-bars { display: block;}
#menu-toggle:checked + .menu .animation, #menu-toggle:checked + .menu .element { animation: none; opacity: 0;}
#menu-toggle:checked + .menu .menu-container-btn { box-shadow: 640px 0 0 600px #4ec226;}
#menu-toggle:checked + .menu .menu-container-btn .menu-toggle-btn { border-radius: 50% 0 0 50%; background: radial-gradient(ellipse at center, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0) 55%, #4ec226 56%, #4ec226 100%); box-shadow: 76px 0 0 50px #4ec226;}
#menu-toggle:checked + .menu .menu-container-btn .menu-toggle-btn:after, #menu-toggle:checked + .menu .menu-container-btn .menu-toggle-btn:before { left:21px; width: 20px;}
#menu-toggle:checked + .menu .menu-container-btn .menu-toggle-btn:after { top: -48px; border-radius: 0 0 100% 0; box-shadow: 12px 1px 0 10px #4ec226;}
#menu-toggle:checked + .menu .menu-container-btn .menu-toggle-btn:before { top: 68px; border-radius: 0 100% 0 0; box-shadow: 12px 3px 0 12px #4ec226;}
#menu-toggle:checked + .menu .menu-container-btn .menu-toggle-btn .menu-btn { background-color: rgba(0, 0, 0, 0); box-shadow: 0 0 0 12px #4ec226;}
@keyframes scale-to-right {
  0% {
    -webkit-transform: scaleX(0);
    transform: scaleX(0);
    opacity: 0;
  }
  100% {
    -webkit-transform: scaleX(1);
    transform: scaleX(1);
    opacity: 1;
  }
}
.element:nth-child(1n) { animation-delay: 0.25s;}
.element:nth-child(2n) { animation-delay: 0.5s;}
.element:nth-child(3n) { animation-delay: 0.75s;}
.element:nth-child(4n) { animation-delay: 1s;}
.element:nth-child(5n) { animation-delay: 1.25s;}
.element:nth-child(6n) { animation-delay: 1.5s;}
.element:nth-child(7n) { animation-delay: 1.75s;}
.element:nth-child(8n) { animation-delay: 2s;}
.element:nth-child(9n) {animation-delay: 2.25s;}
.element:nth-child(10n) { animation-delay: 2.5s;}


.expand-button{ background-color:#ffe100; color: white; text-decoration: none; border-radius: 60px; height:40px; display: inline-flex; align-items: center; overflow:hidden;  width: auto;  max-width:40px;  -webkit-transition: max-width 0.5s; transition: max-width 0.5s; position:fixed; bottom:9%; left:30px; z-index: 99; border:2px solid #ffe100; box-shadow: 0 0 10px rgb(0 0 0 / 14%)}
.expand-button:hover { max-width: 300px;}
.expand-button .info-icon{  margin-right:5px; padding:0px 7px 4px; display: flex; align-items: center; /*font-size: 20px; color: #7b7021;*/}
.expand-button .info-icon img{ max-width:22px;}
.expand-button .text { white-space: nowrap; padding-right: 15px; color:#000; font-weight:500 }


.search-icon { font-size: 22px; color: #FFF; background-color:#111f4f; border: 0; outline: none; height:32px; border-radius: 6px; width: 40px; display: flex;  align-items: center; justify-content: center;}
.search-toggle .search-icon.icon-close { display: none;}
.search-toggle.opened .search-icon.icon-search { display: none;}
.search-toggle.opened .search-icon.icon-close { display: block;}
.search-container { position: relative; -moz-transition: all 0.3s; -o-transition: all 0.3s; -webkit-transition: all 0.3s; transition: all 0.3s; max-height: 0; overflow: hidden; background-color:#111f4f; border-radius: 0 0 8px 8px}
.search-container.opened { max-height: 100px;}
.search-container input[type="text"]{ outline: none;  font-size:16px; margin: 15px; width:250px; background-color: inherit; border: 0; color: #4ec226}
.search-container input[type="text"]:focus{  background-color: inherit; }
.search-container .search-icon { vertical-align: middle;}
.search-container { position: absolute; right:0px; z-index: 999999;}
.search-container form{ display:flex; }
.customer-login-page{ margin:0% 0 }
.customer-login-page .log-meter-text ul li{ margin-bottom:5px; }
.customer-login-page .log-meter-text ul li a{ font-weight:500; color:#000 }
.customer-login-page .log-meter-text{ margin:20px 0 40px }
.customer-login-page .cu-login-input{ border:1px solid #eaeaea; background:#FFF; padding:8px ; border-radius: 6px; }
.customer-login-page .cu-login-input2{ display:flex; align-items: center; box-shadow: rgba(0, 0, 0, 0.05) 0px 1px 2px 0px; background:#FFF; border-radius: 6px;}
.customer-login-page .cu-login-input input{ width:100%; border-left:1px solid #dadada; padding-left:10px; height: 32px; }
.customer-login-page .cu-login-input2 input{ width:100%; height: 46px; border:1px solid #eaeaea; padding:8px; border-radius: 6px; }
.customer-login-page .upload-input { /*box-shadow: rgba(0, 0, 0, 0.05) 0px 1px 2px 0px; background:#FFF; border-radius: 6px;*/}
.customer-login-page .upload-input input{ width:100%; height:50px; border:1px solid #ced4da; padding:12px 12px; border-radius: 6px;background:#FFF; }
.customer-login-page .cu-login-input i, .customer-login-page .cu-login-input2 i{ color:#24ce1a; margin-right:10px; font-size:20px; }
.customer-login-page .register-now{ color:#000; font-weight:600 }
.customer-aw-faq .rs-faq .faq-content .accordion .card .card-header .card-link{ padding:10px 35px 0 0px; font-size:14px }
.customer-aw-faq .rs-faq .faq-content .accordion .card .card-header .card-link:after{ width: 25px; height: 25px; line-height: 25px; right:0; box-shadow:rgba(9, 30, 66, 0.25) 0px 4px 8px -2px, rgba(9, 30, 66, 0.08) 0px 0px 0px 1px }
.customer-aw-faq .rs-faq .faq-content .accordion .card .card-header{ background:inherit; box-shadow:none; }
.customer-aw-faq .rs-faq .faq-content .accordion .card .card-header .card-link{ background:inherit; box-shadow:none;  overflow:hidden; height:48px }
.customer-aw-faq .rs-faq .faq-content .accordion .card, .customer-aw-faq .rs-faq .faq-content .accordion .card .card-body{background:inherit;}
.customer-aw-faq .rs-faq .faq-content .accordion .card .card-body{ padding:10px 10px 0 0px!important; box-shadow:none; font-size: 14px;}
.customer-aw-faq .rs-faq .faq-content .accordion .card .card-header .card-link:after{ background:#51ad32 }
/*.profile-section1 .profile-photo .porfile-edit{ width:35px; height:35px; display:flex; color:#FFF; background-color:#0383dd; border-radius:40px; }*/
.profile-section1 .profile-name h4{ font-size:22px; }
.profile-section1 .profile-name p{ margin-bottom:5px; color:#607175 }
.profile-section1 .user-address{ background:#FFF; padding:15px ;border-radius: 4px}
.profile-section1 .user-address .user-add-heading{ font-size:16px; margin-bottom:15px; font-weight:600 }
.profile-section1 .user-address ul li{ position:relative; padding-left:30px; margin-bottom:15px; font-size:14px; color:#607175 }
.profile-section1 .user-address ul li i{ position:absolute; left:-7px; top:-2px; color:#0383dd; font-size:20px; }
.profile-section1 .user-address ul li a{ color:#607175; overflow-wrap: break-word; }
.billing-cycle-section{ background:#f7f7f7; padding:20px 15px 20px 75px; position:relative;  }
.billing-cycle-section i{ font-size:38px; color:#0383dd; position:absolute; left:3%; top:18%}
.billing-cycle-section .billing-cy-content b{ display:block; margin-bottom:10px }
.billing-section ul{ display:flex; justify-content: start; flex-wrap: wrap; /*justify-content: space-between;*/ }
.billing-section ul li{ width:23.3%; text-align: center;  }
.billing-section ul li a{ color:#000 }
.billing-section .billing-icon{ width:75px; height:75px; background:#0383dd; border-radius:75px; display:flex; padding:15px; margin:0 auto 10px; }
.light-yellow-bg{ background: #fffef2; padding: 30px; height: 100%; }

.customer-care ul{ display:flex; justify-content: start; flex-wrap: wrap; /*justify-content: space-between;*/}
.customer-care ul li{ width:23.3%; text-align: center;  }
.customer-care ul li a{ color:#000 }
.customer-care .billing-icon{ width:75px; height:75px; background:#51ad32; border-radius:75px; display:flex; padding:15px; margin:0 auto 10px; }
.log-meter-reading label{ color:#607175; font-size:14px; text-shadow: none; /*display:none;*/ }
.log-meter-reading .meter-reading-box{  border: 1px solid #eaeaea; background: #FFF; /*padding:10px 8px 10px 50px;*/ padding:10px 8px 10px 10px; align-items: center; position: relative; border-radius: 6px;}
.log-meter-reading .meter-reading-box i{ position:absolute; left:10px; top:9px; color:#24ce1a; font-size:20px; }
.log-meter-reading .meter-reading-box .meter-cont label{ margin-bottom:10px }
.log-meter-reading .meter-reading-box .meter-cont p{ font-size:18px; margin-bottom:0 }
.log-meter-reading .otp-input{ display: flex; border: 1px solid #eaeaea; background: #FFF; padding: 8px; align-items: center; border-radius: 6px;}
.log-meter-reading .otp-input input { border: none; width: 100%;  height: 32px;}
.log-meter-reading .meter-reading-input-box{ display:flex; margin-top:10px }
.log-meter-reading .meter-reading-input-box li{ width:50px; margin-right:10px  }
.log-meter-reading .meter-reading-input-box li .blueborder-input{ border: 1px solid #85c0e9; }
.log-meter-reading .select-reason select{ border:none; width:100%; margin:5px 0 }
.year-journey{ padding:40px; }
.year-journey .downloadbtn{ padding:18px 65px 18px 25px; color: #fff; background:#037cd1; overflow: hidden; position: relative; display:block; border:2px solid #208fdd; box-shadow:0px 7px 17px 2px rgb(0 0 0 / 15%) }
.year-journey .downloadbtn i{ position:absolute; right:10px; top: 12px; font-size:18px;  }

.news-section-page.rs-blog.blog-main-home .blog-item .blog-content .blog-meta{ background:#0383dd; height:47px; width: 155px; right:0; left:auto; }
.news-section-page.rs-blog.blog-main-home .blog-item .blog-content .blog-meta:after{ border-bottom:47px solid #0383dd;  }
.news-section-page.rs-blog.blog-main-home .blog-item .blog-content .blog-meta .date:after{ display:none; }
.news-section-page.rs-blog.blog-main-home .blog-item .blog-content .blog-title a{ display: -webkit-box; max-width: 400px; height: 50.2px; margin: 0; font-size: 16px; -webkit-line-clamp: 2; -webkit-box-orient: vertical; overflow: hidden; text-overflow: ellipsis; line-height: 24px; }
.news-section-page.rs-blog.blog-main-home .blog-item .blog-content .desc{ display: -webkit-box; max-width: 400px; height: 70.2px; margin: 0; font-size: 16px; -webkit-line-clamp: 3; -webkit-box-orient: vertical; overflow: hidden; text-overflow: ellipsis; line-height: 24px; font-weight: 400; margin-bottom:10px}
.news-section-page.rs-blog.blog-main-home .blog-item .blog-content .blog-title{ margin-bottom:10px }
.news-section-page.rs-blog.blog-main-home .blog-item .blog-content{ padding:22px 22px 22px 22px }
.domestic-png-section{ display:flex; justify-content: space-between; }
.domestic-png-section a i{ border:2px solid #9cd6ff; width:95px; height:95px; padding:15px; border-radius:100px; display:inline-block; }
.domestic-png-section a{ display:block; text-align:center; width:14% }
.domestic-png-section a p{ margin-bottom:0; margin-top:10px; color:#000 }
.benifits-piped-gas{ background:url(assets/images/benifits-piped-gas-bg.jpg) no-repeat 0 0; position: relative; padding: 4% 0; color: #FFF; background-attachment: fixed;} 
.benifits-piped-gas:before{ background:rgba(88, 110, 126, 0.8); width:100%; height:100%; content:""; position:absolute; top:0; left:0 }
.piped-natural-gas-content{ display:flex; position:relative; z-index:99; margin-top:40px }
.piped-natural-gas-content li{ width:20%; text-align:center; padding: 0 15px}
.piped-natural-gas-content li i{ border:2px solid #FFF; width:105px; height:105px; padding:20px; border-radius:120px; display:inline-block; }
.benifits-piped-gas h3{ text-align:center; color:#FFF; position:relative; z-index:99  }
.piped-natural-gas-content li h6{ color:#7cff50; font-size:18px; margin-top:20px; height:44px }
.png-composition-table ul li .space{ width:40%; display:inline-block; }
.png-composition-table ul li{ border-bottom:1px solid #ceebff; padding:15px 15px; display:flex; }
.png-composition-table ul li:first-child{ background:#ceebff}
.share-location-section{ background-color:#f6fff6; padding:40px; border:1px solid #e2fbe2; display:flex; height: 100%; align-items: center;}
.share-location-section .location-details{ width:70% }
.share-location-section .location-details ul li{ padding-left:35px; margin-bottom:20px; position:relative; }
.share-location-section .location-details ul li i{ position:absolute; left:0; top:-3px; color:#51ad32; font-size:22px; }
.share-location-section a{ width:30% }

.select-area{display: flex; border: 1px solid #eaeaea; background: #FFF; padding: 8px; align-items: center; border-radius: 6px; box-shadow: rgba(0, 0, 0, 0.05) 0px 1px 2px 0px}
.select-area select{border: none; width: 100%;margin: 5px 0;}
.station-list th{padding: 10px 10px; background-color:#ceebff; color:#000; border: 1px solid #ceebff}
.station-list td{padding: 10px 10px; border: 1px solid #ceebff}
.station-list td a, .station-list td a i{ color:#1b7cc1; font-size:24px; transition: 0.3s; }
.station-list td a{ cursor:pointer; }
.station-list td{ border-bottom:1px solid #ceebff; background: #FFF; }
.station-list td a:hover i{animation-name: dance_hover; animation-duration: 1s; animation-timing-function: ease-in-out; animation-iteration-count: 1; }

.dance-hover:hover img{ animation-name: dance_hover; animation-duration: 1s; animation-timing-function: ease-in-out; animation-iteration-count: 1; }

.rs-faq.applications-natural-gas .faq-content .accordion .card .card-body{ padding:30px 30px 30px !important }

.rs-faq.billing-faq .faq-content .card .card-body{ padding:30px 30px 30px !important }
.rs-faq.billing-faq .faq-content .accordion .card .card-header .card-link{ height:auto; padding:20px 60px 20px 30px}
.select-faqs a{ background: #ffffff; /*box-shadow:0 0 10px #caf1d6;*/ box-shadow: rgb(9 30 66 / 4%) 0px 4px 8px -2px, rgb(9 30 66 / 4%) 0px 0px 0px 1px; padding:20px 50px; display:block; margin-bottom:10px; color:#000; font-weight:500; position:relative;}
.select-faqs a .left-icon{ position: absolute; left: 12px; top: 18px; color: #0069b3; font-size:18px; }
.select-faqs a .right-icon { position: absolute; right: 10px;top: 14px; color: #4ec226; font-size: 24px;}
.blog-cat .categories a{ background-color:#e7f6ff; padding:8px 12px; display:inline-block; margin-bottom:5px }
.blog-full p, .blog-full ul li{ line-height:24px }
.customer-care-overview-icon .rs-services.style4 .services-item{ padding:20px 20px 20px 20px; min-height:100% }
.customer-care-overview-icon .rs-services.style4 .services-item .services-content .title{ font-size:16px }
.customer-care-overview-icon .rs-services.style4 .services-item .services-content p{ font-size:14px; }
.customer-care-overview-icon .rs-services.style4 .services-item .services-icon { margin-bottom: 17px;  background: #f6fff6; padding: 15px; display: inline-block; border-radius: 80px;}
.customer-care-overview-icon .rs-services.style4 .services-item .services-icon img{ padding:0 }

.customer-care-overview-call .heading-sect{ display:flex; align-items: center;}
.customer-care-overview-call .heading-sect i { background: #f6fff6; border: 1px solid #4ec226; border-radius: 40px; width: 45px; height: 45px; display: flex; justify-content: center; align-items: center; font-size: 22px; color:#4ec226}
.customer-care-overview-call .heading-sect h6{ margin-bottom:0; margin-left:10px; }
.customer-care-overview-call .description-sect{ margin:20px 0 }
.customer-care-overview-call .description-sect span{ font-size:20px; font-weight: 500; }
.customer-care-overview-call{/*box-shadow: 0px 0px 15px 4px #f0f5f9;*/ box-shadow: rgb(9 30 66 / 4%) 0px 4px 8px -2px, rgb(9 30 66 / 4%) 0px 0px 0px 1px; padding: 30px;}
.right-menu-btn{z-index: 999; position: fixed; right: 0; top: 50%; background: #4bbb25; width:50px; height:50px; border-radius:10px 0 0 10px; text-align: center; line-height: 46px; color: #FFF; font-size: 22px; border: 2px solid #ffcf1f; border-width: 2px 0px 2px 2px; box-shadow: 0 0 10px rgb(0 0 0 / 14%)}
.sidenav {height: 100%; width: 0; position: fixed; z-index: 1; top: 0; right: 0; background-color:#4bbc25;  overflow-x: hidden; transition: 0.5s; padding-top: 60px; z-index: 99999;}
.sidenav a { padding: 8px 8px 8px 8px; text-decoration: none; font-size: 25px; color: #FFF; display: block; transition: 0.3s;}
.sidenav a:hover { color: #f1f1f1;}
.sidenav .closebtn { position: absolute; top: 0; right: 5px; font-size: 36px; margin-left: 50px;}
@media screen and (max-height: 450px) {
.sidenav {padding-top: 15px;}
.sidenav a {font-size: 18px;}
}
.white-box{ /*box-shadow: 0px 0px 15px 4px #f0f5f9;*/ box-shadow: rgb(9 30 66 / 4%) 0px 4px 8px -2px, rgb(9 30 66 / 4%) 0px 0px 0px 1px; padding: 30px; background:#FFF}
.tender-setion .tender-head{ border-bottom:1px solid #d4e0ed; padding-bottom:15px; margin-bottom:15px; display:flex; align-items: center; justify-content: space-between; }
.tender-setion .tender-head .tendername{ width:75% }
.tender-setion .tender-head .tendername p, .tender-setion .tender-body .category-sect p{ font-size:13px; color:#9ba8ba; margin-bottom:5px; }
.tender-setion .tender-head .tendername h6, .tender-setion .tender-body .category-sect h6{ font-size:16px; color:#000; margin-bottom:0 }
.tender-setion .tender-body{ display:flex; justify-content: space-between; flex-wrap: wrap; }
.tender-setion .tender-body .category-sect{ width:50%; margin: 0 0 10px 0}
.empanelment{ display:flex; justify-content: space-between; align-items: center; }
.white-box10{ /*box-shadow: 0px 0px 15px 4px #f0f5f9;*/ padding: 20px 20px; background:#FFF; box-shadow: rgb(9 30 66 / 8%) 0px 4px 8px -2px, rgb(9 30 66 / 5%) 0px 0px 0px 1px;}
.empanelment .empanelment-name{ font-weight:600; font-size:16px; padding-right: 10px; width:80% }

.csr-policies .rs-services.style4 .services-item{ padding: 20px 20px 20px 20px; min-height: 150px; display: flex; align-items: center;}
.csr-policies .rs-services.style4 .services-item .services-icon { margin:0 15px 0 0;  background: #f6fff6; padding: 15px; display: inline-block; border-radius: 80px;}
.csr-policies .rs-services.style4 .services-item .services-icon img{ padding:0 }
.light-green-bg{ background-color: #f3fff5; padding:30px; height: 100%; border-radius: 6px;}

.area_table{ width:100%; height: auto; margin-top: 10px; margin-bottom: 0px;}
.area_table ul{ width: 100%; height: auto; display: block;}
.area_table ul li { width: 100%; border-bottom:1px solid #dae1e5; border-left:1px solid #dae1e5; border-right:1px solid #dae1e5; display: table; box-sizing: border-box; text-align: center; background:#FFF}
.area_table ul li:first-child { background:#ecf8ff; border-top:1px solid #dae1e5; font-weight: 600; text-transform: uppercase; text-align: center;}
.area_table ul li:first-child span { padding-left: 0px;}
.area_table ul li span { width: 33.33333%; display: table-cell; border-right:1px solid #dae1e5; vertical-align: middle; padding: 15px ; font-size: 14px; line-height: 18px; color: #616161; box-sizing: border-box;}
.area_table ul li big { width: 33.33333333%; display: table-cell; border-right:1px solid #dae1e5; padding: 10px ; vertical-align: middle; padding-left: 20px; font-size: 14px; color: #616161; box-sizing: border-box; line-height: 18px;}
.area_table ul li small { width: 33.33333%; display: table-cell; vertical-align: middle; padding: 10px;  font-size: 14px;  color: #616161; box-sizing: border-box; line-height: 18px;}
.area_table ul li:first-child .area2, .area_table ul li:first-child .slab1, .area_table ul li:first-child .slab2{ color:#30434e }
.clientele-logo-sect ul{ display:flex; justify-content: space-between; flex-wrap: wrap;}
.clientele-logo-sect ul li{ margin:10px 10px; width:22% }
.clientele-logo{background: #ffffff; box-shadow: 0px 0px 15px 4px #dfe7ed; padding: 40px 30px 40px 30px; border-radius: 5px 5px 5px 5px;}
.latest-details ul li p{ display: -webkit-box; max-width: 400px; height:68.2px; margin: 0;  -webkit-line-clamp:3; -webkit-box-orient: vertical;  overflow: hidden;  text-overflow: ellipsis;   font-weight: 400; }
.latest-details ul li p a{ color:#94d3fe }
.latest-details ul li p a:hover{ color:#FFF }
/***Current Bill***/
.clear{clear:both}
.clr{clear:both}
.Fleft{float:left}
.Fright{float:right}
.inline{display:inline}
.green_box{width:100%;height:auto;background:#4fb847 /*url(../images/top-menubg.jpg) left bottom repeat-x*/}
.wrapper{width:100%;position:relative;margin:0 auto}
.bill-main{max-width:853px;width:100%;margin:0 auto}
.tax-invoice{font-size:11px;font-weight:600;text-transform:uppercase;color:#000;padding-bottom:5px;text-align:right;margin:0}
.mng-top-panel{display:flex;flex-wrap:wrap}
.bill-box1{width:63.5%;height:auto;float:left;padding-right:.5%;box-sizing:border-box;display:flex;flex-wrap:wrap}
.bill-box1-a{width:100%;min-height:108px; border:2px solid #52b049;border-radius:6px;margin-bottom:6px;box-sizing:border-box; display: flex; align-items: center;}
.bill-logo{width:120px;float:left;height:auto;padding:10px;box-sizing:border-box}
.bill-logo img{width:74px;display:block;margin:0 auto}
.bill-box1-a-text{width:calc(100% - 120px);float:left;height:auto;padding:6px 6px 6px 0;box-sizing:border-box;text-align:center}
.bill-box1-a-text h3{font-size:14px;line-height:20px;color:#000;text-transform:none;margin:0;display:inline-block;background:#52b049;padding:2px 15%;border-radius:15px;margin:0 0}
.bill-box1-a-text h2{font-size:22px;line-height:28px;font-weight:800;text-align:center;color:#0074bb;text-transform:uppercase;padding:0;margin:0 0 3px}
.bill-box1-a-text h4{font-size:14px;line-height:20px;font-weight:600;color:#4fb048;text-transform:none;padding-bottom:0;margin:3px 0 0}
.bill-box1-a-text p{font-size:14px;font-weight:400;color:#1a1a18;text-transform:none;line-height:14px;margin:0}
.bill-box1-b{width:156px;min-height:126px;height:auto;background:#fceee5;float:left;border:2px solid #52b049;border-radius:6px;margin-right:6px;box-sizing:border-box;text-align:center}
.bill-box1-b h2{font-size:16px;line-height:22px;font-weight:600;color:#000;padding:2px 0;text-align:center}
.bill-box1-b h2 i{width:16px;height:auto;float:right;margin-left:5px}
.bill-box1-b h2 i img{width:100%}
.bill-box1-b h3{font-size:14px;font-weight:600;color:#000;text-transform:none;margin:0}
.bill-box1-b p{font-size:12px;line-height:16px;font-weight:400;color:#000;text-transform:none;margin:0}
.bill-box1-b h5{font-size:13px;line-height:17px;font-weight:600;color:#e80717;text-transform:none;padding:2px 0;margin:0}
.bill-box1-b h4{font-size:15px;line-height:21px;font-weight:400;color:#000;text-transform:none;padding:2px 0;margin:0}
.bill-box1-c{width:calc(100% - 162px);min-height:126px;float:left;border:2px solid #52b049;border-radius:6px;padding:5px;box-sizing:border-box}
.bill-box1-c h2{font-size:12px;line-height:16px;font-weight:800;color:#000;text-transform:uppercase;padding:0 2px 0 4px;margin:0}
.bill-box1-c p{font-size:12px;line-height:16px;font-weight:800;color:#000;text-transform:none;padding:0 2px 0 4px;margin:0}
.bill-box2{width:36.5%;height:auto;float:left;margin:0;padding:0}
.bill-box2-a{width:100%;min-height:108px;float:left;border:2px solid #52b049;border-radius:6px;display:table;box-sizing:border-box;margin-bottom:6px}
.bill-box2-a ul{width:100%;height:auto;float:left;margin:0;padding:0;border-radius:6px;display:table;background:#edf5e8}
.bill-box2-a ul li{width:100%;height:auto;display:table;text-align:left;box-sizing:border-box;border-bottom:1px solid #52b049}
.bill-box2-a ul li:last-child{border-bottom:0}
.bill-box2-a ul li span{width:120px;height:auto;display:table-cell;text-align:left;padding:7px;border-right:1px solid #52b049;font-size:14px;line-height:20px;font-weight:600;color:#000}
.bill-box2-a ul li small{width:calc(100% - 120px);height:auto;display:table-cell;text-align:left;padding:7px;border-left:0 solid #52b049;font-size:12px;font-weight:600;color:#000}
.bill_group_left{width:50%;height:auto;display:table-cell;border-right:1px solid #FFF;padding:6px 2px 5px;box-sizing:border-box;vertical-align:middle}
.bill_group_left span{display:table-cell;font-size:12px;font-weight:400;color:#000;vertical-align:middle}
.bill_group_left small{display:table-cell;font-size:12px;font-weight:600;color:#000;vertical-align:middle;padding-left:4px}
.bill_group_left.ca{border-right:1px solid #52b049}
.bill_group_left.ca span,.bill_group_left.ca small{color:#52b049!important}
.bill_group_right{width:50%;height:auto;display:table-cell;padding:6px 2px 5px;box-sizing:border-box;vertical-align:middle;box-sizing:border-box}
.bill_group_right strong{display:table-cell;font-size:12px;font-weight:400;color:#000;vertical-align:middle}
.bill_group_right.billFor strong,.bill_group_right.billFor big{color:#52b049!important}
.bill_group_right big{display:table-cell;font-size:12px;font-weight:600;color:#000;vertical-align:middle;padding-left:4px}
.bill_text{font-size:11px;font-weight:400;color:#000;line-height:16px}
.bill_text strong{font-size:12px;font-weight:600;color:#FF0004}
.bill-box2-b{width:100%;height:calc(100% - 114px);float:left;display:table;box-sizing:border-box}
.bill-box2-b ul{display:flex;flex-wrap:wrap;margin:0 -3px;height:100%}
.bill-box2-b ul li{width:50%;padding:0 3px;min-height:126px}
.bill-box2-b ul li article{border:2px solid #52b049;border-radius:6px;width:100%;height:100%;padding:5px}
.bill-box2-b ul li article h2{font-size:18px;line-height:22px;color:#000;font-weight:400;text-align:center}
.bill-box2-b ul li article strong{font-size:20px;line-height:26px;color:#000;font-weight:800;display:block;text-align:center;padding:5px 0}
.bill-box2-b ul li article span{font-size:14px;line-height:18px;color:#000;font-weight:800;display:block;text-align:center;padding:5px 0 0}
.box-left49per{width:50%;height:auto;float:left;box-sizing:border-box;margin:6px 0 0}
.BillDetails{width:100%;height:auto;display:table;margin:0;padding:0;border-left:2px solid #52b049;border-right:2px solid #52b049;border-bottom:2px solid #52b049;box-sizing:border-box;border-radius:0 0 6px 6px;-moz-border-radius:0 0 6px 6px;-webkit-border-radius:0 0 6px 6px}
.BillDetails ul{width:100%;height:auto;margin:0;padding:0;display:table}
.BillDetails ul li{width:100%;height:auto;margin:0;padding:0;border-bottom:1px solid #52b049;display:table;box-sizing:border-box}
.BillDetails ul li:first-child{text-transform:uppercase;text-align:center}
.BillDetails ul li:first-child small{color:#FFF;font-weight:600;font-size:15px}
.BillDetails ul li:last-child{border-bottom:0}
.BillDetails ul li span{width:10%;height:auto;display:table-cell;border-right:1px solid #52b049;text-align:center;vertical-align:middle;font-size:12px;font-weight:400;color:#000;padding:4px;box-sizing:border-box}
.BillDetails ul li small{width:75%;height:auto;display:table-cell;border-right:1px solid #52b049;text-align:left;vertical-align:middle;font-size:12px;font-weight:400;color:#000;padding:4px;box-sizing:border-box}
.BillDetails ul li big{width:15%;height:auto;display:table-cell;text-align:center;vertical-align:middle;font-size:12px;font-weight:400;color:#000;padding:4px;box-sizing:border-box}
.BillDetails ul li strong{width:28%;height:auto;float:left;border-right:1px solid #52b049;box-sizing:border-box;padding:4px;text-align:center;font-size:12px;font-weight:400;color:#000}
.BillDetails ul li strong:first-child{width:16%}
.BillDetails ul li strong.bold{font-weight:800}
.BillDetails ul li b{width:100%;height:auto;float:left;box-sizing:border-box;padding:4px;text-align:left;font-size:12px;font-weight:400;color:#000}
.BillDetails ul li b span{display:inline-block;width:auto;padding:0;font-weight:800;border-right:0}
.BillDetails ul li strong:last-child{border-right:0 solid #000;box-sizing:border-box}
.BillDetails ul li p{width:100%;height:auto;float:left;font-size:12px;font-weight:800;color:#000;padding:4px 5px;margin:0;box-sizing:border-box}
.BillDetailsNew{width:100%;height:auto;display:table;border-top:0 solid #52b049;margin:0;padding:0;border-left:2px solid #52b049;border-right:2px solid #52b049;border-bottom:2px solid #52b049;box-sizing:border-box;border-radius:6px 6px 0 0;-moz-border-radius:6px 6px 0 0;-webkit-border-radius:6px 6px 0 0}
.BillDetailsNew ul{width:100%;height:auto;margin:0;padding:0;display:table}
.BillDetailsNew ul li{width:100%;height:auto;margin:0;padding:0;border-bottom:1px solid #52b049;display:table;box-sizing:border-box;padding:0 7px 0}
.BillDetailsNew ul li.heading{border-bottom:2px solid #52b049}
.BillDetailsNew ul li:last-child{border-bottom:0}
.BillDetailsNew ul li .bdn_invoice_no{display:inline-block;font-size:12px;line-height:16px;font-weight:800;padding:3px 50px 2px 0}
.BillDetailsNew ul li .bdn_invoice_no_val{display:inline-block;font-size:12px;line-height:16px;font-weight:800;padding:3px 0 2px}
.BillDetailsNew ul li:first-child{background:#52b049;text-transform:uppercase;border-radius:4px 4px 0 0;-moz-border-radius:4px 4px 0 0;-webkit-border-radius:4px 4px 0 0;border-bottom:0 solid #52b049;min-height:24px}
.BillDetailsNew ul li:first-child small{color:#FFF;font-weight:600;font-size:15px}
.BillDetailsNew ul li span{width:10%;height:auto;display:table-cell;border-right:1px solid #52b049;text-align:center;vertical-align:middle;font-size:12px;font-weight:400;color:#000;padding:4px;box-sizing:border-box}
.BillDetailsNew ul li small{width:25%;height:auto;display:table-cell;border-right:1px solid #52b049;text-align:left;vertical-align:middle;font-size:12px;font-weight:400;color:#000;padding:4px;box-sizing:border-box}
.BillDetailsNew ul li strong{width:calc(100% - 120px);height:auto;display:table-cell;border-right:2px solid #52b049;box-sizing:border-box;padding:4px 25px 4px 0;font-size:12px;font-weight:400;color:#000;position:relative}
.BillDetailsNew ul li.heading strong{font-weight:800}
.BillDetailsNew ul li.heading2 strong{font-weight:800}
.BillDetailsNew ul li big{width:120px;height:auto;display:table-cell;vertical-align:middle;font-size:12px;font-weight:800;color:#000;padding:4px;box-sizing:border-box;text-align:right}
.BillDetailsNew ul li samp{width:80%;text-align:right;font-weight:700;height:auto;display:table-cell;border-right:1px solid #52b049;box-sizing:border-box;padding:4px;font-size:12px;font-weight:400;color:#000}
.BillDetailsNew ul li bdi{width:60%;text-align:left;font-weight:700;height:auto;display:table-cell;border-right:1px solid #52b049;box-sizing:border-box;padding:4px;font-size:12px;font-weight:400;color:#000}
.BillDetailsNew ul li strong b{position:absolute;right:17px;top:4px}
.BillDetailsNew ul li strong:last-child{border-right:0 solid #000;box-sizing:border-box}
.BillDetailsNew ul li p{width:100%;height:auto;float:left;font-size:12px;font-weight:400;color:#000;padding:4px 5px;margin:0;box-sizing:border-box}
.box-right49per{width:50%;height:auto;float:left;padding-left:6px;box-sizing:border-box;margin-top:6px}
.round-box{width:100%;height:auto;float:left;border:2px solid #52b049;box-sizing:border-box;border-radius:6px}
.round-box span{text-align:center;float:left;width:100%;box-sizing:border-box;padding:4px;font-size:14px;font-weight:600;color:#000;background:#52b049;text-transform:uppercase;border-radius:4px 4px 0 0;-moz-border-radius:4px 4px 0 0;-webkit-border-radius:4px 4px 0 0}
.messages-box{width:100%;height:auto;float:left}
.messages-box .round-box span{text-transform:uppercase;font-weight:600}
.messages-box .round-box small{width:100%;min-height:150px;float:left;font-size:11px;font-weight:400;color:#000;padding:6px;box-sizing:border-box}
.messages-box .round-box small p{font-size:14px;line-height:20px;color:#000;padding-bottom:0px; margin-bottom: 15px;}
.messages-box .round-box small p:last-child{padding-bottom:0}
.messages-box2{width:100%;height:auto;float:left;margin-top:6px}
.messages-box2 .round-box small{width:100%;min-height:145px;float:left;font-size:14px;line-height:18px;font-weight:600;color:#000;padding:6px;box-sizing:border-box;text-align:center}
.messages-box3{width:100%;height:auto;float:left;margin-top:6px;min-height:214px}
.messages-box3 .round-box small{width:100%;float:left;font-size:14px;line-height:18px;font-weight:600;color:#000;padding:6px 6px 0;box-sizing:border-box}
.messages-box3 .round-box small strong{display:block;font-size:14px;line-height:18px;font-weight:800;color:#000;padding-bottom:5px}
.messages-box3 .round-box .declarationLeft{float:left;width:66%;padding:0 0 7px 6px}
.messages-box3 .round-box .declarationLeft strong{display:block;font-size:14px;line-height:18px;font-weight:800;color:#000;padding-bottom:5px}
.messages-box3 .round-box .declarationLeft img{width:43px;display:block;padding:1px 0 6px 6px}
.messages-box3 .round-box .declarationRight{float:left;width:34%;padding:0 0 6px 6px}
.messages-box3 .round-box .declarationRight img{display:block;float:right;padding:6px 12px 0 0}
.acknowledgment2{width:100%;height:auto;float:left;padding-top:4px}
.acknowledgment2 .round-box span{text-transform:uppercase;font-weight:600}
.acknowledgment2 .round-box small{width:100%;min-height:30px;float:left;font-size:12px;font-weight:600;color:#000;padding:4px;box-sizing:border-box}
.box-text{width:100%;min-height:40px;float:left;border:2px solid #52b049;border-radius:6px;font-size:11px;font-weight:400;color:#000;padding:4px;box-sizing:border-box;margin-top:4px}
.status{width:100%;height:auto;float:left;padding-top:4px}
.status .round-box span{text-transform:uppercase;font-weight:600}
.status-table{width:100%;height:auto;float:left;padding:2px;box-sizing:border-box}
.status-table table{border-collapse:collapse;border:1px solid #000!important}
.status-table td{vertical-align:middle;padding:2px;font-size:12px;font-weight:400;color:#000}
.status-table td strong{font-weight:600;text-transform:uppercase;text-align:center}
.int{width:100%;height:auto;float:left;display:block;padding:0 10px;box-sizing:border-box}
.int li{width:100%;height:auto;float:left;padding-bottom:3px;box-sizing:border-box;background:/*url(../images/star.png) left 4px no-repeat*/;padding-left:12px}
.certify-box{width:100%;height:auto;margin-top:4px;display:flex;flex-wrap:wrap}
.certify-box-left{width:35%;height:auto;float:left;margin-right:1%;border:2px solid #52b049;box-sizing:border-box;border-radius:6px;padding:5px;font-size:13px}
.certify-box-right{width:63%;height:auto;float:left;margin-left:1%;border:2px solid #52b049;box-sizing:border-box;border-radius:6px;padding:5px}
.certify-box-right h2{margin:0;padding:0;font-size:12px;font-weight:400;color:#000}
.certify-box-right small{float:left;width:auto;height:auto}
.certify-box-right span{width:auto;height:auto;float:right}
.certify-box-right p{font-size:11px;font-weight:400;color:#000;margin:0;padding:0}
.certify-box-right p strong{font-size:10px;font-weight:600;color:#000;margin:0;padding:0}
.pay-box{width:100%;height:auto;margin-top:5px;display:flex;flex-wrap:wrap}
.box-left49per1{width:49.5%;height:auto;float:left;margin-right:.5%;box-sizing:border-box;border:2px solid #52b049;border-radius:6px}
.box-right49per2{width:49.5%;height:auto;float:left;margin-left:.5%;box-sizing:border-box;border:2px solid #52b049;border-radius:6px}
.paymentslip{width:100%;height:auto;float:left}
.paymentslip ul{width:100%;height:auto;margin:0;padding:0;list-style-type:none}
.paymentslip ul li{width:100%;height:auto;float:left;border-bottom:2px solid #52b049}
.paymentslip ul li:first-child{background:#52b049;text-transform:uppercase;border-radius:4px 4px 0 0;-moz-border-radius:4px 4px 0 0;-webkit-border-radius:4px 4px 0 0;border-bottom:0 solid #52b049;font-size:15px;font-weight:600;color:#000;padding:5px 0;box-sizing:border-box;text-align:center}
.paymentslip ul li:last-child{border-bottom:0 solid #52b049;border-radius:0 0 6px 6px;-moz-border-radius:0 0 6px 6px;-webkit-border-radius:0 0 6px 6px}
.paymentslip ul li > samp{width:50%;height:auto;float:left;padding:5px 4px;box-sizing:border-box}
.paymentslip ul li samp.border-right{border-right:2px solid #52b049!important}
.paymentslip ul li > samp > big{width:auto;height:auto;padding-right:3px;font-size:12px;font-weight:600;color:#000;display:table-cell}
.paymentslip ul li > samp > small{width:auto;height:auto;font-size:12px;font-weight:800;color:#000;display:table-cell}
.paymentslip ul li strong{width:auto;height:auto;padding:5px 4px;font-size:12px;font-weight:600;color:#000;display:table-cell}
.paymentslip ul li em{width:auto;height:auto;font-size:11px;font-weight:800;color:#000;display:table-cell;font-style:normal}
.paymentslip ul li p{width:auto;height:auto;padding:15px 4px;font-size:12px;font-weight:400;color:#000;font-style:normal;margin:0;line-height:14px}
.paymentslip ul li p samp{width:auto;height:auto;font-size:12px;font-weight:800;color:#000}
.youcanpay{width:100%;height:auto;float:left;box-sizing:border-box}
.youcanpay ul{width:100%;height:auto;margin:0;padding:0;list-style-type:none}
.youcanpay ul li{display:flex;flex-wrap:wrap}
.youcanpay ul li:first-child{background:#52b049;text-transform:uppercase;border-radius:4px 4px 0 0;-moz-border-radius:4px 4px 0 0;-webkit-border-radius:4px 4px 0 0;border-bottom:0 solid #52b049;text-align:center;width:100%;box-sizing:border-box;padding:5px 4px;font-size:15px;font-weight:600;color:#000;text-transform:uppercase;font-style:normal;display:block}
.youcanpay ul li:last-child{border-bottom:0 solid #52b049;border-radius:0 0 6px 6px;-moz-border-radius:0 0 6px 6px;-webkit-border-radius:0 0 6px 6px}
.youcanpay ul li big{width:50%;min-height:154px;display:table-cell;box-sizing:border-box;border-right:2px solid #52b049;padding:5px 4px;font-size:12px;font-weight:600;color:#000}
.youcanpay ul li big img{display:block;margin:auto;padding-top:20px}
.youcanpay ul li samp{width:50%;min-height:150px;display:table-cell;box-sizing:border-box;padding:5px 4px;font-size:12px;font-weight:600;color:#000}
.youcanpay ul li samp img{display:block;margin:auto;padding-top:20px}
.text10{text-align:center;font-size:12px;font-weight:600;color:#000;margin:10px 0}
.text10 em{display:block;font-style:normal;padding-top:5px;font-weight:400}
.text11{text-align:center;font-size:12px;font-weight:600;color:#000;margin:10px 0;background:#52b049;padding:5px;display:block}
.text12{text-align:center;font-size:12px;font-weight:600;color:#000;margin:10px 0}
.text9{text-align:center;font-size:10px;font-weight:400;color:#000;margin-top:10px;margin-bottom:10px}
.text9 a{font-size:11px;font-weight:400;color:#000}
.text9 a:hover{font-size:11px;font-weight:400;color:#000;text-decoration:underline}
.backpage-left{width:50%;height:auto;float:left;padding-right:.5%;box-sizing:border-box}
.backpage-right{width:50%;height:auto;float:left;padding-left:.5%;box-sizing:border-box}
.terms{width:100%;height:auto;float:left;border:2px solid #52b049;box-sizing:border-box;border-radius:6px;margin:0;background:#edf5e8}
.header-bg{width:100%;height:auto;position:relative;border-radius:6px}
.header-bg img{border-radius:4px 4px 0 0;-moz-border-radius:4px 4px 0 0;-webkit-border-radius:4px 4px 0 0;width:100%}
.terms span{float:left;text-align:center;width:100%;box-sizing:border-box;padding:4px;font-size:14px;font-weight:600;color:#000;text-transform:uppercase;background:#52b049;text-transform:uppercase;border-radius:4px 4px 0 0;-moz-border-radius:4px 4px 0 0;-webkit-border-radius:4px 4px 0 0}
.terms h5{font-size:24px;line-height:28px;color:#000;font-weight:800;text-align:center;width:190px;margin:28px auto 0;position:relative}
.terms h5:after{content:'';background:;position:absolute;right:-55px;top:18px;width:50px;height:36px}
.terms h6{font-size:20px;line-height:24px;color:#000;font-weight:400;text-align:center;padding:3px 0 30px}
.terms p{font-size:11px;font-weight:400;color:#000;padding:5px 5px 0 20px;margin:0}
.terms p a{font-size:11px;font-weight:600;color:#393e8b;text-decoration:underline}
.terms ul{width:100%;height:auto;float:left;margin:0;padding:5px 8px;box-sizing:border-box}
.terms ul li{background:;width:100%;height:auto;font-size:11px;font-weight:400;color:#000;display:block;padding-bottom:3px;box-sizing:border-box;padding-left:12px;line-height:13px}
.terms ul li i img{text-align:left;vertical-align:text-top;padding-top:2px}
.terms ul li big{font-size:9px;font-weight:400;color:#000;display:table-cell;padding-bottom:3px;box-sizing:border-box;text-align:left;vertical-align:top}
.ShouldKnow{width:100%;height:auto;float:left;border:2px solid #52b049;box-sizing:border-box;border-radius:6px;margin:6px 0 0;position:relative;background:#edf5e8}
.ShouldKnow span{background:#52b049;float:left;text-transform:uppercase;border-radius:4px 4px 0 0;-moz-border-radius:4px 4px 0 0;-webkit-border-radius:4px 4px 0 0;text-align:center;width:100%;box-sizing:border-box;padding:4px;font-size:12px;font-weight:600;color:#000;text-transform:uppercase}
.ShouldKnow ul{width:100%;height:auto;float:left;margin:0;padding:5px 8px;box-sizing:border-box}
.ShouldKnow ul li{width:100%;height:auto;float:left;font-size:12px;line-height:16px;font-weight:400;color:#000;padding-bottom:7px;box-sizing:border-box;padding-left:12px;position:relative}
.ShouldKnow ul li:before{content:'';width:5px;height:5px;background:#000;border-radius:50%;position:absolute;left:0;top:4px}
.ShouldKnow ul li b{text-decoration:underline;font-weight:800}
.ShouldKnow ul li a{color:#3f3b90;text-decoration:underline}
.ShouldKnow ul li big{font-size:9px;font-weight:400;color:#000;display:table-cell;box-sizing:border-box;text-align:left;vertical-align:top}
.ShouldKnow2{width:100%;height:auto;float:left;border:2px solid #52b049;box-sizing:border-box;border-radius:6px;margin-bottom:2px;position:relative;margin-top:6px;padding:5px 8px;background:#edf5e8}
.ShouldKnow2 strong{font-size:12px;line-height:16px;color:#000;font-weight:600;padding-bottom:8px;display:block}
.ShouldKnow2 p{font-size:12px;line-height:16px;color:#000;padding-bottom:8px}
.ShouldKnow2 p:last-child{padding-bottom:0}
.ShouldKnow3{width:100%;height:auto;float:left;border:2px solid #52b049;box-sizing:border-box;border-radius:6px;margin:6px 0 0;position:relative;background:#edf5e8;min-height:264px}
.ShouldKnow3 span{float:left;text-transform:uppercase;border-radius:4px 4px 0 0;-moz-border-radius:4px 4px 0 0;-webkit-border-radius:4px 4px 0 0;text-align:center;width:100%;box-sizing:border-box;padding:4px;font-size:12px;font-weight:600;color:#000;text-transform:uppercase;border-bottom:2px solid #52b049}
.ShouldKnow3 .contArea{padding:36px 8px;display:block;float:left}
.ShouldKnow3 p{font-size:14px;line-height:18px;display:block;font-weight:700}
.ShouldKnow4{width:100%;height:auto;float:left;border:2px solid #52b049;box-sizing:border-box;border-radius:6px;margin:6px 0 0;position:relative;background:#edf5e8;padding-bottom:15px}
.ShouldKnow4 span{background:#52b049;float:left;text-transform:uppercase;border-radius:4px 4px 0 0;-moz-border-radius:4px 4px 0 0;-webkit-border-radius:4px 4px 0 0;text-align:center;width:100%;box-sizing:border-box;padding:4px;font-size:12px;font-weight:600;color:#000;text-transform:uppercase}
.ShouldKnow4 ul{width:100%;height:auto;float:left;margin:0;padding:20px 8px 5px;box-sizing:border-box}
.ShouldKnow4 ul li{width:100%;height:auto;float:left;font-size:12px;line-height:16px;font-weight:600;color:#000;padding-bottom:5px;box-sizing:border-box;position:relative}
.ShouldKnow4 ul li strong{font-weight:800px}
.ShouldKnow4 p{width:100%;height:auto;float:left;font-size:12px;line-height:16px;font-weight:600;color:#000;box-sizing:border-box;position:relative;padding:0 8px 10px}
.ShouldKnow4 p em{font-weight:600;font-style:normal;text-decoration:underline}
.ShouldKnow5{width:100%;height:auto;float:left;border:2px solid #52b049;box-sizing:border-box;border-radius:6px;margin:6px 0 0;position:relative;background:#edf5e8;min-height:360px}
.ShouldKnow6{width:100%;float:left;border:2px solid #52b049;box-sizing:border-box;border-radius:6px;margin:6px 0 0;position:relative;background:#edf5e8}
.ShouldKnow6 .qrcodeRow{display: flex; justify-content: space-between;}
.ShouldKnow6 .qrcodeRow .qrcodeCol{width:50%;padding:20px 10px 39px}
.ShouldKnow6 .qrcodeRow .qrcodeCol h2{font-size:16px;line-height:20px;color:#2b2829;text-align:center;font-weight:600; min-height: 40px}
.ShouldKnow6 .qrcodeRow .qrcodeCol img{display:block;margin:auto}
.bill-history{width:100%;height:auto;display:table;border-left:1px solid #52b049;border-right:1px solid #52b049;border-top:1px solid #52b049;margin:6px 0 0;padding:0;box-sizing:border-box;border-radius:4px}
.bill-history li{width:100%;height:auto;border-bottom:1px solid #52b049;display:table}
.bill-history li:first-child{background:#52b049;text-transform:uppercase;border-radius:4px 4px 0 0;-moz-border-radius:4px 4px 0 0;-webkit-border-radius:4px 4px 0 0}
.consumption{width:45%;height:auto;display:table-cell;vertical-align:middle;padding:0 4px;box-sizing:border-box;font-size:10px;font-weight:400;color:#000}
.consumption_first{font-size:15px;font-weight:600;color:#FFF;padding:4px 5px;text-transform:uppercase}
.consumption big{display:block;font-size:10px;font-weight:600;color:#000;float:left;width:100%;padding-bottom:3px}
.consumption span{display:block;font-size:12px;font-weight:400;color:#000;padding:2px 0}
.consumption samp{display:inline-block;font-size:12px;font-weight:600;color:#000;padding-left:3px}
/*.history{height:auto;display:table-cell;vertical-align:middle;padding:5px 7px;box-sizing:border-box;font-size:12px;font-weight:400;color:#000;margin:0}*/
.history_first{font-size:15px;font-weight:600;color:#FFF;text-align:center;padding:4px 5px;text-transform:uppercase}
.history > span{width:25%;float:left;font-size:12px;font-weight:600;color:#000;text-align:center}
.meter_box{width:100%;height:auto;display:table;margin:15px 0}
.box-round2{width:100%;height:auto;float:left;border:1px solid #52b049;border-radius:6px;font-size:11px;font-weight:400;color:#000;padding:2px;box-sizing:border-box;margin-bottom:2px;overflow-wrap:break-word;word-wrap:break-word;-ms-word-break:break-all;word-break:break-all;word-break:break-word;-ms-hyphens:auto;-moz-hyphens:auto;-webkit-hyphens:auto;hyphens:auto;text-align:center;padding:5px 0}
.box-round2 p{font-size:11px;font-weight:400;color:#000;text-align:left;padding:5px 3px;margin:0;overflow-wrap:break-word;word-wrap:break-word;-ms-word-break:break-all;word-break:break-all;word-break:break-word;-ms-hyphens:auto;-moz-hyphens:auto;-webkit-hyphens:auto;hyphens:auto}
.box-round2 samp{text-align:center;display:block;font-weight:600}
.box-round2 p a{font-size:11px;font-weight:600;color:#393e8b;text-decoration:underline}
.general{float:left;display:block;text-align:center;width:100%;box-sizing:border-box;font-size:11px;font-weight:600;color:#FFF;margin-bottom:2px}
.general h2{background:#52b049;float:left;text-transform:uppercase;border-radius:6px 6px 0 0;-moz-border-radius:6px 6px 0 0;-webkit-border-radius:6px 6px 0 0;text-align:center;width:100%;box-sizing:border-box;padding:4px;font-size:12px;font-weight:600;color:#FFF;text-transform:uppercase}
.box-round3{width:100%;height:auto;float:left;border:1px solid #52b049;border-radius:6px;box-sizing:border-box;margin-bottom:2px;display:table}
.box-round3 h2{margin:0;width:100%;padding:5px 0 4px;text-align:center;font-size:14px;font-weight:600;color:#000}
.box-round3a{width:100%;height:auto;float:left;display:table}
.box-round3a big{width:50%;margin:0;padding:5px 0;text-align:center;font-size:16px;font-weight:600;color:#FFF;border-right:1px solid #FFF;box-sizing:border-box;display:table-cell;background:#52b049}
.box-round3a small{width:50%;margin:0;padding:5px 0;text-align:center;font-size:16px;font-weight:600;color:#FFF;border-left:1px solid #FFF;box-sizing:border-box;display:table-cell;background:#52b049}
.box-round3b{width:100%;height:auto;float:left;display:table}
.box-round3b span{width:50%;margin:0;padding:4px 0;text-align:center;font-size:11px;font-weight:400;color:#000;border-right:1px solid #52b049;box-sizing:border-box;display:table-cell}
.box-round3b strong{width:50%;margin:0;padding:4px 0;text-align:center;font-size:11px;font-weight:400;color:#000;border-left:1px solid #52b049;box-sizing:border-box;display:table-cell}
.small-gas-hin{width:49%;height:auto;float:left;margin-right:1%;border:1px solid #52b049;position:relative;box-sizing:border-box;border-radius:3px;margin-bottom:2px}
.small-gas-eng{width:49%;height:auto;float:left;margin-left:1%;border:1px solid #52b049;position:relative;box-sizing:border-box;border-radius:3px;margin-bottom:2px}
.round-box2{width:100%;height:auto;float:left;border:2px solid #52b049;box-sizing:border-box;border-radius:6px;padding:10px;background:#edf5e8}
.meter_box_right{width:100%;float:left;box-sizing:border-box;vertical-align:top;display:flex;flex-wrap:wrap}
.meter_box_right img{display:block;width:100%;border:2px solid #52b049;border-radius:6px;background:#edf5e8;padding:6px}
.meter_box2a{width:100%;float:left}
.meter_box2a h2{font-size:10px;font-weight:600;color:#000;padding:4px;margin:0}
.meter_box2a ul{width:100%;height:auto;list-style-type:none}
.meter_box2a ul li{width:100%;height:auto;padding-bottom:3px;box-sizing:border-box;display:table}
.meter_box2a ul li big{height:auto;font-size:8px;font-weight:400;color:#000;display:table-cell;text-align:left;vertical-align:top}
.meter_box2a ul li span{width:16px;height:auto;display:table-cell;text-align:left;vertical-align:top;padding-left:3px;box-sizing:border-box}
.meter_box2a ul li span.red{width:100%;height:auto;font-size:8px;font-weight:600;color:#FF0004}
.hours24{background:#fceee5;border:1px solid #52b049;border-radius:6px;width:100%;height:auto;padding:8px;box-sizing:border-box;text-align:center;margin-bottom:2px}
.hours24:after{content:'';display:block;clear:both}
.hours24 h3{width:auto;display:block;font-size:15px;font-weight:600;color:#000;text-transform:uppercase;position:relative;margin:0;padding:0}
.hours24 h4{font-size:12px;font-weight:600;color:#000;padding:3px 0;margin:0}
.hours24 p{font-size:11px;font-weight:400;color:#000;margin:0;padding:0}
.unresolved{width:100%;height:auto;float:left;border:1px solid #52b049;border-radius:6px;padding:4px;box-sizing:border-box}
.unresolved h6{font-size:11px;font-weight:600;color:#000;padding-bottom:2px}
.unresolved ul{width:100%;height:auto;display:block}
.unresolved ul li{width:100%;height:auto;display:block;font-size:11px;font-weight:400;color:#000;padding-bottom:4px;float:left}
.unresolved ul li strong{width:28%;height:auto;float:left;font-size:11px;font-weight:600;color:#000;line-height:12px}
.unresolved ul li span{width:27%;height:auto;float:left;font-size:11px;font-weight:400;color:#000;line-height:12px}
.unresolved ul li samp{width:45%;height:auto;float:left;font-size:11px;font-weight:400;color:#000;line-height:12px}
.unresolved p{width:100%;height:auto;display:block;font-size:11px;font-weight:400;color:#000;padding-bottom:2px;line-height:12px}
.round-box4{width:100%;height:auto;float:left;border:0 solid #52b049;box-sizing:border-box;margin-top:6px;margin-bottom:20px}
.round-box4 span{background:#52b049;padding:6px 0;text-transform:uppercase;border-radius:4px 4px 0 0;-moz-border-radius:4px 4px 0 0;-webkit-border-radius:4px 4px 0 0;float:left;width:100%;text-align:center;box-sizing:border-box}
.round-box4 span p{font-size:12px;line-height:16px;font-weight:600;color:#000;text-transform:uppercase}
.round-box4 span p img{width:auto!important;padding-top:1px}
.round-box4 table{border:2px solid #52b049!important;border-collapse:collapse}
.round-box4 table td{font-size:10px;line-height:14px;font-weight:400;color:#000;padding:2px 5px;margin:0;overflow-wrap:break-word;word-wrap:break-word;-ms-word-break:break-all;word-break:break-all;word-break:break-word;-ms-hyphens:auto;-moz-hyphens:auto;-webkit-hyphens:auto;hyphens:auto; border:1px solid #52b049}
.round-box4 table td.heading{background:#edf5e8;font-size:12px;line-height:16px;font-weight:600;color:#000;text-align:center!important;overflow-wrap:break-word;word-wrap:break-word;-ms-word-break:break-all;word-break:break-all;word-break:break-word;-ms-hyphens:auto;-moz-hyphens:auto;-webkit-hyphens:auto;hyphens:auto}
.round-box4 table td strong{font-weight:800;padding-bottom:0;display:block}
.round-box4 table td p{padding-bottom:5px; margin-bottom: 0}
.round-box4 table td a{font-size:9px;font-weight:600;color:#393e8b;text-decoration:underline}
.round-box4 table td ul{width:100%;height:auto;list-style-type:none;text-align:left}
.round-box4 table td ul li{width:auto;display:inline-block;padding-right:5px;overflow-wrap:break-word;word-wrap:break-word;-ms-word-break:break-all;word-break:break-all;word-break:break-word;-ms-hyphens:auto;-moz-hyphens:auto;-webkit-hyphens:auto;hyphens:auto;font-size:9px;font-weight:400;color:#FF0004;line-height:13px}
.round-box4 table td ul li i{width:8px;height:auto;float:left;padding-right:4px;box-sizing:border-box}
.readingDetails{display:flex;flex-wrap:wrap;margin:6px -3px}
.readingDetails .readingDetailsCol{width:33.3333333333%;float:left;padding:0 3px}
.readingDetails .readingDetailsCol article{widows:100%;height:100%;display:block;border:2px solid #52b049;border-radius:6px;padding:8px 5px}
.readingDetails .readingDetailsCol article h2{font-size:12px;line-height:16px;font-weight:400;color:#000;text-align:center;padding:0 0 2px}
.readingDetails .readingDetailsCol article span{font-size:14px;line-height:18px;font-weight:600;color:#000;text-align:center;display:block}
.readingDetails .readingDetailsCol article h4{font-size:12px;line-height:16px;font-weight:400;color:#000;text-align:center;padding:6px 0 2px}
.BillDetails2{width:100%;height:auto;display:table;margin:0;padding:0;border-left:2px solid #52b049;border-right:2px solid #52b049;border-bottom:2px solid #52b049;box-sizing:border-box;border-radius:6px;-moz-border-radius:6px;-webkit-border-radius:6px;min-height:214px}
.BillDetails2 ul{width:100%;height:auto;margin:0;padding:0;display:table}
.BillDetails2 ul li{width:100%;height:auto;margin:0;padding:0;border-bottom:1px solid #52b049;display:flex;flex-wrap:wrap;box-sizing:border-box}
.BillDetails2 ul li:first-child{background:#52b049;text-transform:uppercase;border-radius:4px 4px 0 0;-moz-border-radius:4px 4px 0 0;-webkit-border-radius:4px 4px 0 0;border-bottom:0 solid #52b049;min-height:24px}
.BillDetails2 ul li:last-child{border-bottom:0}
.BillDetails2 ul li strong{height:auto;float:left;border-right:1px solid #52b049;box-sizing:border-box;padding:6px 4px;text-align:center;font-size:10px;line-height:14px;font-weight:700;color:#000}
.BillDetails2 ul li:nth-child(2) strong{font-weight:400}
.BillDetails2 ul li strong:nth-child(1){width:20.40302267%}
.BillDetails2 ul li strong:nth-child(2){width:24.4332493703%}
.BillDetails2 ul li strong:nth-child(3){width:8.8161209068%}
.BillDetails2 ul li strong:nth-child(4){width:21.9143576826%}
.BillDetails2 ul li strong:nth-child(5){width:24.4332493703%}
.BillDetails2 ul li strong.bold{font-weight:800}
.BillDetails2 ul li strong img{display:block;margin:auto;height:25px}
.BillDetails2 ul li b{width:100%;height:auto;float:left;box-sizing:border-box;padding:4px;text-align:left;font-size:12px;font-weight:400;color:#000}
.BillDetails2 ul li b span{display:inline-block;width:auto;padding:0;font-weight:800;border-right:0}
.BillDetails2 ul li strong:last-child{border-right:0 solid #000;box-sizing:border-box}
.BillDetails2 ul li p{width:100%;height:auto;float:left;font-size:12px;font-weight:800;color:#000;padding:4px 5px;margin:0;box-sizing:border-box}
.ConsBox{margin:6px 0 0}
.ConsBox .ConsBoxCont{padding:6px 10px}
.ConsBox .ConsBoxCont h2{display:block;font-size:14px;line-height:18px;font-weight:800;color:#000;padding-bottom:2px}
.ConsBox .ConsBoxCont p{display:block;font-size:14px;line-height:18px;font-weight:600;color:#000;padding-bottom:5px}
.ConsBox .ConsBoxCont p em{font-size:14px;line-height:18px;font-weight:800;color:#000;font-style:normal;border-bottom:1px dotted #000;padding:0 20px}
.ConsBox .ConsBoxCont p samp{background:/*url(../images/text-arrow-icon.jpg) no-repeat 0 0*/;width:15px;height:8px;display:inline-block}
.ConsBox .ConsBoxCont strong{display:block;font-size:14px;line-height:18px;font-weight:700;color:#000;padding-bottom:5px;}
.print_box3{width:96.777%;height:50px;float:left;border-bottom:1px solid #bfbfbf;padding:0 0 10px;margin-bottom:20px}
.print_box3 a.print{font-size:14px;font-weight:400;color:#000;line-height:30px;position:relative;padding-left:25px;float:right}
.print_box3 a.mv_text{font-size:14px;font-weight:600;color:#000;line-height:30px;position:relative;padding:0 25px 0 0;float:right}
.print_box3 a.mv_text:hover{color:#57b23d}
.print_box3 a.print:after{content:'\f02f';font-family:'FontAwesome';font-size:22px;font-weight:400;color:#000;position:absolute;left:0;top:0}
.print_box3 a.print:hover{color:#57b23d}
.print_box3 a.print:hover:after{color:#57b23d}
.marathi_version{width:96.777%;height:50px;float:left;border-bottom:1px solid #bfbfbf;padding:0 0 10px;margin-bottom:20px}
.marathi_version a{font-size:16px;font-weight:600;color:#000;line-height:30px;position:relative;padding-left:25px;float:right}
.marathi_version a:hover{color:#57b23d}
.marathi_version a:hover:after{color:#57b23d}
.text-img-connect{position:relative;display:inline-block;min-width:105px;height:10px;text-align:center}
.text-img-connect img{position:absolute;top:0;left:10px}
.declar small{font-size:12px!important}
.declar strong{font-size:12px!important}
@media only screen and (max-width: 1024px) {
.bill-main{max-width:inherit;width:96%;margin:0 auto}
}
@media only screen and (max-width: 800px) {
.bill-box1{width:100%;padding-right:0}
.bill-box2{width:100%;margin-top:6px}
.box-left49per{width:100%;padding-right:0}
.box-right49per{width:100%;padding-left:0}
.box-left49per1{width:100%;margin-right:0}
.box-right49per2{width:100%;margin-left:0;margin-top:6px}
.backpage-left{width:100%;padding-right:0}
.backpage-right{width:100%;padding-left:0;margin-top:6px}
.ShouldKnow3{min-height:inherit}
.ShouldKnow5{min-height:150px}
.messages-box3{min-height:inherit}
.messages-box .round-box small p{font-size:12px;line-height:16px;color:#000;padding-bottom:10px}
}
@media only screen and (max-width: 640px) {
.bill-box2{margin-top:6px}
}
@media only screen and (max-width: 480px) {
.bill-box1-b{width:100%;margin-right:0}
.bill-box1-c{width:100%;margin-top:6px}
.messages-box .round-box small{min-height:inherit}
.certify-box-left{width:100%;margin-right:0;margin-bottom:2px}
.certify-box-right{width:100%;margin-left:0}
.bill-box1-b h2{ margin-bottom:5px }
}

.table{ border:1px solid #dee2e6;}
table, td, th{ border: 1px solid #dee2e6; border-collapse: collapse; }

.table th{ background-color:#f4faff; padding:.8rem .8rem;border-width:1px  }
.table td{ padding:.8rem .8rem;border-width:1px  }
.ignore { width: 100%; max-width: 240px; margin-right: 20px; float: left;}
.ignore samp { width: 20px; height: 20px; background: #FF0004; font-size: 14px; font-weight: 600; color: #FFF; text-align: center; line-height: 20px; display: block; margin-right: 4px; float: left;}
.remember{ width: 100%; height: auto;}
.remember li { width: 100%;   font-size: 14px;  font-weight: 400;  color: #616161;  padding-bottom: 8px;  line-height: 20px;}
.least{ width: 100%; max-width: 550px; margin-right: 20px; float: left;}

/***Customer zone Dashboard Billing Info***/
.billinginfo_table { width:100%; height: auto; margin-top: 10px; margin-bottom: 20px; font-size: 14px}
.billinginfo_table ul li:first-child { background: #ecf8ff; border-top: 1px solid #dae1e5; font-weight: 600; text-transform: uppercase;  text-align: center;}
.billinginfo_table ul li{ width: 100%; border-bottom: 1px solid #dae1e5; border-left: 1px solid #dae1e5; border-right: 1px solid #dae1e5; display: table; box-sizing: border-box;}
.billinginfo_table ul li span{ display: table-cell; border-right: 1px solid #dae1e5; text-align: center;padding: 10px 10px 10px 10px; }
.billinginfo_table ul li span.BillNo { width: 16%;}
.billinginfo_table ul li:first-child span {padding-left: 0px;}
.billinginfo_table ul li span.BillDate { width: 16%;}
.billinginfo_table ul li span.DisplayDate { width: 16%;}
.billinginfo_table ul li span.DueDate { width: 16%;}
.billinginfo_table ul li span.NetPay { width: 16%;}
.billinginfo_table ul li span.ViewBill {width: 10%;}
/***Customer Zone Payment History***/
.payments_table {width: 100%;}
.payments_table ul {width: 100%;}
.payments_table ul li { width: 100%; border-bottom: 1px solid #dae1e5; border-left: 1px solid #dae1e5; border-right: 1px solid #dae1e5; display: table; box-sizing: border-box;}
.payments_table ul li:first-child { background: #ecf8ff;  border-top: 1px solid #dae1e5; font-weight: 600; text-transform: uppercase;  text-align: center;}
.payments_table ul li span{  display: table-cell; border-right: 1px solid #dae1e5; text-align: center; vertical-align: middle; font-size: 14px; line-height: 18px; color: #616161; box-sizing: border-box; padding: 10px}
.payments_table ul li span.InstrumentNo, .payments_table ul li span.PaymentDate, .payments_table ul li span.PaymentSource, .payments_table ul li span.Amount{ width: 16%; display: table-cell;}


/***Leadership***/
.chairman-section{ box-shadow:0 6px 25px #eee; padding:30px; display:flex; align-items: center; }
.chairman-section .chairman-photo{ width:40%; display:flex;align-items: flex-end; }
.chairman-section .chairman-details{ width:60% }
.chairman-section .chairman-details p{ font-size:14px; margin-bottom:10px;text-align: justify; }
.chairman-section .chairman-details h4{ margin-bottom:10px }
.oth-directors .oth-director-photo{ border:1px solid #efefef; margin-bottom:10px; box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 6px -1px, rgba(0, 0, 0, 0.06) 0px 2px 4px -1px; }
.oth-directors .oth-directors-des h4{ font-size:18px; margin-bottom:5px }
.oth-directors .oth-directors-des p{ color:#767676; margin-bottom:0; font-size:14px; }
.directors-sd  .btn-close{ position:absolute; right:-55px; top:0; background: #FFF; width: 35px; height: 35px; color:#000 }
.director-modal{ display:flex;  }
.director-modal .director-photo{ width:40%; display:flex;align-items: flex-end; padding-right: 15px; }
.director-modal .director-details{ width:60%; padding:0 10px 0 0 }
.director-modal .director-details p{ font-size:14px; margin-bottom:10px;}
.director-modal .director-details h4{ margin-bottom:10px }
.director-modal .modal-body .director-details p, .oth-directors .modal-body .oth-directors-des p, .chairman-section .modal-body .chairman-details p, .directors-sd .modal-body .director-details p{ text-align: justify; }
button, a{ transition: all 0.4s; }
/*.greenbtn2:hover{ background:#35811b }*/
.greenbtn2, .greenbtn, .blog-know-btn a, .bluebtn, .bluebtn2{ 
  overflow: hidden; position: relative;z-index:1; box-shadow:rgba(9, 30, 66, 0.25) 0px 4px 8px -2px, rgba(9, 30, 66, 0.08) 0px 0px 0px 1px }
.greenbtn2:after, .greenbtn:after, .blog-know-btn a:after, .bluebtn:after{ 
  content: ""; position: absolute; top: 10px; left: 0; width: 300px; height: 200px; background-color:#484848; border-color: transparent; border-radius: 50%; transform: translate(-40px, -80px) scale(0.1); opacity: 0; z-index: -1; transition: transform 0.5s, opacity 0.5s, background-color 0.5s;}
.greenbtn2:hover:after, .greenbtn:hover:after, .blog-know-btn:hover a:after, .bluebtn:hover:after{ 
  opacity: .3; transform-origin: 100px 100px; transform: scale(1) translate(-10px, -70px); }
.blue-border-btn{ border:1px solid #006ec5; padding:3px 30px 3px 10px; border-radius: 4px; position:relative; background:none; color:#006ec5 }
.blue-border-btn i{ position:absolute; right:4px; top:2px }
.blue-border-btn:hover{ background:#006ec5; color:#FFF }
.blue-border-btn:hover i{ color:#FFF }
.nse-bse-section .nse-bse-content p span{ background-color:rgb(222 241 255); padding:3px; border-radius: 4px; }
.right-menu-btn:hover{ border:2px solid #4bbb25; }
.sidenav a:hover{ background:#42ab1e }
.sidenav a:hover img, .rs-services.style2 .service-wrap:hover .icon-service{ animation-name: dance_hover; animation-duration: 1s; animation-timing-function: ease-in-out; animation-iteration-count: 1; }
.follow-us-on{ margin-top:0px; display: flex; flex-direction: column; align-items: flex-start}
.if-you-smell-gas.rs-services.style2 .service-wrap{ min-height:100% }
.light-yellow-bg .rs-services.style4 .services-item{ box-shadow: rgb(9 30 66 / 4%) 0px 4px 8px -2px, rgb(9 30 66 / 4%) 0px 0px 0px 1px; /*box-shadow:0px 0px 15px 4px #edebdf*/ }
body .modal.show .modal-dialog.modal-video{ max-width:fit-content }
.customer-login-page .cu-login-input .form-control{ border-left:1px solid #ced4da; border-width:0 0 0 1px; border-radius:0 }
.cng-kit-suppiler .rs-faq .faq-content .accordion .card .card-header .card-link{ padding:20px 30px 20px 50px }
.cng-kit-suppiler .rs-faq .faq-content .accordion .card .card-header .card-link i{ position:absolute; left: 9px; font-size: 24px; color: #016ab3; top:14px; }
.commercial-png-applications i{ width:70px; height:70px }
 body .directors-sd.modal.show .modal-dialog{ max-width:90% }
 
 /***MGL Spirit statement***/
.spirit-stat{ background: #f5fbff;border:1px solid #e6f4fd; padding:10px 30px; display:flex;  align-items: center;}
.spirit-stat .icon-numb{ position:relative; width:15% }
.spirit-stat .icon-stat{ width: 88px; height: 88px; background: #FFF; padding: 20px; border-radius: 100%;  box-shadow: 0px 16px 20px 0px rgb(69 94 60 / 11%);}
.spirit-stat .icon-desc{ width:80% }
.spirit-stat .icon-numb i { position: absolute;left: -10px; top: 0px; background: rgb(4,119,200); background:linear-gradient(59deg, rgb(255 244 163) 0%, rgb(255 225 0) 100%); width:32px; height:32px; border-radius: 40px; /* text-align: center; */ display: flex; align-items: center; justify-content: center; color:#7f7320; font-style: normal; font-weight: 600;}

.csr-initiatives .rs-services.style4 .services-item{ padding: 20px 20px 20px 20px; min-height: 100%; display: flex; align-items: flex-start; flex-direction: column;}
.csr-initiatives .rs-services.style4 .services-item .services-icon { margin:0 15px 0 0;  background: #f6fff6; padding: 15px; display: inline-block; border-radius: 80px;}
.csr-initiatives .rs-services.style4 .services-item .services-icon img{ padding:0 }
.csr-initiatives .rs-services.style4 .services-item .services-content .title{ font-size:14px; }
.innerpage-banner .banner-heading{ margin:3% 3% }
.greybtn{ padding:14px 45px 14px 25px; color: #fff; background: #a7a7a7; overflow: hidden; position: relative; display: inline-block; border: none; border-radius: 6px; }
.greybtn i{ position:absolute; right:10px; top: 12px; font-size:18px;  }
.customer-login-page .cu-login-input2 textarea{  border: none; width: 100%;height:60px; border: 1px solid #eaeaea; box-shadow: rgba(0, 0, 0, 0.05) 0px 1px 2px 0px; padding: 8px; border-radius: 6px;}
.customer-login-page .cu-login-input2 select{ border: none; width: 100%;height:46px; border:1px solid #eaeaea; box-shadow:rgba(0, 0, 0, 0.05) 0px 1px 2px 0px; padding:0 8px; border-radius: 6px; }
.search-container .search-icon{ height:auto; }
.border-single{ border-bottom:1px solid #dbe8f1; margin:10px 0 }
.applicants li{ margin-bottom:10px }
.dropdown-icon{ position:relative; }
.dropdown-icon::after{ content: '\e81f';  position: absolute; right: 12px;  top:7px; font-family: "unicons";  color:#a5a5a5; }
.csr-policies .rs-services.style4 .services-item .services-content .yearly_savings_text{ font-size:30px; font-weight:700; color: #51ad32; }
.yearly_savings{}
.yearly_savings.csr-policies .rs-services.style4 .services-item .services-content .title{ font-size:16px }
.footer-bottom{ background:#000; padding:30px 20px}
.footer-bottom .privacy-policy-links{ margin:10px 0 0 0}
.footer-bottom .copyright{ padding:5px 0; margin-bottom:0; color:#62727f; text-align:right;}
.footer-bottom .privacy-policy-links a{ padding:0 10px }
.footer-links{ margin-top:5% }
.footer-links ul li{ padding-right:15px; margin-right:15px; border-right:1px solid #FFF; margin-bottom: 10px;}
.footer-links ul li:last-child{ border-right:0; padding-right:0; margin-right:0 }
.download-mgl .app-store-icon a{ width:46% }
 body .videogallery.modal.show .modal-dialog{ max-width:auto }
.videogallery  .btn-close{ position:absolute; right:-55px; top:0; background: #FFF; width: 35px; height: 35px; color:#000 }
.modal.show .btn-close{ position:absolute; right:10px; top:7px; background:#c3c3c3; width:25px; height:25px; color:#000; z-index:999 }
.rs-blog.style2 .blog-item .blog-content .blog-meta .admin{ display: -webkit-box;  max-width:100px; width:100px; height:18.2px; font-size: 14px; -webkit-line-clamp: 1; -webkit-box-orient: vertical; overflow: hidden; text-overflow: ellipsis; line-height: 24px; font-weight: 400; }

.name-transfer-box{ background:#FFF; padding:30px; border:1px solid #deeaf3 }
.name-transfer-box .user-add-heading { font-size: 16px; margin-bottom: 15px; font-weight: 600;}

.sitemap-section{ border-bottom:1px solid #eee; margin-bottom: 20px; padding-bottom:20px }
.sitemap-section h5{ font-size:18px; color: #4bbb25;}
.sitemap-section h6{ font-size:16px; margin-bottom: 10px; }
.sitemap-section .sitemap-sublinks ul > li > a{ color:#000; padding-left:20px; position:relative; }
.sitemap-section .sitemap-sublinks ul > li > a:after{ position:absolute; left:0; top:4px; font-family: "unicons"; content:'\eb50'; color:#000; font-size:8px }
.sitemap-section .sitemap-sublinks ul li{ margin-bottom:5px }
.sitemap-section .sitemap-sublinks ul ul{ padding-left:10px; padding-top:10px; padding-bottom:10px }
.sitemap-section .sitemap-sublinks ul > li > ul > li a:after{ position:absolute; left:0; top:2px; font-family: "unicons"; content:'\e81f'; color:#000; font-size:14px }
.sitemap-section .sitemap-sublinks ul > li > ul > li a{ font-size:13px; }

.form-check-input:checked { background-color: #037cd1; border-color: #037cd1;}
.service-label label{color: #000;  font-size: 16px;}
.service-label label{ display:inline-block; padding-left:10px }
.full-width-header .rs-header .topbar-area.style1 .toolbar-sl-share ul li .dropdown .dropdown-menu{display:none;  }
.full-width-header .rs-header .topbar-area.style1 .toolbar-sl-share ul li .dropdown .dropdown-menu.show{display:block;  }
.full-width-header .rs-header .topbar-area.style1 .toolbar-sl-share ul li .dropdown button{ background:none; border:none; color:#FFF }
.full-width-header .rs-header .topbar-area.style1 .toolbar-sl-share ul li .dropdown button i{ font-size: 22px; line-height: 34px}
.full-width-header .rs-header .topbar-area.style1 .toolbar-sl-share ul li .dropdown .dropdown-menu li{ margin-right:0 }
.full-width-header .rs-header .topbar-area.style1 .toolbar-sl-share ul li .dropdown .dropdown-menu li a:hover{background:#111f4f}
.full-width-header .rs-header .topbar-area.style1 .toolbar-sl-share ul li .dropdown .dropdown-menu li a{ color:#FFF; cursor:pointer; }
.full-width-header .rs-header .topbar-area.style1 .toolbar-sl-share ul li .dropdown .dropdown-menu li .dropdown-divider{ border-top:1px solid #323f6e; }
.full-width-header .rs-header .topbar-area.style1 .toolbar-sl-share ul li .dropdown .dropdown-menu{ background-color:#0a1437; border-radius:0px; border:none }
.authorization-required .main-head-a{ background-color:#0871c4; padding:20px 0; text-align:center; color:#FFF; font-weight:700 }
.must-do .main-head-a{ background-color:#51ae32; padding:20px 0; text-align:center; color:#FFF ; text-transform: uppercase;font-weight:700}
.must-not-do .main-head-a{ background-color:#df0b17; padding:20px 0; text-align:center; color:#FFF ; text-transform: uppercase;font-weight:700}

.authorization-required .services-items, .must-not-do .services-items, .must-do .services-items{ background: #ffffff; box-shadow:0 6px 25px #eee; padding: 40px 30px 40px 30px;  border-radius: 5px 5px 5px 5px; display: flex; flex-direction: column; align-items: center; min-height: 290px}
.authorization-required .services-items.blue-border-btm{ border-bottom:3px solid #0871c4; }
.must-do .services-items.green-border-btm{ border-bottom:3px solid #51ae32; }
.must-not-do .services-items.red-border-btm{ border-bottom:3px solid #df0b17; }

.authorization-required .services-items i, .must-not-do .services-items i, .must-do .services-items i{ margin-bottom:15px; width: 120px}
.authorization-required .services-items p, .must-not-do .services-items p, .must-do .services-items p{ margin-bottom:0; text-align:center; }
.greenbtn3{ padding: 14px 25px; color: #fff; background: #51ad32; overflow: hidden; position: relative; display: inline-block; border: none; border-radius: 6px}
.cng-locators table td, .cng-locators table th{word-break: break-word; border-left:0px; border-right:0; border-top:0 }
.cng-locators table{ border:none; width:100% }
.authorized-personnel{ background: #ffffff; box-shadow: 0px 0px 15px 4px #dfe7ed; padding:30px 30px 30px 30px; border-radius: 5px 5px 5px 5px;  }
.authorized-personnel .authorized-personnel-userImg{ width:120px;height:120px; border-radius:120px; background: #eee;}
.authorized-personnel .authorized-personnel-userImg img{ width: 120px; height: 120px; border-radius: 120px;  object-fit: scale-down; }
.authorized-personnel span{font-size: 13px; color: #9ba8ba; margin-bottom: 5px; font-weight: 500; display: block;}
.authorized-personnel ul li{ font-size: 16px; font-weight: 700; margin-bottom: 20px;}
.authorized-personnel ul{ position:relative; padding-left:150px; }
.authorized-personnel ul li:nth-child(1){ position:absolute; left:0; margin-bottom: 0px;}
.authorized-personnel ul li:nth-child(2){ width:55% }
.authorized-personnel ul li:nth-child(3){ width:35% }
.authorized-personnel ul li:nth-child(4){ width:100%;margin-bottom: 0px; }
.authorized-personnel ul{display: flex; justify-content: space-between; align-items: center;flex-wrap: wrap;}
.sitemap-sublinks ul{ margin-bottom:20px }

.pngpay-setion .pngpay-head{ border-bottom:1px solid #d4e0ed; padding-bottom:15px; margin-bottom:15px; display:flex; align-items: center; justify-content: space-between; }
.pngpay-setion .pngpay-head .bankname{ width:100% }
.pngpay-setion .pngpay-head .bankname p, .pngpay-setion .pngpay-body .category-sect p{ font-size:13px; color:#9ba8ba; margin-bottom:5px; }
.pngpay-setion .pngpay-head .bankname h6, .pngpay-setion .pngpay-body .category-sect h6{ font-size:16px; color:#000; margin-bottom:0 }
.pngpay-setion .pngpay-body{ display:flex; justify-content: space-between; flex-wrap: wrap; }
.pngpay-setion .pngpay-body .category-sect{ /*width:32%;*/ margin-bottom:15px } 
.rs-process.style2 .rs-addon-number .number-part .desc{ display: -webkit-box;max-width: 400px;height:70.2px;font-size: 16px;-webkit-line-clamp: 3;-webkit-box-orient: vertical;overflow: hidden;text-overflow: ellipsis;line-height: 24px;font-weight: 400; }
.rs-process.style2 .rs-addon-number .number-part .number-txt strong{ margin-right:10px }
.paytmqr-img img{ width:160px; margin-top:10px!important; }
.captcha-text{ font-weight:700; font-size:18px; letter-spacing:3px }
.captcha-text i{ color: #0069b3; cursor: pointer; }
.customer-login-page .register-now:hover, .customer-login-page .log-meter-text ul li a:hover{ color:#51ad32}
input:-webkit-autofill, input:-webkit-autofill:hover, input:-webkit-autofill:focus, input:-webkit-autofill:active{ -webkit-box-shadow: 0 0 0 30px white inset !important;}
.follow-us-on ul li i.fa-facebook:hover{ color:#4867aa }
.follow-us-on ul li i.fa-twitter:hover{ color:#1da1f2 }
.follow-us-on ul li i.fa-youtube-play:hover{ color:#f70000 }
.follow-us-on ul li i.fa-instagram:hover{ color:#b431b7 }
.follow-us-on ul li i.fa-linkedin:hover{ color:#0284ca }
.number-list{ margin-bottom: 10px; padding-left: 10px; line-height: 22px; margin-left: 10px;}
.customer-login-page input[type="date"]{ text-transform: uppercase; border:none; }
/*.ng-star-inserted{ margin-top:5px }*/
.rate-services-box{ background-color:#FFF; padding:15px 10px; border:1px solid #e4f1e6 }
.customer-login-page .cu-login-input2 input:disabled{ cursor: not-allowed;  opacity: .6; background:#c9dfed; border: 1px solid #b0c7d7; color: #11232e}
.font-size11{ font-size:11px!important; }
.font-size111{ font-size:15px!important; }
.form-floating input[type="date"]{ border:1px solid #ced4da; }
.innerpage-right-content{ /*text-shadow:0px 1.6px 3.6px rgb(0 0 0 / 13%), 0px 0px 2.9px rgb(0 0 0 / 11%)*/ }
.form-floating > label{ text-shadow:none; }
/*.text-shadow, .blogs-article .rs-blog.style2 .blog-item .blog-content .blog-title a, .csr-whitebox, .rs-blog.style2 .blog-item .blog-content p, .rs-services.style2 .mgl-slider .service-wrap .content-part .desc, .site-heading, .latest-details ul li p, .investor-zone .investors-events ul li, .leadership-desc{
 text-shadow:0px 1.6px 3.6px rgb(0 0 0 / 13%), 0px 0px 2.9px rgb(0 0 0 / 11%) }*/


.cngrate-slider { width:145px; height: 30px; position: relative; font-size:14px;  text-align:center; line-height: 30px;}
.cngrate-slide1,.cngrate-slide2,.cngrate-slide3,.cngrate-slide4 { position: absolute; width: 100%; height: 100%; font-size:14px; text-align: center;}
.cngrate-slide1 { animation:fade 10s infinite; -webkit-animation:fade 10s infinite;} 
.cngrate-slide2 {  animation:fade2 10s infinite; -webkit-animation:fade2 10s infinite;}
.cngrate-slide3 {  animation:fade3 10s infinite; -webkit-animation:fade3 10s infinite;}
.cngrate-slide4 {  animation:fade4 10s infinite; -webkit-animation:fade4 10s infinite;}
@keyframes fade
{
  0%   {opacity:0}
  20% { opacity: 1}
  40% { opacity: 0}
  60% { opacity: 0}
  80% { opacity: 0}
  100% { opacity: 0}
}
@keyframes fade2
{
  0%   {opacity:0}
  20% { opacity: 0}
  40% { opacity: 1}
  60% { opacity: 0}
  80% { opacity: 0}
  100% { opacity: 0}
}
@keyframes fade3
{
  0%   {opacity:0}
  20% { opacity: 0}
  40% { opacity: 0}
  60% { opacity: 1}
  80% { opacity: 0}
  100% { opacity: 0}
}
@keyframes fade4
{
  0%   {opacity:0}
  20% { opacity: 0}
  40% { opacity: 0}
  60% { opacity: 0}
  80% { opacity: 1}
  100% { opacity: 0}
}
.full-width-header .rs-header .topbar-area.style1 .toolbar-sl-share ul li .dropdown .dropdown-menu li a{ justify-content: flex-start; }
.name-trasfer-address p{font-size: 13px; color: #9ba8ba;  margin-bottom: 5px;}
.name-trasfer-address h6{font-size: 16px; color: #000; margin-bottom: 0;}
.apply-list-sect{ margin:10px 0 }
.apply-list-sect li{ margin-bottom:10px }
.cng-rate-mobileview{display: flex; justify-content: space-between;}
.cng-rate-mobileview p{ color:#000;margin:0 5px 10px 0px; }
.cng-rate-mobileview p a{ color:#000 }
.quick-pay-btn a{ background:#111f4f; padding:12px 20px 12px 40px; border-radius:40px; color:#FFF; font-size:14px; margin:0 5px 10px 0; position: relative;width: 48%;}
.quick-pay-btn a i{ position:absolute; left: 10px; top: 10px;font-size: 15px;}
.quick-pay-btn{ display:flex; justify-content: flex-start; flex-wrap: wrap; }
.quick-pay-btn select{ background:#111f4f; padding:10px 20px; border-radius:40px; color:#FFF;width: 48%;; font-size: 14px;height: 40px}
.blog-details .bs-img a img{ height:450px; object-fit: scale-down; background:#e5f4ff; }
.rs-project.style1.red-overly-bg .project-item .project-img img { height: 200px; width: 100%; object-fit: cover;}
.rs-project.style1.red-overly-bg .project-item .project-content .project-inner .title a{ font-size:16px }
.rs-project.style1 .project-item .project-content .project-inner .title{ line-height: 20px }
body #galleryModal.modal.show .modal-dialog{ max-width:800px}
body #galleryModal .modal-body{ padding:0 }
body #galleryModal .gallery-slider{ position:relative; }
body #galleryModal .gallery-slider .gallery-prev ,body #galleryModal .gallery-slider .gallery-next{ position:absolute; background:rgb(255 255 255 / 69%); width: 40px; height: 40px; line-height: 40px; border: none; border-radius: 40px;   font-size: 26px;    display: flex;    justify-content: center;    align-items: center;}
body #galleryModal .gallery-slider .gallery-prev{  left:-50px; top:50%; transform:translate(0, -50%); }
body #galleryModal .gallery-slider .gallery-next { right: -50px; top: 50%; transform: translate(0, -50%); }
body #galleryModal.modal.show .btn-close{ right:20px; top:20px }
.netpay-text .payable-amount{ font-size:20px }
.netpay-text .payable-amount b{ font-size:36px; color: #0069b3;}
.mobile-user-login{ display:none!important; }
.mobile-user-login .dropdown .dropdown-toggle { width: 50px; background-color: #111f4f;  border-radius: 6px;  outline: none;  border: none;  height: 40px; color: #FFF; font-size: 18px}
.mobile-user-login .dropdown-menu{background: #111f4f;}
.mobile-user-login .dropdown-menu li .dropdown-item{ color:#FFF }
.mobile-user-login .dropdown .dropdown-toggle::after{ border-top: 0.25em solid;  border-right: 0.25em solid transparent; border-bottom: 0; border-left: 0.25em solid transparent; position: absolute; right: 7px;  top: 18px }
.mobile-user-login .dropdown .dropdown-toggle i{ position: absolute; left: 8px; top: 8px;}
.dom-con-req{ display: flex; justify-content: space-between; }
.dom-con-req i{ background: #f6fff6; border: 1px solid #4ec226; border-radius: 40px; width: 45px; height: 45px; display: flex; justify-content: center; align-items: center; font-size: 22px; color: #4ec226;}
.dom-con-req p{ width:93%; margin-bottom:0; font-weight: 500; font-size: 16px; }
.dom-con-req p a{ text-align: center; display: inline-block; color: #016ab3; padding-right: 25px; position: relative; z-index: 1; font-weight: 700; font-size: 15px;}
.dom-con-req p a:after{ content: "\f114"; position: absolute; font-family: flaticon; font-size: 14px; top:2px; right:2px; opacity: 1; transition: all 0.3s;}
.dom-con-req p a:hover:after { right: -5px; opacity: 1;}
.mid-social{ display:flex; }
.mid-social a{ color: #0069b3; font-size: 26px; padding:0px 25px 0 0}
.topbar-contact ul{ display:flex;align-items: center; }
.rs-inner-blog .csr-initiatives-blogs.blog-item .blog-img a img{ height: 250px; width: 100%; object-fit: cover;}
.looking-for.cng-service-sect .looking-for-services ul, .looking-for.residential-png-serv .looking-for-services ul, .looking-for.industrial-service-sect .looking-for-services ul, .looking-for.mgl-corporate-service-sect .looking-for-services ul{ /*min-height: 120px;*/ align-items: center;}
.looking-for.cng-service-sect .looking-for-services ul li{ width:25% }
.looking-for.industrial-service-sect .looking-for-services ul li,  .looking-for.mgl-corporate-service-sect .looking-for-services ul li{ width:24% }
.font-size12{ font-size:12px }
.font-size13{ font-size:13px }
.font-size14{ font-size:14px }
.font-size15{ font-size:15px }
.font-size16{ font-size:16px }
.font-size17{ font-size:17px }
.font-size18{ font-size:18px }
.font-size19{ font-size:19px }
.font-size20{ font-size:20px }
.pngpay-setion .pngpay-body .category-sect.last-add{ width:100% }

.rs-blog.blog-main-home .blog-item.awardspage .image-wrap a img{ height:200px; object-fit:none } 
.customer-aw-faq .rs-faq .faq-content .accordion .card .card-body h6{ font-size:16px }
body #upload-doc-ment.modal.show .modal-dialog{ max-width:800px}
body #upload-doc-ment.modal.show .btn-close{ right:20px; top:20px } 
.marketstats-content{ min-height: 160px; display: flex; flex-direction: column; justify-content: center; }
.terms .customer-care-phone h5{ width:auto; }
.terms .whatsApp-number h5{ width:auto; }
.latest-posts .latest-post-widget .post-img a img, .left-blog-posts .left-blog-post-widget .post-img a img{ box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 2px 6px 2px; }




/***Leadership Speaks***/
.video-sect h4{ font-size: 20px; color: #FFF; margin: 20px 0; text-align: center; }
.video-sect .rs-videos .animate-border .popup-border1{  background: #037cd1;  width: 80px;  height: 80px;  line-height: 80px;  text-align: center;  display: inline-block;  border-radius: 50%;  position: absolute;  left: 50%;  transform: translateX(-50%);}
.video-sect .rs-videos .animate-border .popup-border1 i {  font-size: 20px;  line-height: 80px;  display: block;  color: #ffffff;}
.video-sect .rs-videos .animate-border .popup-border1:before {  content: "";  border: 2px solid #037cd1;  position: absolute;  z-index: 0;  left: 50%;  top: 50%;  transform: translateX(-50%) translateY(-50%);  display: block;  width: 180px;  height: 180px;  border-radius: 50%;  animation: zoomBig 3.25s linear infinite;  -webkit-animation-delay: .75s;  animation-delay: .75s;}
.video-sect .rs-videos .animate-border .popup-border1:after {  content: "";  border: 2px solid #037cd1;  position: absolute;  z-index: 0;  left: 50%;  top: 50%;  transform: translateX(-50%) translateY(-50%);  display: block;  width: 180px;  height: 180px;  border-radius: 50%;  animation: zoomBig 3.25s linear infinite;  -webkit-animation-delay: 0s;  animation-delay: 0s;}
.video-sect .rs-videos .animate-border.blue-color-style .popup-border1:after, .video-sect .rs-videos .animate-border.blue-color-style .popup-border1:before {  border: 2px solid #0082f1;}
.video-sect .rs-videos .animate-border.blue-color-style .popup-border1 { background: #0082f1;}
.video-sect .rs-videos .animate-border.orange-color-style .popup-border1:after, .video-sect .rs-videos .animate-border.orange-color-style .popup-border1:before { border: 2px solid #ffffff;}
.video-sect .rs-videos .animate-border.orange-color-style .popup-border1 i { color: #fd4a36;}
.video-sect .rs-videos .animate-border.orange-color-style .popup-border1 { background: #ffffff;}
.video-sect .rs-videos.choose-video { position: relative;}
.video-sect .rs-videos.choose-video .animate-border .popup-border1 { left: 50%; top:40%; transform: translate(-50%, -50%);}
.video-sect .rs-videos.choose-video.careers-video { position: relative;}
.leadership-speaks-video .owl-nav{ position:absolute; width:100%; top:25%; transform:translate(0,-50%); }
.leadership-speaks-video .owl-carousel .owl-nav .owl-next{ position:absolute; right:-70px; background:none; border:none; }
.leadership-speaks-video .owl-carousel .owl-nav .owl-prev{ position:absolute; left:-70px; background:none; border:none; }
.leadership-speaks-video .owl-carousel .owl-nav .owl-next i, .leadership-speaks-video .owl-carousel .owl-nav .owl-prev i{ font-size:56px; color:#95f984 }


.follow-us-on ul li i.new-twitter-icon{ display: flex; margin-top:3px }
.follow-us-on ul li i.new-twitter-icon img{ width:17px }
.mid-social a i.new-twitter-icon{ display: flex; margin-top:3px }
.mid-social a i.new-twitter-icon img{ width:17px }